import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Input, TableLangValue, Editor } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';

const ConditionTypeForm = ({ match }) => {
  return (
    <View>
      <ResourceForm resource="ConditionType" id={match.params.id} initialValues={{ name: {}, alias: '' }}>
        {({ submitting, renderActions, values, ...args }) => (
          <>
            <Card>
              <CardBody className={submitting ? 'loading' : ''}>
                <Row>
                  <Col lg={6}>
                    <Row>
                      <Col lg={12}>
                        <Input name="alias" />
                      </Col>
                      <Col lg={12}>
                        <TableLangValue name="name" />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Editor name="configuration" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

ConditionTypeForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default ConditionTypeForm;
