/* eslint-disable no-continue */

/* eslint-disable no-prototype-builtins */

/* eslint-disable no-restricted-syntax */
import jwtDecode from 'jwt-decode';

import api from '@/services/api';
import logger from '@/services/logger';
import storage from '@/services/storage';
import string from '@/services/utils/string';

/**
 * Security service in charge of authentication & check users permissions.
 */
export default {
  /**
   * @type {object} - Matrix loaded from API
   */
  matrix: {},

  /**
   * Init matrix and refresh user data
   *
   * @return {Promise}
   */
  init() {
    return api.get('/matrix').then(({ data }) => {
      this.matrix = data.matrix;
      this.user = data.user;
      logger.success('Security', 'Matrix has been loaded', this.matrix);
      logger.success('Security', 'User has been refreshed', this.user);
    });
  },

  /**
   * Authenticate user with username/password couple
   *
   * @param {string}  username
   * @param {string}  password
   * @param {boolean} authenticate
   *
   * @return {Promise}
   */
  login(username, password, authenticate = true) {
    return api
      .post('/login_check', { username, password }, null, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ data }) => {
        return authenticate ? this.authenticate(data.token, data.refresh_token) : data;
      });
  },

  /**
   * Connect user
   *
   * @param {string} token
   * @param {string} refreshToken
   *
   * @return {object}
   */
  authenticate(token, refreshToken) {
    const data = jwtDecode(token);
    this.user = { ...this.user, ...data.user };
    this.token = token;
    this.tokenExp = data.exp;
    this.refreshToken = refreshToken;
    this.refreshTokenExp = data.refreshExp;

    return this.user;
  },

  /**
   * Disconnect user
   *
   * @param {boolean} redirectToLogin
   */
  deAuthenticate(redirectToLogin = false) {
    this.user = null;
    this.token = null;
    this.tokenExp = null;
    this.refreshToken = null;
    this.refreshTokenExp = null;
    storage.delete();

    if (redirectToLogin) {
      // router.navigate('login');
    }
  },

  /**
   * Is user authenticated
   *
   * @return {boolean}
   */
  isAuthenticated() {
    return typeof this.user === 'object' || typeof this.token === 'string';
  },

  /**
   * Is token expired
   *
   * @return {boolean}
   */
  isTokenExpired() {
    return this.tokenExp < Date.now() / 1000;
  },

  /**
   * Is refresh token expired
   *
   * @return {boolean}
   */
  isRefreshTokenExpired() {
    return this.refreshTokenExp < Date.now() / 1000;
  },

  /**
   * Check if user has specific roles
   *
   * @param {array|string} roles  - roles to check
   * @param {boolean}      strict - if roles is array & strict is true, user must have all roles
   * @param {?object}      user   - Use current user if this var is not defined
   *
   * @return {boolean}
   */
  isGranted(roles, strict = false, user) {
    if (!this.isAuthenticated()) {
      return roles.length === 0;
    }

    const userTmp = user || this.user;

    if (!userTmp || !userTmp.roles || !Array.isArray(userTmp.roles)) {
      return false;
    }

    // cast roles to array
    const tmpRoles = !Array.isArray(roles) ? [roles] : roles;

    // NEGATIVE ROLE (AND OPERATION)
    if (tmpRoles.find((role) => role.indexOf('!') === 0) !== undefined) {
      return (
        tmpRoles.length ===
        tmpRoles.filter((role) => {
          return role.indexOf('!') === 0 && !userTmp.roles.includes(role.replace('!', ''));
        }).length
      );
    }

    if (tmpRoles.length === 0 || userTmp.roles.includes('ROLE_SUPER_ADMIN')) {
      return true;
    }

    const has = tmpRoles.filter((role) => userTmp.roles.includes(role)).length;

    return strict ? has === tmpRoles.length : has > 0;
  },

  /**
   * Check if user has permission on specific action
   *
   * @param {string}   alias
   * @param {string}   operation
   * @param {?boolean} checkRoles
   * @param {?object}   user
   *
   * @return {boolean|null}
   */
  hasPermission(alias, operation, checkRoles = true, user) {
    if (this.isGranted('ROLE_SUPER_ADMIN', true, user)) {
      return true;
    }

    const tmpAlias = string.ucfirst(alias);
    const tmpUser = user || this.user;

    if (tmpUser && tmpUser.permissions && Array.isArray(tmpUser.permissions[tmpAlias])) {
      if (tmpUser.permissions[tmpAlias].includes(`!${operation}`)) {
        return false;
      }

      if (tmpUser.permissions[tmpAlias].includes(operation)) {
        return true;
      }
    }

    if (checkRoles) {
      return this.hasRequiredRoles(tmpAlias, operation, tmpUser);
    }

    return null;
  },

  /**
   * Check if user has permission on specific actions
   *
   * @param {object}  permissions
   * @param {boolean} useRoles
   *
   * @return {boolean}
   */
  hasPermissions(permissions = {}, useRoles, user) {
    if (this.isGranted('ROLE_SUPER_ADMIN', true, user)) {
      return true;
    }

    for (const alias in permissions) {
      if (!permissions.hasOwnProperty(alias)) {
        continue;
      }

      // Il a besoin d'avoir toutes les permissions.
      for (let i = 0, len = permissions[alias].length; i < len; ++i) {
        if (!this.hasPermission(alias, permissions[alias][i], useRoles, user)) {
          return false;
        }
      }
    }

    return true;
  },

  /**
   * Has required role for actions as defined in matrix
   *
   * @param {string}  module
   * @param {string}  operation
   * @param {?object} user
   *
   * @return {boolean}
   */
  hasRequiredRoles(module, operation, user) {
    if (this.isGranted('ROLE_SUPER_ADMIN', true, user)) {
      return true;
    }

    const config = this.getOperationConfig(module, operation);
    return !config || this.isGranted(config.roles, false, user);
  },

  /**
   * Get operation config in matrix
   *
   * @param {string} module
   * @param {string} operation
   *
   * @return {null|object}
   */
  getOperationConfig(module, operation) {
    const { matrix } = this;

    for (let i = 0, len = matrix.length; i < len; ++i) {
      const { alias, operations } = matrix[i];

      if (alias.toLowerCase() === module.toLowerCase() && operations[operation]) {
        return operations[operation];
      }
    }

    return null;
  },

  /**
   * Get user from storage
   *
   * @return {?object}
   */
  get user() {
    return storage.get('user');
  },

  /**
   * Set user data into storage
   *
   * @param {object} user
   */
  set user(user) {
    storage.set('user', user);
  },

  /**
   * Get token from storage
   *
   * @return {?string}
   */
  get token() {
    return storage.get('token');
  },

  /**
   * Set token data into storage
   *
   * @param {string} token
   */
  set token(token) {
    storage.set('token', token);
  },

  /**
   * Get token exp from storage
   *
   * @return {number}
   */
  get tokenExp() {
    return Number(storage.get('token-exp') ?? 0);
  },

  /**
   * Set token exp data into storage
   *
   * @param {number} exp
   */
  set tokenExp(exp) {
    storage.set('token-exp', exp);
  },

  /**
   * Get refresh token from storage
   *
   * @return {?string}
   */
  get refreshToken() {
    return storage.get('refresh-token');
  },

  /**
   * Set refresh token into storage
   *
   * @param {string} token
   */
  set refreshToken(token) {
    storage.set('refresh-token', token);
  },

  /**
   * Get refresh token exp from storage
   *
   * @return {number}
   */
  get refreshTokenExp() {
    return Number(storage.get('refresh-token-exp') ?? 0);
  },

  /**
   * Set refresh token exp into storage
   *
   * @param {number} exp
   */
  set refreshTokenExp(exp) {
    storage.set('refresh-token-exp', exp);
  }
};
