import React from 'react';
import { Col, Row, Card, CardBody, Table, Button } from 'reactstrap';

import Date from '@/components/common/date';
import notifications from '@/services/notifications';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

export default ({ data, submitting }) => {
  // Lien temporaire de téléchargement du fichier.
  const createDownloadLink = (file, name) => {
    const a = document.createElement('a');
    a.href = `data:application/pdf;base64,${file}`;
    a.download = name;
    document.body.appendChild(a);
    a.click();

    setTimeout(() => {
      a.remove();
    }, 500);
  };

  // Au clic sur un bouton dowload
  const download = async (id) => {
    let response = null;
    try {
      response = await getResource('DocumentHistoric').download(id);
    } catch (e) {
      notifications.error(t('download'), t('error_cannot_fetch_resource'));
      return;
    }
    const dataResponse = response.data;
    createDownloadLink(dataResponse.file, dataResponse.name);
  };

  return (
    <Card>
      <CardBody className={submitting ? 'loading' : ''}>
        <Row>
          <Col lg={12}>
            <Table>
              <thead>
                <tr>
                  <th>{t('version')}</th>
                  <th>{t('date')}</th>
                  <th>{t('user')}</th>
                  <th>{t('download')}</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) &&
                  data.map((historic, index) => {
                    return (
                      <tr key={index}>
                        <td>{historic.version}</td>
                        <td>{historic.date && <Date>{historic.date}</Date>}</td>
                        <td>{historic.user ? historic.user.username : '/'}</td>
                        <td>
                          <Button color="info" size="sm" onClick={() => download(historic.id, this)}>
                            {t('download')}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
