import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Table, Button, UncontrolledPopover, PopoverBody, Badge } from 'reactstrap';

import ExternalLink from '@/components/common/external-link';
import Icon from '@/components/common/icon';
import { RadioButtons } from '@/components/form';
import environment from '@/services/environment';
import security from '@/services/security';
import { t } from '@/services/translator';

export default class Permissions extends React.Component {
  state = {
    toggled: {}
  };

  /**
   * Show/hide entrypoint permissions
   *
   * @param {string} alias
   */
  toggle(alias) {
    const { toggled } = this.state;
    toggled[alias] = !toggled[alias];
    this.setState({ toggled });
  }

  /**
   * Show/hide all entrypoints permissions
   *
   * @param {boolean} value
   */
  toggleAll(value = true) {
    const matrix = security.matrix || {};
    const toggled = {};

    for (let i = 0, len = matrix.length; i < len; ++i) {
      toggled[matrix[i].alias] = value;
    }

    this.setState({ toggled });
  }

  /**
   * Check if user have a permission on specific alias & operation
   *
   * @param {object} user
   * @param {string} alias
   * @param {string} operation
   *
   * @return {boolean}
   */
  hasPermission(user, alias, operation) {
    const value = this.getValue(alias, operation);

    return typeof value === 'boolean' ? value : security.hasPermission(alias, operation, true, user);
  }

  /**
   * Reset permissions
   *
   * @param {?string} alias - alias to reset
   */
  reset(alias) {
    const { values, setFieldValue } = this.props;

    let permissions = values.permissions || {};

    if (alias) {
      delete permissions[alias];
    } else {
      permissions = {};
    }

    setFieldValue('permissions', permissions);
  }

  /**
   * Get permission value for an alias/operation
   *
   * @param {string} alias
   * @param {string} operation
   *
   * @return {boolean|null}
   */
  getValue(alias, operation) {
    const { values } = this.props;

    // All other permissions are partner permissions.
    const permissions = values.permissions ? values.permissions.default || {} : {};
    const operations = permissions[alias];

    if (operations) {
      if (operations.includes(`!${operation}`)) {
        return false;
      }

      if (operations.includes(operation)) {
        return true;
      }
    }

    return null;
  }

  /**
   * Set permission value for an alias/operation
   *
   * @param {string} alias
   * @param {string} operation
   * @param {string} value
   */
  setValue(alias, operation, value) {
    const { values, setFieldValue } = this.props;
    let permissionsContainer = values.permissions || {};

    if (Array.isArray(permissionsContainer)) {
      permissionsContainer = { default: {} };
    }

    if (!permissionsContainer.default) {
      permissionsContainer.default = {};
    }

    if (permissionsContainer.default[alias] === undefined) {
      permissionsContainer.default[alias] = [];
    }

    const permissions = permissionsContainer.default;

    const notOperation = `!${operation}`;

    switch (value) {
      case 'false': {
        const inverseOperation = permissions[alias].indexOf(operation);
        if (inverseOperation !== -1) {
          permissions[alias].splice(inverseOperation, 1);
        }

        if (!permissions[alias].includes(notOperation)) {
          permissions[alias].push(notOperation);
        }

        break;
      }
      case 'true': {
        const inverseOperation = permissions[alias].indexOf(notOperation);
        if (inverseOperation !== -1) {
          permissions[alias].splice(inverseOperation, 1);
        }

        if (!permissions[alias].includes(operation)) {
          permissions[alias].push(operation);
        }

        break;
      }
      default:
      case 'null': {
        // just here to indicate that "value" can be null.
        const forbiddenOperation = permissions[alias].indexOf(notOperation);
        if (forbiddenOperation !== -1) {
          permissions[alias].splice(forbiddenOperation, 1);
        }

        const allowedOperation = permissions[alias].indexOf(operation);
        if (allowedOperation !== -1) {
          permissions[alias].splice(allowedOperation, 1);
        }

        break;
      }
    }

    if (permissions[alias].length === 0) {
      delete permissions[alias];
    }

    permissionsContainer.default = permissions;
    setFieldValue('permissions', permissionsContainer);
  }

  render() {
    const { toggled } = this.state;
    const { values } = this.props;

    return (
      <Row className="mt-0 mb-0">
        <Col lg={12}>
          <div className="flex justify-content-end mb-3 pb-3 border-bottom">
            <Button outline size="sm" color="default" onClick={() => this.toggleAll(false)}>
              <Icon name="chevron-right" className="mr-2" />
              {t('untoggle_all')}
            </Button>
            <Button outline size="sm" color="default" onClick={() => this.toggleAll()}>
              <Icon name="chevron-down" className="mr-2" />
              {t('toggle_all')}
            </Button>
            <Button outline size="sm" color="default" onClick={() => this.reset()}>
              <Icon name="undo" className="mr-2" />
              {t('reset_permissions')}
            </Button>
          </div>
          {security.matrix.map(({ alias, operations }) => (
            <div key={alias} className="mb-2 pointer">
              <div
                className="text-gray-dark p-3 bg-lighter flex justify-content-between align-items-center"
                onClick={() => this.toggle(alias)}
                aria-hidden
              >
                <h3 className="m-0">
                  <ExternalLink className="text-gray-dark" href={environment.get('api_url')}>
                    {alias}
                  </ExternalLink>
                </h3>
                <div className="flex align-items-center">
                  <div className="flex mr-4" style={{ overflow: 'scroll', maxWidth: '300px' }}>
                    {Object.keys(operations).map(
                      (name) =>
                        this.hasPermission(values, alias, name) && (
                          <Badge key={name} color="success" pill className="ml-1">
                            {name}
                          </Badge>
                        )
                    )}
                  </div>
                  <Icon name={toggled[alias] ? 'chevron-down' : 'chevron-right'} />
                </div>
              </div>
              {toggled[alias] && (
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col" className="text-left" width="300">
                        {t('api_operation')}
                      </th>
                      <th scope="col" className="text-left">
                        {t('api_description')}
                      </th>
                      <th scope="col" width="200" className="text-center">
                        Authorization &nbsp;
                        <Icon className="pointer" name="undo" onClick={() => this.reset(alias)} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(operations).map((name) => {
                      const { meta, roles } = operations[name];
                      const id = meta.key.replace(/:/g, '').toLowerCase();

                      return (
                        <tr key={id}>
                          <td className="text-left">
                            <h5>
                              <ExternalLink id={id} className="text-gray-dark" href={environment.get('api_url')}>
                                {name}
                              </ExternalLink>
                            </h5>
                            <UncontrolledPopover target={id} trigger="hover">
                              <PopoverBody style={{ width: 'max-content' }}>
                                <strong className="mr-2">{meta.method.toUpperCase()}</strong>
                                {meta.path}
                                <hr className="mt-2 mb-2" />
                                {roles.length === 0 ? (
                                  <small>
                                    <i>{t('public')}</i>
                                  </small>
                                ) : (
                                  roles.map((role) => (
                                    <div key={role}>
                                      <Badge color="primary" pill>
                                        {t(role)}
                                      </Badge>
                                    </div>
                                  ))
                                )}
                              </PopoverBody>
                            </UncontrolledPopover>
                          </td>
                          <td className="text-left">{operations[name].meta.description}</td>
                          <td>
                            <RadioButtons
                              name={id}
                              label={false}
                              inline="center"
                              value={this.getValue(alias, name)}
                              options={[
                                { label: t('yes'), value: true },
                                { label: t('no'), value: false },
                                { label: t('auto'), value: null }
                              ]}
                              inputProps={{
                                onChange: ({ currentTarget }) => {
                                  this.setValue(alias, name, currentTarget.value);
                                }
                              }}
                              groupProps={{
                                className: 'm-0 flex justify-content-center'
                              }}
                              buttonClass={(item, active) => ({
                                'bg-danger': active && this.hasPermission(values, alias, name) === false,
                                'bg-success': active && this.hasPermission(values, alias, name) === true,
                                'text-white': active
                              })}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </div>
          ))}
        </Col>
      </Row>
    );
  }
}

Permissions.propTypes = {
  values: PropTypes.shape({ permissions: PropTypes.any }).isRequired,
  setFieldValue: PropTypes.func.isRequired
};
