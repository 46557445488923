/* eslint-disable no-console */

/* eslint-disable no-unused-expressions */
import environment from '@/services/environment';

export default {
  /**
   * Log a message in console with a specific color & data
   *
   * @param {string}  title
   * @param {string}  message
   * @param {?object} data
   * @param {?string} color
   *
   * @return {self}
   */
  log(title, message, data, color) {
    if (environment.get('node_env') !== 'production') {
      console.log(`%c${title ? `<--- ${title.toUpperCase()} ---> ` : ''}${message}`, `color: ${color}`);
      data && console.log(data);
    }

    return this;
  },

  /**
   * Perform an info log
   *
   * @see this.log
   */
  info(title, message, data) {
    return this.log(title, message, data, '#17a2b8');
  },

  /**
   * Perform an success log
   *
   * @see this.log
   */
  success(title, message, data) {
    return this.log(title, message, data, '#28a745');
  },

  /**
   * Perform an error log
   *
   * @see this.log
   */
  error(title, message, data) {
    return this.log(title, message, data, '#dc3545');
  },

  /**
   * Perform an warning log
   *
   * @see this.log
   */
  warn(title, message, data) {
    return this.log(title, message, data, '#ffc107');
  }
};
