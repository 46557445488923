import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Datepicker, Editor, Input, Autocomplete } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';
import security from '@/services/security';
import { t } from '@/services/translator';

import Calculation from './calculations';
import DataView from './data-view';
import Geometries from './geometries';

const Index = ({ match }) => (
  <View>
    <ResourceForm
      resource="Report"
      id={match.params.id}
      initialValues={{ query: '[]', geometries: { points: [], lines: [], polygons: [] } }}
    >
      {({ submitting, renderActions, values, ...props }) => (
        <>
          <Card>
            <CardBody className={submitting ? 'loading' : ''}>
              <Row className="m-0">
                <Col lg={6}>
                  <Row>
                    <Col lg="6">
                      <Input name="name" />
                    </Col>
                    <Col lg="6 d-flex justify-content-center align-items-center">
                      {(security.isGranted('ROLE_ADMIN') || security.isGranted('ROLE_SUPER_ADMIN')) && values.owner ? (
                        <Link to={`/users/${values.owner.id}`}>
                          {t('user')} : {values.owner.id}
                        </Link>
                      ) : null}
                    </Col>
                  </Row>
                  <Datepicker name="startDate" />
                  <Datepicker name="endDate" />
                  <Autocomplete name="community" clearable resource="Community" display="name" tag="name" />
                </Col>
                <Col lg={6}>
                  <Editor name="query" height="300" />
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <Calculation values={values} {...props} />
                </Col>
                <Col lg="12" className="mt-4">
                  <Geometries {...props} />
                </Col>
              </Row>
              <DataView query={values.query} id={match.params.id} />
            </CardBody>
          </Card>
          <div className="mt-4 mb-4">{renderActions()}</div>
        </>
      )}
    </ResourceForm>
  </View>
);

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default Index;
