import React, { useEffect, useState } from 'react';
import SimpleSelect from 'react-select';
import { Button, Spinner, CardBody, CardHeader, Card, Form } from 'reactstrap';

import Icon from '@/components/common/icon';
import Pagination from '@/components/common/pagination';
import { Select } from '@/components/form';
import selectHelper from '@/components/form/select/helper';
import security from '@/services/security';
import translator, { t } from '@/services/translator';

const ListRenderer = (props) => {
  const { formik, state, dispatchState, components, functions } = props;

  const { loading, totalItems, filters, navigating } = state;

  const { Actions, Body, Filters } = components;

  const {
    multipleActions,
    handleMultipleAction,
    bodyProps,
    perPageOptions,
    resource,
    fetch,
    renderCreateButton,
    resetFilters
  } = functions;

  const [initContext, setInitContext] = useState(true);
  const isAdmin = security.isGranted('ROLE_ADMIN') || security.isGranted('ROLE_SUPER_ADMIN');

  useEffect(() => {
    formik.setFormikState({ ...formik, resource });
    setInitContext(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    !initContext && (
      <>
        {renderCreateButton()}
        {Actions && <Actions />}
        {isAdmin && Filters && (
          <Card>
            <CardBody className={`bg-transparent${loading ? ' loading' : ''}`}>
              <Form role="form" noValidate onSubmit={formik.handleSubmit}>
                <div className="list-filters">
                  <Filters {...formik} />
                </div>
                <div className="text-center pt-3">
                  <Button outline color="primary" size="sm" type="submit">
                    <Icon name="filter" className="mr-1" />
                    {t('filter')}
                  </Button>
                  <Button outline color="warning" size="sm" onClick={() => resetFilters(formik.setValues)}>
                    <Icon name="eraser" className="mr-1" />
                    {t('reset')}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        )}
        <div className="f-height flex align-items-center justify-content-center mb-2 mt-2">
          {(!loading || navigating) && (
            <span className={`text-center ${Filters ? 'text-grey' : 'text-white'}`}>
              <small className="font-weight-bold">{t('nb_result', totalItems)}</small>
            </span>
          )}
        </div>
        <Card
          className="shadow"
          id="list-table-container"
          innerRef={(el) => dispatchState && state.listContainer !== el && dispatchState({ listContainer: el })}
        >
          <CardHeader className="pt-3 pb-3">
            <div className="flex align-items-center justify-content-between">
              {multipleActions === undefined || multipleActions.length === 0 ? (
                <span />
              ) : (
                <div style={{ minWidth: '150px' }}>
                  <SimpleSelect
                    placeholder={translator.translate('actions')}
                    isSearchable={false}
                    isDisabled={loading}
                    options={multipleActions}
                    noOptionsMessage={() => <small>-</small>}
                    value={null}
                    onChange={({ value }) => handleMultipleAction(value)}
                    styles={selectHelper.buildStyles('sm')}
                  />
                </div>
              )}
              <Select
                size="sm"
                label={false}
                name="perPage"
                groupTag="span"
                isDisabled={loading}
                className="input-per-page"
                options={perPageOptions().map((value) => ({
                  label: `${value} / ${translator.translate('page')}`,
                  value
                }))}
                onSelect={(perPage) => fetch({ perPage })}
              />
            </div>
          </CardHeader>
          <CardBody className="p-0">
            {loading || !Body ? (
              <div className="text-center pt-3 pb-3">
                <Spinner />
              </div>
            ) : (
              <Body {...bodyProps()} />
            )}
          </CardBody>
        </Card>

        {/* Show pagination only if pages > 1 */}

        {(!loading || navigating) && Math.ceil(totalItems / filters.perPage) > 1 && (
          <nav className="flex justify-content-center mt-3">
            <Pagination
              perPage={filters.perPage}
              total={totalItems}
              loading={loading}
              active={Number(filters.page)}
              onClick={(page) => fetch({ page })}
              disabled={loading}
            />
          </nav>
        )}
      </>
    )
  );
};

export default ListRenderer;
