/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Card, CardBody } from 'reactstrap';

import View from '@/components/view';
import Context from '@/services/context';
import getResource from '@/services/resources';

import AccordionItem from './accordion-item';

export default () => {
  const { community, locale } = useContext(Context);
  const [communities, setCommunities] = useState();
  const [selected, setSelected] = useState();
  const refCard = useRef();

  const loadMapCommunities = async () => {
    const list = await getResource('Community').getSheetActions(community);
    console.log(list);
    setCommunities(list);
  };

  const handleOnSelect = (communityId, report, dashboard, name) => {
    setSelected({ community: communityId, report, dashboard, locale, name });
  };

  useEffect(() => {
    loadMapCommunities();
  }, [community]);

  console.log(communities);

  return (
    <View>
      <Card style={{ height: '100%' }} ref={refCard}>
        <CardBody style={{ position: 'relative', overflow: 'auto', padding: 0 }}>
          {communities &&
            communities.map((communityMapped) => (
              <AccordionItem community={communityMapped} key={communityMapped.id} onSelect={handleOnSelect} />
            ))}
        </CardBody>
      </Card>
    </View>
  );
};
