import React from 'react';
import { Row, Col } from 'reactstrap';

import HeaderImg from '@/assets/img/common/tob_web.svg';

export default ({ routes }) => {
  return (
    <div className="admin_shared_one">
      <Row>
        <Col className="col-12">
          <img src={HeaderImg} alt="header" width="100%" />
        </Col>
        <Col className="col-12">{routes()}</Col>
      </Row>
    </div>
  );
};
