import * as yup from 'yup';

/**
 * Boolean extension validator
 *
 * @see @/services/mapping/validator
 */
export default {
  supports: ['bool', 'boolean'],

  build({ constraints }, rule) {
    rule = rule || yup.boolean();

    for (let i = 0, len = constraints.length; i < len; ++i) {
      switch (constraints[i].alias) {
        case 'IsTrue':
          rule = rule.test('is-true', constraints[i].message, (value) => value === true);
          break;
        case 'IsFalse':
          rule = rule.test('is-false', constraints[i].message, (value) => value === true);
          break;
        default:
          break;
      }
    }

    return rule;
  }
};
