import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Input, Resource, Checkbox, WysiwygEditor } from '@/components/form';
import { t } from '@/services/translator';

export default ({ submitting, values, editMode }) => (
  <Card>
    <CardBody className={submitting ? 'loading' : ''}>
      <Row>
        <Col lg={6}>
          <Input name="name" />
        </Col>
        <Col lg={6}>
          <Checkbox name="validate" />
        </Col>
        <Col lg={6}>
          {t('current_version')} : {values.version}
        </Col>
        <Col lg={6}>
          <Resource name="type" resource="DocumentType" display="name" />
        </Col>
        <Col lg="12">
          <WysiwygEditor name="content" />
        </Col>
      </Row>
    </CardBody>
  </Card>
);
