import PropTypes from 'prop-types';
import React from 'react';
import AutosizeInput from 'react-input-autosize';
import { Spinner } from 'reactstrap';

import Context from '@/services/context';
import translator from '@/services/translator';
import dom from '@/services/utils/dom';

class Translation extends React.Component {
  state = {
    focus: false,
    value: null,
    submitting: false
  };

  /**
   * Get current locale
   *
   * @return {string}
   */
  get locale() {
    const { locale } = this.context;

    return locale;
  }

  /**
   * Get translation value
   *
   * @return {string}
   */
  get translation() {
    const { children, number } = this.props;

    return translator.translate(children, number, this.locale);
  }

  /**
   * Get input style configured with inherited css properties
   *
   * @return {object}
   */
  get inputStyle() {
    return {
      background: 'transparent',
      border: 'none',
      padding: 0,
      margin: 0,
      fontSize: dom.getStyle(this.container, 'font-size'),
      letterSpacing: dom.getStyle(this.container, 'letter-spacing'),
      color: dom.getStyle(this.container, 'color'),
      fontWeight: dom.getStyle(this.container, 'font-weight')
    };
  }

  /**
   * Disable edition mode (show only translation, no input)
   */
  disableEdition() {
    this.setState({ focus: false, value: null, submitting: false });
  }

  /**
   * Enable edition mode (show input)
   */
  enableEdition() {
    const { children, number } = this.props;
    const translation = translator.getTranslation(children, this.locale);

    let value = children;

    if (translation) {
      value = translation[number > 1 ? 'pluralValue' : 'value'];
    }

    this.setState({ focus: true, value }, () => this.input && this.input.focus());
  }

  /**
   * Sync managed translation with current value of input
   */
  submit() {
    this.setState({ submitting: true }, () => {
      const { children, number } = this.props;
      const { value } = this.state;

      translator.sync(children, value, this.locale, number > 1).then(() => this.disableEdition());
    });
  }

  render() {
    const { focus, value, submitting } = this.state;
    const { editionMode } = this.context;

    return !editionMode ? (
      this.translation
    ) : (
      <span
        ref={(el) => {
          this.container = el;
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.enableEdition();
        }}
      >
        {!focus ? (
          this.translation
        ) : (
          <AutosizeInput
            ref={(el) => {
              this.input = el;
            }}
            disabled={submitting}
            inputStyle={this.inputStyle}
            value={value || ''}
            onChange={(e) => this.setState({ value: e.target.value })}
            onClick={(e) => e.stopPropagation()}
            onBlur={() => this.disableEdition()}
            onKeyUp={(e) => {
              // Stop event diffusion to avoid button click event when press backspace
              e.preventDefault();
              e.stopPropagation();
              if (e.key === 'Enter') {
                this.submit();
              }
            }}
          />
        )}
        {submitting && <Spinner className="ml-2" size="sm" />}
      </span>
    );
  }
}

Translation.contextType = Context;

Translation.propTypes = {
  children: PropTypes.string.isRequired, // key
  number: PropTypes.number
};

Translation.defaultProps = {
  number: null
};

export default Translation;
