import Api from '@/services/api';
import Resource from '@/services/resources/common';

export default class DocumentHistoricResource extends Resource {
  constructor(entrypoint = '/document_historics', alias = 'DocumentHistoric') {
    super(entrypoint, alias);
  }

  download(id) {
    return Api.get(`${this.entrypoint}/${id}/download`);
  }
}
