import * as yup from 'yup';

/**
 * Date extension validator
 *
 * @see @/services/mapping/validator
 */
export default {
  supports: ['date', 'time', 'datetime'],

  build({ constraints }) {
    let rule = yup.date().nullable();

    for (let i = 0, len = constraints.length; i < len; ++i) {
      switch (constraints[i].alias) {
        default:
          rule = rule.typeError(constraints[i].message);
          break;
      }
    }

    return rule;
  }
};
