export default {
  /**
   * Capitalize the first char of string
   *
   * @param {string} string
   *
   * @return {string}
   */
  ucfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  /**
   * Lower the first char of string
   *
   * @param {string} string
   *
   * @return {string}
   */
  lcfirst(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  },

  /**
   * Generate random string
   *
   * @param {number}  length
   * @param {number}  radix
   * @param {boolean} lettersOnly - use only letter
   *
   * @return {string}
   */
  random(length, radix, lettersOnly = false) {
    if (lettersOnly) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    }

    return Math.random()
      .toString(radix || 36)
      .substring(length);
  },

  camelCaseToSnakeCase(str) {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  }
};
