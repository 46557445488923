/* eslint-disable global-require */

/** @todo TO FINISH IT. */
export default [
  // {
  //   path: 'demo',
  //   name: 'demo',
  //   component: require('@/components/layout/view-layout').default,
  //   routes: [
  //     {
  //       path: '/insight',
  //       name: 'insight',
  //       component: require('@/views/demo/insight').default,
  //       title: 'Renseignement'
  //     },
  //     {
  //       path: '/actions',
  //       name: 'actions',
  //       component: require('@/views/demo/actions').default,
  //       title: 'Actions'
  //     }
  //   ]
  // }
];
