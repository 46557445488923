import React from 'react';

import { context } from '@/services/context';
import notifications from '@/services/notifications';
import getResource from '@/services/resources';
import router from '@/services/router';
import object from '@/services/utils/object';

import Translation from './translation';

/**
 * Display editable translation component
 *
 * @param {string} key     - translation key
 * @param {number} number  - for pluralization
 *
 * @return {JSX.Element}
 */
export const t = (key, number) => <Translation number={number}>{key}</Translation>;

/**
 * Translator service is used to fetch and sync API translations
 */
export default {
  /**
   * Languages storage
   *
   * @type {array}
   */
  languages: null,

  /**
   * Translation storage
   *
   * @type {object}
   *
   * @example {
   *   fr: {...}
   * }
   */
  translations: {},

  /**
   * Fetch translations for a specific locale
   *
   * @param {string}  locale
   * @param {boolean} force
   *
   * @return {Promise}
   */
  fetch(locale, force) {
    if (this.translations[locale] !== undefined && !force) {
      return Promise.resolve();
    }

    return getResource('Translation')
      .fetchByLocale(locale)
      .then((translations) => {
        this.translations[locale] = translations;
        context.update({ locale });
      })
      .catch(() => {
        notifications.error(
          t('error_occurred'),
          <>
            {t('impossible_to_fetch_translation')} : {locale}
          </>
        );
      });
  },

  /**
   *
   * @param {string} key
   * @param {number} number
   * @param {string} locale
   *
   * @return {string}
   */
  translate(key, number = 0, locale) {
    const currentLocale = locale || this.getLocale();

    const translation = this.translations[currentLocale] && this.translations[currentLocale][key];

    if (typeof translation !== 'object') {
      return key;
    }

    // eslint-disable-next-line no-nested-ternary
    return typeof number === 'number' && number > 1
      ? translation.pluralValue
        ? translation.pluralValue.replace('$n', number)
        : translation.value.replace('$n', number)
      : translation.value.replace('$n', number);
  },

  /**
   * Get translation in storage
   *
   * @param {string} key
   * @param {string} locale
   *
   * @return {object|undefined}
   */
  getTranslation(key, locale) {
    const currentLocal = locale || this.getLocale();

    return this.translations[currentLocal] && this.translations[currentLocal][key];
  },

  /**
   * Update translation in class storage
   *
   * @param {object} translation
   * @param {string} locale
   *
   * @return {self}
   */
  setTranslation(translation, locale) {
    this.translations[locale] = translation;
    return this;
  },

  /**
   * Change current locale
   *
   * @param {string} locale
   * @return {Promise<void>}
   */
  setLocale(locale) {
    return this.fetch(locale).then(() => {
      context.update({ locale });
    });
  },

  /**
   * Get current locale
   *
   * @return {string}
   */
  getLocale() {
    return context.locale;
  },

  /**
   *
   * @param force
   * @return {Promise<*>|Promise<array>}
   */
  fetchLanguages(force) {
    if (this.languages !== null && !force) {
      return Promise.resolve(this.languages);
    }

    return getResource('Language')
      .list({ perPage: 1000 })
      .then((data) => {
        this.languages = data['hydra:member'];
      });
  },

  /**
   * Refresh language in class storage
   *
   * @param {object} language
   */
  refreshLanguage(language) {
    if (this.languages === null) {
      this.languages = [language];
      return;
    }

    for (let i = 0, len = this.languages.length; i < len; ++i) {
      if (this.languages[i].id === language.id) {
        this.languages[i] = { ...this.languages[i], ...language };
        return;
      }
    }

    this.languages.push(language);
  },

  /**
   * Synchronize a translation with api
   *
   * @param {string}  key
   * @param {string}  syncValue - value or pluralValue
   * @param {string}  locale
   * @param {boolean} isPluralValue
   *
   * @return {Promise}
   */
  sync(key, syncValue, locale, isPluralValue) {
    const currentLocale = locale || this.getLocale();

    const translation = this.getTranslation(key, locale);
    const payload = {
      ...translation,
      locale: currentLocale,
      key,
      [isPluralValue ? 'pluralValue' : 'value']: syncValue
    };
    const isCreation = translation === undefined || translation.locale !== currentLocale;
    const verb = isCreation ? 'create' : 'update';

    if (isCreation) {
      payload.value = syncValue;
      payload.lang = object.get(`@language.@id`, this.translations[currentLocale]);
    }

    return getResource('Translation')
      [verb](payload)
      .then(({ id, value, pluralValue }) => {
        this.translations[currentLocale][key] = {
          ...this.translations[currentLocale][key],
          id,
          value,
          pluralValue,
          locale: currentLocale
        };

        context.update({ locale: currentLocale });
        notifications.success(
          'Success',
          <div onClick={() => router.navigateToResource('Translation', 'update', { id })} aria-hidden>
            {`Translation "${key}" for locale "${currentLocale}" has been`} <strong>{verb}d</strong>
            {isPluralValue && (
              <>
                <br />
                <small>You edited the plural value, please check simple value</small>
              </>
            )}
          </div>
        );
      })
      .catch(() => {
        notifications.error(
          'Oops an error has occurred',
          `Impossible to ${isCreation ? 'create' : 'update'} translation "${key}" for locale "${currentLocale}"`
        );
      });
  }
};
