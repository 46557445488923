import React, { useContext, useEffect } from 'react';
import { Col, Row, Table } from 'reactstrap';

import Media from '@/components/common/media';
import { Input } from '@/components/form';
import ResourceListAdmin from '@/components/resources/list';
import ResourceListManager from '@/components/resources/listAdmin';
import View from '@/components/view';
import Context from '@/services/context';
import security from '@/services/security';

export default () => {
  const { community } = useContext(Context);
  const isManager =
    !security.isGranted('ROLE_ADMIN') &&
    !security.isGranted('ROLE_SUPER_ADMIN') &&
    !security.isGranted('ROLE_ACCOUNT_MANAGER');
  const ResourceList = isManager ? ResourceListManager : ResourceListAdmin;

  useEffect(() => {}, [community]);

  return (
    <View>
      <ResourceList
        resource="Community"
        admin={security.isGranted('ROLE_ADMIN') || security.isGranted('ROLE_ACCOUNT_MANAGER')}
        extraParameters={community}
        deleteMultiple
        filters={() => (
          <Row>
            <Col lg={3}>
              <Input name="name" />
            </Col>
          </Row>
        )}
        body={({ collection, renderColumn, renderItemActions, renderSelect }) => (
          <Table responsive hover>
            <thead className="thead-light">
              <tr>
                {renderColumn(renderSelect('*'), false)}
                {renderColumn('id')}
                {renderColumn('name')}
                {renderColumn('logo')}
                {renderColumn('url')}
                {renderColumn()}
              </tr>
            </thead>
            <tbody>
              {collection.map((item) => (
                <tr key={item.id}>
                  <td>{renderSelect(item.id)}</td>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>
                    <Media data={item.logo} />
                  </td>
                  <td>{item.url}</td>
                  <td>{renderItemActions(item)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      />
    </View>
  );
};
