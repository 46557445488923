import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'adminapp_menu_diagnostics',
    icon: <i className="ni ni-planet text-blue" />,
    items: [
      {
        label: 'adminapp_menu_diagnostics_editor',
        route: 'diagnostic_editor',
        icon: <Icon name="object-group" className="text-info iconRightMarginMid" />,
        roles: ['ROLE_ACCOUNT_MANAGER']
      },
      {
        label: 'adminapp_menu_diagnostic_list',
        route: 'diagnostic_list',
        icon: <Icon name="file-invoice" className="text-info iconRightMarginMid" />
      },
      // --- PATTERN
      {
        label: 'adminapp_menu_diagnostic_library',
        icon: <Icon name="clone" className="iconRightMarginMid" />,
        route: 'pattern',
        roles: ['ROLE_ADMIN'],
        items: [
          {
            label: 'adminapp_menu_diagnostic_expected_response_pattern',
            route: 'expected_response_pattern',
            icon: <Icon name="question" className="iconRightMarginSmall" />,
            items: [
              {
                label: 'adminapp_menu_diagnostic_expected_response_pattern_list',
                route: 'expected_response_pattern_list'
              },
              {
                label: 'adminapp_menu_diagnostic_expected_response_pattern_create',
                route: 'expected_response_pattern_create'
              }
            ]
          },
          {
            label: 'adminapp_menu_diagnostic_question_pattern',
            route: 'question_pattern',
            icon: <Icon name="question-circle" className="iconRightMarginSmall" />,
            items: [
              {
                label: 'adminapp_menu_diagnostic_question_pattern_list',
                route: 'question_pattern_list'
              },
              {
                label: 'adminapp_menu_diagnostic_question_pattern_create',
                route: 'question_pattern_create'
              }
            ]
          },
          {
            label: 'adminapp_menu_diagnostic_pattern',
            route: 'diagnostic_pattern_list',
            icon: <Icon name="file-alt" className="iconRightMarginSmall" />
          }
        ]
      },
      // ----- CONFIG
      {
        label: 'adminapp_menu_diagnostic_configuration',
        icon: <Icon name="cog" className="iconRightMarginMid" />,
        route: 'configuration',
        roles: ['ROLE_ADMIN'],
        items: [
          {
            label: 'adminapp_menu_diagnostic_tag',
            route: 'tag',
            icon: <Icon name="palette" className="iconRightMarginSmall" />,
            items: [
              {
                label: 'adminapp_menu_diagnostic_tag_list',
                route: 'tag_list'
              },
              {
                label: 'adminapp_menu_diagnostic_tag_create',
                route: 'tag_create'
              }
            ]
          },
          {
            label: 'adminapp_menu_diagnostic_type',
            route: 'tag',
            icon: <Icon name="list-ol" className="iconRightMarginSmall" />,
            items: [
              {
                label: 'adminapp_menu_diagnostic_type_list',
                route: 'diagnostic_type_list'
              },
              {
                label: 'adminapp_menu_diagnostic_type_create',
                route: 'diagnostic_type_create'
              }
            ]
          },
          {
            label: 'adminapp_menu_diagnostic_type',
            route: 'type',
            icon: <Icon name="balance-scale" className="iconRightMarginSmall" />,
            items: [
              {
                label: 'adminapp_menu_diagnostic_type_list',
                route: 'type_list'
              },
              {
                label: 'adminapp_menu_diagnostic_type_create',
                route: 'type_create'
              }
            ]
          },
          {
            label: 'adminapp_menu_diagnostic_condition_types',
            route: 'condition_types',
            icon: <Icon name="drafting-compass" className="iconRightMarginSmall" />,
            items: [
              {
                label: 'adminapp_menu_diagnostic_condition_types_list',
                route: 'condition_types_list'
              },
              {
                label: 'adminapp_menu_diagnostic_condition_types_create',
                route: 'condition_types_create'
              }
            ]
          }
        ]
      },
      {
        label: 'adminapp_menu_diagnostic_actions',
        icon: <Icon name="cog" className="iconRightMarginMid" />,
        route: 'actions',
        roles: ['ROLE_ADMIN'],
        items: [
          {
            label: 'adminapp_menu_diagnostic_actions',
            route: 'actions',
            icon: <Icon name="palette" className="iconRightMarginSmall" />,
            items: [
              {
                label: 'adminapp_menu_diagnostic_action_list',
                route: 'actions_list'
              },
              {
                label: 'adminapp_menu_diagnostic_action_create',
                route: 'actions_create'
              }
            ]
          },
          {
            label: 'adminapp_menu_diagnostic_action_type',
            route: 'action_type',
            icon: <Icon name="palette" className="iconRightMarginSmall" />,
            items: [
              {
                label: 'adminapp_menu_diagnostic_action_type_list',
                route: 'action_type_list'
              },
              {
                label: 'adminapp_menu_diagnostic_action_type_create',
                route: 'action_type_create'
              }
            ]
          }
        ]
      }
    ]
  }
];
