import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Context from '@/services/context';

export default () => (
  <Context.Consumer>
    {({ modal, closeModal }) => (
      <Modal className="modal-dialog-centered" isOpen={modal.show} toggle={closeModal} size={modal.size || 'sm'}>
        <ModalHeader tag="div">
          {modal.title}
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={closeModal}>
            <span aria-hidden={true}>×</span>
          </button>
        </ModalHeader>
        <ModalBody>{modal.content}</ModalBody>
        {modal.actions && <ModalFooter>{modal.actions}</ModalFooter>}
      </Modal>
    )}
  </Context.Consumer>
);
