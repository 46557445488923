// import React from 'react';

// import Icon from '@/components/common/icon';

export default [
  // {
  //   label: 'adminapp_menu_actions',
  //   icon: <Icon name="exclamation" className="iconRightMarginMax" style={{ width: '1.25rem' }} />,
  //   route: 'demo_actions'
  // },
  // {
  //   label: 'adminapp_menu_renseignements',
  //   icon: <Icon name="question" className="iconRightMarginMax" style={{ width: '1.25rem' }} />,
  //   route: 'demo_insight',
  //   roles: ['ROLE_ADMIN']
  // }
];
