import React from 'react';
import ContentLoader, { BulletList } from 'react-content-loader';
import {
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Nav,
  Navbar,
  NavbarBrand,
  Spinner
} from 'reactstrap';

import logo from '@/assets/img/brand/logo.png';
import Icon from '@/components/common/icon';
import Header from '@/components/layout/headers/header';

const backgroundColor = '#e9ecef';
const foregroundColor = '#dee2e6';

export default () => (
  <>
    <Navbar className="navbar-vertical fixed-left navbar-light bg-white" expand="md" id="sidenav-main">
      <NavbarBrand className="pt-0">
        <img alt="logo" className="navbar-brand-img" src={logo} />
      </NavbarBrand>
      <Collapse navbar isOpen={false}>
        <BulletList foregroundColor={foregroundColor} backgroundColor={backgroundColor} />
        <BulletList foregroundColor={foregroundColor} backgroundColor={backgroundColor} />
        <BulletList foregroundColor={foregroundColor} backgroundColor={backgroundColor} />
      </Collapse>
    </Navbar>
    <div className="main-content">
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <ContentLoader
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
            width="300"
            height="60"
            viewBox="0 0 500 100"
          >
            <rect x="0" y="35" rx="12" ry="12" width="400" height="25" />
          </ContentLoader>
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <Icon name="circle" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="text" disabled />
              </InputGroup>
            </FormGroup>
          </Form>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <ContentLoader
              height={60}
              width={50}
              backgroundColor={backgroundColor}
              foregroundColor={foregroundColor}
              viewBox="0 0 200 200"
              className="ml-3"
            >
              {/* Only SVG shapes */}
              <circle cx="100" cy="100" r="100" />
            </ContentLoader>
          </Nav>
        </Container>
      </Navbar>
      <Header />
      <Container className="text-center mt--8" fluid>
        <Spinner style={{ width: '4rem', height: '4rem' }} color="secondary" />
      </Container>
    </div>
  </>
);
