import { connect } from 'formik';
import React from 'react';

import { Select } from '@/components/form';
import api from '@/services/api';
import getResource from '@/services/resources';
import object from '@/services/utils/object';

export default connect(
  class Resource extends React.Component {
    state = {
      collection: [],
      loading: false
    };

    componentDidMount() {
      if (!this.props.autocomplete) {
        this.fetch();
      }
    }

    fetch() {
      this.setState({ loading: false });

      const { resource, filters, formik } = this.props;
      const id = resource + JSON.stringify(filters);

      if (formik.referencials && formik.referencials[id]) {
        return this.setState({
          collection: formik.referencials[id],
          loading: false
        });
      }

      let promise = Promise.resolve({});
      if (resource) {
        promise = getResource(resource).list(filters);
      } else {
        if (formik.mapping) {
          const mappingEntrypoint = formik.mapping.getEntrypoint(this.props.name);

          if (mappingEntrypoint) {
            promise = api.get(mappingEntrypoint, filters).then(({ data }) => data);
          }
        }
      }

      promise.then((data) => {
        this.setState(
          {
            collection: data['hydra:member'] || [],
            loading: false
          },
          () => {
            formik.setFormikState({
              ...formik,
              referencials: {
                ...formik.referencials,
                [id]: data['hydra:member'] || []
              }
            });
          }
        );
      });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      const { name, formik, useId, targetProperty, multiple } = this.props;
      const { value } = formik.getFieldProps(name) || {};

      const fieldValue = multiple ? value : [value];
      let realFieldValue = [];

      if (Array.isArray(fieldValue)) {
        for (let i = 0, len = fieldValue.length; i < len; ++i) {
          if (fieldValue[i] && typeof fieldValue[i] === 'object') {
            realFieldValue.push(targetProperty ? fieldValue[i][targetProperty] : fieldValue[i][useId ? 'id' : '@id']);
          } else {
            realFieldValue.push(fieldValue[i]);
          }
        }
      }

      realFieldValue = multiple ? realFieldValue : realFieldValue[0];
      if (JSON.stringify(value) !== JSON.stringify(realFieldValue)) {
        realFieldValue !== null && this.props.formik.setFieldValue(name, realFieldValue);
      }

      if (realFieldValue === null) {
        return this.props.formik.setFieldValue(name, undefined);
      }
    }

    buildOptions() {
      const { useId, targetProperty } = this.props;

      return this.state.collection.map((item) => {
        const option = {
          value: targetProperty ? item[targetProperty] : item[useId ? 'id' : '@id'],
          label: item.id
        };

        const { display } = this.props;

        if (typeof display === 'string') {
          option.label = object.get(display, item);
        } else if (typeof display === 'function') {
          option.label = display(item);
        }

        return option;
      });
    }

    render() {
      const { loading } = this.state;
      const { display, resource, filters, entrypoint, inputTag, formik, ...props } = this.props;
      const InputTag = inputTag || Select;

      return <InputTag isLoading={loading} isDisabled={loading} options={this.buildOptions()} {...props} />;
    }
  }
);
