/* eslint-disable global-require */

export default [
  {
    path: 'communities',
    name: 'communities',
    resource: 'Community',
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/community/list').default,
        title: 'communities_list_title'
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/community/form').default,
        title: 'communities_create_title',
        roles: ['ROLE_ACCOUNT_MANAGER'],
        permissions: {
          Community: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/community/form').default,
        title: 'communities_update_title',
        permissions: {
          Community: ['read', 'update']
        }
      }
    ]
  },
  {
    path: 'communities_type_client',
    name: 'type-client',
    resource: 'TypeClient',
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/type-client/list').default,
        title: 'type-client_list_title'
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/type-client/form').default,
        title: 'type-client_create_title',
        roles: ['ROLE_ACCOUNT_MANAGER'],
        permissions: {
          TypeClient: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/type-client/form').default,
        title: 'type-client_update_title',
        permissions: {
          TypeClient: ['read', 'update']
        }
      }
    ]
  }
];
