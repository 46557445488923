/* eslint-disable global-require */

export default [
  {
    path: 'sheet_actions',
    name: 'sheet_actions',
    resource: 'SheetAction',
    roles: ['ROLE_MANAGER'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '/view',
        name: 'view',
        component: require('@/components/layout/view-layout').default,
        roles: ['ROLE_MANAGER'],
        routes: [
          {
            path: '/:id',
            name: 'view',
            component: require('@/views/sheet-action/view').default,
            title: 'sheet-actions_view_single_title',
            permissions: {
              SheetAction: ['read']
            }
          }
        ]
      },
      {
        path: '/assign',
        name: 'assign',
        component: require('@/components/layout/view-layout').default,
        roles: ['ROLE_MANAGER'],
        routes: [
          {
            path: '/:id',
            name: 'assign',
            component: require('@/views/sheet-action/assign').default,
            title: 'sheet-actions_assign_single_title',
            permissions: {
              SheetAction: ['read']
            }
          }
        ]
      },
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/sheet-action/list').default,
        title: 'sheet-actions_list_title',
        permissions: {
          SheetAction: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/sheet-action/form').default,
        title: 'sheet-actions_create_title',
        roles: ['ROLE_MANAGER'],
        permissions: {
          SheetAction: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/sheet-action/form').default,
        title: 'sheet-actions_update_title',
        permissions: {
          SheetAction: ['read', 'update']
        }
      },
      {
        path: '/:id',
        name: 'delete',
        component: require('@/views/sheet-action/form').default,
        title: 'sheet-actions_delete_title',
        permissions: {
          SheetAction: ['delete']
        }
      }
    ]
  },
  {
    path: 'sheet_action_category',
    name: 'category',
    resource: 'SheetActionCategory',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        exact: true,
        context: 'read:list',
        component: require('@/views/sheet-action/sheet-action-category/list').default,
        title: 'category_list_title',
        permissions: {
          SheetActionCategory: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/sheet-action/sheet-action-category/form').default,
        title: 'category_create_title',
        permissions: {
          SheetActionCategory: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/sheet-action/sheet-action-category/form').default,
        title: 'category_update_title',
        permissions: {
          SheetActionCategory: ['read', 'update']
        }
      },
      {
        path: '/:id',
        name: 'delete',
        component: require('@/views/sheet-action/sheet-action-category/form').default,
        title: 'category_delete_title',
        permissions: {
          SheetActionCategory: ['delete']
        }
      }
    ]
  }
];
