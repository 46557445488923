import validator from '@/services/mapping/validator';
import { t } from '@/services/translator';
import object from '@/services/utils/object';

export default class Mapping {
  /**
   * Init mapping service
   *
   * @param {?object} definition
   * @param {?object} validator
   */
  constructor(definition = {}, _validator) {
    this.definition = definition;
    this.validator = _validator;
  }

  /**
   * Get validation rules for mapping definition
   *
   * @return {object}
   */
  get rules() {
    if (!this.validator) {
      this.validator = validator.build(this.definition);
    }

    return this.validator;
  }

  /**
   * Get default values based on mapping
   *
   * @param {object} properties
   *
   * @return {object}
   */
  getDefaultValues(properties = this.definition) {
    const obj = {};

    // eslint-disable-next-line guard-for-in
    for (const prop in properties) {
      const definition = properties[prop];

      if (definition.default !== null) {
        obj[prop] =
          Array.isArray(definition.properties) && definition.properties.length > 0
            ? this.getDefaultValues(definition.properties)
            : definition.default;
      }
    }

    return object.clone(obj, {});
  }

  /**
   * Find mapping config for a property path
   *
   * @param {string} path
   *
   * @return {object}
   */
  find(path) {
    const pathData = path.split('.');
    let mapping = this.definition;
    let prop = '';

    // Browse path
    for (let i = 0, len = pathData.length; i < len; ++i) {
      prop = pathData[i];

      // Remove array notation like addresses[0] to addresses
      // to get correct property name
      const arrayNotationIndex = prop.indexOf('[');
      if (arrayNotationIndex !== -1) {
        prop = prop.substring(0, arrayNotationIndex);
      }

      // No mapping found for defined path
      if (mapping === undefined || !mapping.hasOwnProperty(prop)) {
        mapping = undefined;
        break;
      }

      mapping = mapping[prop];

      // Nested property with "."
      if (i < len - 1) {
        mapping = mapping.properties;
      }
    }

    return mapping;
  }

  /**
   * Check if path exists in mapping definition
   *
   * @param {string} path
   *
   * @return {boolean}
   */
  has(path) {
    return this.find(path) !== undefined;
  }

  /**
   * Get constraints of property path in mapping definition
   *
   * @param {string} path
   *
   * @return {null|array}
   */
  getConstraints(path) {
    const mapping = this.find(path);

    if (!mapping || !mapping.constraints) {
      return null;
    }

    return mapping.constraints;
  }

  /**
   * Get a specific constraint for a property path in mapping definition
   *
   * @param {string} path
   * @param {string} alias - Alias of constraint
   *
   * @return {null|object}
   */
  getConstraint(path, alias) {
    const constraints = this.getConstraints(path);

    if (constraints) {
      for (let i = 0, len = constraints.length; i < len; ++i) {
        if (constraints[i].alias === alias) {
          return constraints[i];
        }
      }
    }

    return null;
  }

  /**
   * Extract choices of Choice constraint if exists for a property path
   *
   * @param {string} path
   *
   * @return {array}
   */
  getChoices(path) {
    const constraint = this.getConstraint(path, 'Choice');

    if (constraint && constraint.choices) {
      if (Array.isArray(constraint.choices)) {
        return constraint.choices.map((value) => ({ label: t(value), value }));
      }

      if (typeof constraint.choices === 'object') {
        return Object.keys(constraint.choices).map((key) => ({ label: t(key), value: constraint.choices[key] }));
      }
    }

    return [];
  }

  /**
   * Test if path contains NotNull constraint or a constraint with "min" property
   *
   * @param {string} path
   *
   * @return {boolean}
   */
  isRequired(path) {
    const constraints = this.getConstraints(path);

    if (constraints) {
      for (let i = 0, len = constraints.length; i < len; ++i) {
        if (constraints[i].alias === 'NotNull' || constraints[i].min >= 1) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Get entrypoint to list items of specific nested property
   *
   * @param {string} path
   *
   * @return {?string}
   */
  getEntrypoint(path) {
    const config = this.find(path);

    return config && config.context;
  }
}
