/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import 'wever-maps/src/assets/style.scss';

import '@/assets/scss/components/tab-sheet-action.scss';
import Tabs from '@/components/common/tabs';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';
import Api from '@/services/api';
import notif from '@/services/notifications';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

import Description from './description';
import Discussion from './discussion';
import Operation from './operation';
import Review from './review';
import DashboardTabs from './show_dashboard';
import UserFeedback from './user-feedback';

export default ({ match }) => {
  const [permitDiscussion, setPermitDiscussion] = useState(false);
  const [load, setLoad] = useState(false);

  const communityHasActionSheet = async () => {
    const response = await getResource('Community').managedList();

    setPermitDiscussion(
      response.some((community) =>
        community.sheetActions.some(
          (action) => parseInt(action.replace('/sheet_actions/', '')) === parseInt(match.params.id)
        )
      )
    );
  };

  const accept = async () => {
    setLoad(true);
    try {
      await Api.get('/sheet_actions/events');
      notif.success(t('adminapp_success'), t('adminapp_sheet_action_accepted'));
    } catch (e) {
      console.error(e);
    }
    setLoad(false);
  };

  useEffect(() => {
    communityHasActionSheet();
  }, []);

  return (
    <View>
      <ResourceForm resource="SheetAction" id={match.params.id} initialValues={{ name: '', url: '', partners: [] }}>
        {({ submitting, values, renderActions, mapping }) => (
          <>
            <Tabs className="tab-sheet-action" baseUri={match.url}>
              <Description color="danger" tab={t('adminapp_description')} resource={values} />
              <DashboardTabs
                tab={t('adminapp_user_feedback')}
                dashboard={values.feedbackDashboard}
                path="user_feedback"
                type="feedback"
                id={match.params.id}
              />
              {permitDiscussion && <Discussion actionSheet={values} tab={t('adminapp_discussion')} path="discussion" />}
              <DashboardTabs
                tab={t('adminapp_operation')}
                path="operation"
                dashboard={values.dimensionDashboard}
                type="operation"
                id={match.params.id}
              />
              <DashboardTabs
                tab={t('adminapp_review')}
                path="review"
                dashboard={values.evaluationDashboard}
                type="evaluation"
                id={match.params.id}
              />
            </Tabs>
            <div className="mt-4 mb-4">
              <Button className="btn-wever bg-pink-wever" onClick={accept} disabled={load} type="button">
                {load ? t('adminapp_loading') : t('adminapp_accept_sheet_action')}
              </Button>
            </div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};
