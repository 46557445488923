/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Badge, Table, Button } from 'reactstrap';
import { _Api } from 'wever-dashboard/dist/services/core';
import { _Notifications } from 'wever-maps/dist/services/core';

import Icon from '@/components/common/icon';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

const Tab = (props) => {
  const [communities, setCommunities] = useState([]);
  const [aCommnunities, setACommunities] = useState([]);
  const [logos, setLogos] = useState([]);

  const loadCommunities = async () => {
    const response = await getResource('Community').list();
    setCommunities(response['hydra:member']);
    let temp = [];
    for (const community of response['hydra:member']) {
      if (community.logo !== null) {
        const res = await getResource('Media').read(community.logo.replace(/^\D+/g, ''));
        temp.push(res.path);
      }
    }
    setLogos(temp);

    temp = [];
    for (const community of response['hydra:member']) {
      if (community.sheetActions.length !== 0) {
        for (const action of community.sheetActions) {
          const res = await _Api.get(action['@id']);
          if (props.sheetAction.id === res.data.sheetAction.id) {
            temp.push(community);
          }
        }
      }
    }
    setACommunities(temp);
  };

  const attach = async (id) => {
    let temp = [...aCommnunities];
    if (!aCommnunities.some((item) => item.id === id)) {
      temp.push(...communities.filter((item) => item.id === id));
      temp.forEach((community) => {
        if (community.id === id && !community.sheetActions.some((el) => el.sheetAction === props.sheetAction['@id'])) {
          community.sheetActions.push({
            sheetAction: props.sheetAction['@id'],
            date: new Date().toLocaleString()
          });
        }
      });

      const newCommunity = Object.assign({}, ...temp.filter((item) => item.id === id));

      await getResource('Community')
        .read(id)
        .then(async () => {
          await getResource('Community')
            .update(newCommunity)
            .catch((err) => console.error(err));
          _Notifications.success(t('adminapp_assignement'), t('adminapp_succesfully_assigned_to_the_community'));
        })
        .catch((err) => console.error(err));

      setACommunities(temp);

      temp = [...communities];
      setCommunities(temp);
    }
  };

  const detach = async (id) => {
    let temp = [...aCommnunities];
    if (aCommnunities.some((item) => item.id === id)) {
      let updatedCommunity = Object.assign({}, ...temp.filter((community) => community.id === id));

      for (const action of updatedCommunity.sheetActions) {
        const actionResponse = await _Api.get(action['@id']);
        if (
          actionResponse.data.sheetAction.id === props.sheetAction.id ||
          action.sheetAction === props.sheetAction['@id']
        ) {
          updatedCommunity.sheetActions.splice(updatedCommunity.sheetActions.indexOf(action), 1);
        }
      }

      await getResource('Community')
        .update(updatedCommunity)
        .catch((err) => console.error(err));

      const res = await getResource('Community').list();
      setCommunities(res['hydra:member']);

      temp = temp.filter((community) => community.id !== id);
      setACommunities(temp);
    }
  };

  useEffect(() => {
    loadCommunities();
  }, []);

  return (
    <div>
      <h4>{t('adminapp_Assigned_communities')}</h4>
      {aCommnunities.length === 0 ? (
        <h1>
          <Badge color="dark">{t('adminapp_no_assigned_community')}</Badge>
        </h1>
      ) : (
        <Table responsive hover>
          <thead>
            <tr>
              <th>#</th>
              <th>{t('adminapp_tab_sheet_id')}</th>
              <th>{t('adminapp_tab_sheet_name')}</th>
              <th>{t('adminapp_tab_sheet_logo')}</th>
              <th>{t('adminapp_tab_sheet_url')}</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(aCommnunities) &&
              aCommnunities.map((community, index) => (
                <tr key={community}>
                  <th scope="row">{index + 1}</th>
                  <td>{community.id}</td>
                  <td>{community.name}</td>
                  <td>
                    <img width="50%" src={logos[index]} alt="logo" />
                  </td>
                  <td>{community.url}</td>
                  <Button onClick={() => detach(community.id)} color="primary">
                    <Icon name="minus" />
                  </Button>
                </tr>
              ))}
          </tbody>
        </Table>
      )}

      <h4>{t('adminapp_All_communities')}</h4>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>{t('adminapp_tab_sheet_id')}</th>
            <th>{t('adminapp_tab_sheet_name')}</th>
            <th>{t('adminapp_tab_sheet_logo')}</th>
            <th>{t('adminapp_tab_sheet_url')}</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(communities) &&
            communities.map((community, index) => (
              <tr key={community}>
                <th scope="row">{index + 1}</th>
                <td>{community.id}</td>
                <td>{community.name}</td>
                <td>
                  <img width="50%" src={logos[index]} alt="logo" />
                </td>
                <td>{community.url}</td>
                <Button
                  hidden={aCommnunities.filter((element) => element.id === community.id).length !== 0}
                  onClick={() => attach(community.id)}
                  color="primary"
                >
                  <Icon name="plus" />
                </Button>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Tab;
