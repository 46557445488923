/* eslint-disable react-hooks/exhaustive-deps  */
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Spinner } from 'reactstrap';

import getResource from '@/services/resources';
import { t } from '@/services/translator';

import Item from './items';

const Dashboard = ({ type }) => {
  const [field, , helpers] = useField({ name: 'items' });
  const [loadedType, setLoadedType] = useState();

  const addItem = () => {
    const items = field.value || [];
    items.push({ id: null, name: '', graphType: null, graphFunction: '', parameters: {}, colspan: 4 });

    helpers.setValue(items);
  };

  const handleDelete = (index) => {
    const items = field.value || [];

    items.splice(index, 1);
    helpers.setValue(items);
  };

  const loadType = async () => {
    const response = await getResource('DashboardLayoutType').read(type.replace('/dashboard_layout_types/', ''));
    setLoadedType(response.name);
  };

  useEffect(() => {
    if (!type) {
      return;
    }

    if (typeof type === 'string') {
      loadType();
    }

    setLoadedType(type.name);
  }, [type]);

  if (!loadedType) {
    return (
      <div>
        {t('app_loading')}
        <Spinner size="sm" color="primary" />
      </div>
    );
  }

  return (
    <Row>
      <Col lg="12">
        <Button onClick={addItem} color="primary" size="sm">
          {t('add_dashboard_component')}
        </Button>
      </Col>
      <Col lg="12" className="mt-2">
        <Row>
          {field.value &&
            field.value.map((item, index) => {
              return (
                <Item type={loadedType} index={index} value={item} key={index} onDelete={() => handleDelete(index)} />
              );
            })}
        </Row>
      </Col>
    </Row>
  );
};

Dashboard.propTypes = {
  type: PropTypes.string.isRequired
};

export default Dashboard;
