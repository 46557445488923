import React from 'react';
import { Col, Row } from 'reactstrap';

import { Input, Datepicker } from '@/components/form';

const ContractPartnerInformation = () => (
  <Row className="m-0">
    <Col lg={12}>
      <Row>
        <Col lg={12}>
          <Input name="name" />
        </Col>
        <Col lg={12}>
          <Input name="key" />
        </Col>
        <Col lg={12}>
          <Datepicker name="expireAt" />
        </Col>
      </Row>
    </Col>
  </Row>
);

export default ContractPartnerInformation;
