/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row, Col } from 'reactstrap';

import { Editor } from '@/components/form';

// Api platform attend des IRIS. et pas des objets
export default () => (
  <Row className="m-0">
    <Col>
      <Editor name="configuration" />{' '}
    </Col>
  </Row>
);
