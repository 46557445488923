import PropTypes from 'prop-types';
import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';

import { TableLangValue } from '@/components/form';
import { t } from '@/services/translator';

const SheetActionObjective = ({ index }) => (
  <div key={`objective-${index}`}>
    <FormGroup row>
      <Label sm={5}>{t('adminapp_sheet_action_objective')}</Label>
      <Col sm={6}>
        <TableLangValue name={`objectives[${index}].name`} />
      </Col>
    </FormGroup>
  </div>
);

SheetActionObjective.propTypes = {
  index: PropTypes.number,
  objectiveState: PropTypes.array,
  handleObjectiveChange: PropTypes.func
};

export default SheetActionObjective;
