import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'adminapp_menu_server',
    icon: <Icon name="server" className="iconRightMarginMid" />,
    items: [
      {
        label: 'adminapp_menu_server_list',
        route: 'servers_list'
      },
      {
        label: 'adminapp_menu_server_create',
        route: 'servers_create'
      }
    ]
  }
];
