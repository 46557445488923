/* eslint-disable react-hooks/exhaustive-deps */
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label } from 'reactstrap';

import { Input, Select, MediaInput } from '@/components/form';
import { t } from '@/services/translator';

const SheetActionMedia = ({ index }) => {
  const [mimeType, ,] = useField({ name: `medias[${index}].mimeType` });
  const [webUrl, , helper] = useField({ name: `medias[${index}].webUrl` });
  const [error, setError] = useState();
  const typeOptions = [
    { value: 'image/*', label: 'Image' },
    { value: 'video/*', label: 'Video' },
    { value: 'audio/*', label: 'Audio' },
    { value: 'text/plain', label: 'Link' },
    { value: 'application/octet-stream', label: 'Other' }
  ];

  useEffect(() => {
    if (mimeType.value !== 'text/plain') {
      return;
    }

    if (!webUrl.value) {
      setError('This value should not be null.');
      helper.setError('This value should not be null.');
      helper.setTouched(true);
    } else {
      setError(null);
    }
  }, [mimeType.value, webUrl.value]);

  return (
    <div key={`medias-${index}`}>
      {
        <>
          <FormGroup row>
            <Label sm={5}>{t('adminapp_sheet_action_media_name')}</Label>
            <Col sm={6}>
              <Input label={null} name={`medias[${index}].originalName`} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={5}>{t('adminapp_sheet_action_media_description')}</Label>
            <Col sm={6}>
              <Input label={null} name={`medias[${index}].description`} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={5}>{t('adminapp_sheet_action_media_type')}</Label>
            <Col sm={6}>
              <Select options={typeOptions} label={null} name={`medias[${index}].mimeType`} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={5}>{t('adminapp_sheet_action_media_web_url')}</Label>
            <Col sm={6}>
              <Input label={null} name={`medias[${index}].webUrl`} required={mimeType.value === 'text/plain'} />
              {error && <span className="text-danger">{t(error)}</span>}
            </Col>
          </FormGroup>
        </>
      }
      <FormGroup row>
        <Label sm={5}>{t('adminapp_sheet_action_media_upload')}</Label>
        <Col sm={6}>
          <MediaInput nullable name={`medias[${index}].file`} resource="sheet_actions" />
        </Col>
      </FormGroup>
    </div>
  );
};

SheetActionMedia.propTypes = {
  index: PropTypes.number
};

export default SheetActionMedia;
