import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

const CardGeometry = (props) => {
  const { addClick, list, label } = props;

  return (
    <Card className="mb-3 shadow">
      <CardBody className="p-3">
        <Row>
          <Col md="4" sm="5">
            <Button color="secondary" size="sm" onClick={addClick}>
              {label}
            </Button>
          </Col>
          <Col>
            <ul>
              {list &&
                list.map((point) => {
                  return <li key={`li_${point.name}_${point.value.length}`}>{point.name}</li>;
                })}
            </ul>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

CardGeometry.propTypes = {
  label: PropTypes.oneOfType([PropTypes.any]).isRequired,
  addClick: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.any })).isRequired
};

export default CardGeometry;
