import { useField } from 'formik';
import React from 'react';
import { Button } from 'reactstrap';

import Icon from '@/components/common/icon';
import { t } from '@/services/translator';

import Action from './action';

export default ({ name, actionList, submitting, icon }) => {
  const [actionType, , helper] = useField({ name });

  const addAction = (current, setter) => {
    const action = { action: '', parameters: [] };
    current.push(action);
    setter(current);
  };

  const updateAction = (valueUpdated, index, key, key2) => {
    let { value } = actionType;
    if (!value) {
      value = {};
    }

    if (!value[index]) {
      value[index] = {};
    }

    if (!key2) {
      const [action, parameters] = valueUpdated;
      value[index][key] = action;
      value[index].parameters = { ...parameters };
    } else {
      value[index][key][key2] = valueUpdated;
    }

    helper.setValue(value);
  };

  return (
    <div className="accordion-item wever">
      <h2 className="accordion-header" id={name}>
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${name}_collapse`}
          aria-expanded="true"
          aria-controls={`#${name}_collapse`}
        >
          <span className="mr-2">{icon}</span> {t(name)}
        </button>
      </h2>
      <div id={`${name}_collapse`} className="accordion-collapse collapse show" aria-labelledby={name}>
        <div className="accordion-body">
          <Button color="info" type="button" size="sm" onClick={() => addAction(actionType.value, helper.setValue)}>
            <Icon name="plus-square" /> {t('add')}{' '}
          </Button>
          <br />
          {Array.isArray(actionType.value) &&
            actionType.value.map((element, index) => {
              return (
                <Action
                  key={`${name}_${index}`}
                  index={index}
                  value={element}
                  name={`${name}[${index}]`}
                  onChange={updateAction}
                  actionList={actionList}
                  submitting={submitting}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
