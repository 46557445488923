import * as yup from 'yup';

/**
 * String extension validator
 *
 * @see @/services/mapping/validator
 */
export default {
  supports: ['text', 'string'],

  build({ constraints }) {
    let rule = yup.string().nullable();

    for (let i = 0, len = constraints.length; i < len; ++i) {
      switch (constraints[i].alias) {
        case 'Email':
          rule = rule.email(constraints[i].message);
          break;
        case 'Length':
          rule = rule.min(constraints[i].min).max(constraints[i].max);
          break;
        case 'Regex':
          rule = rule.matches(constraints[i].htmlPattern, constraints[i].message);
          break;
        case 'Url':
          rule = rule.url();
          break;
        case 'Uuid':
          rule = rule.uuid();
          break;
        default:
          break;
      }
    }

    return rule;
  }
};
