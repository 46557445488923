import classnames from 'classnames';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';
import DatetimePicker from 'react-datetime';
import { FormGroup } from 'reactstrap';

import { Error, Label } from '@/components/form';
import Context from '@/services/context';

export default ({
  name,
  label,
  type,
  size = 'sm',
  groupTag = FormGroup,
  labelTag = Label,
  errorTag = Error,
  inputTag = DatetimePicker,
  date = true,
  time = false,
  groupProps = {},
  labelProps = {},
  inputProps = {},
  errorProps = {},
  ...props
}) => {
  const [field, meta, helpers] = useField({ name });
  const { submitCount } = useFormikContext();

  const GroupTag = groupTag;
  const LabelTag = labelTag;
  const ErrorTag = errorTag;
  const InputTag = inputTag;

  label = labelProps.text || label;
  if (label === undefined) {
    label = name;
  }

  return (
    <GroupTag {...groupProps}>
      {/* Label */}
      {label && (
        <LabelTag name={name} size={size} {...labelProps}>
          {label}
        </LabelTag>
      )}

      {/* Input */}
      <Context.Consumer>
        {({ locale }) => (
          <InputTag
            className="f-width"
            locale={locale}
            dateFormat={date}
            timeFormat={time}
            value={field.value ? moment(field.value) : null}
            onChange={(moment) => {
              helpers.setValue(!moment || typeof moment === 'string' ? moment : moment.toISOString());
            }}
            inputProps={{
              className: classnames({
                'is-invalid': (meta.touched || submitCount > 0) && meta.error !== undefined,
                'form-control': true,
                'form-control-sm': size === 'sm',
                'form-control-md': size === 'md',
                'form-control-lg': size === 'lg'
              })
            }}
            closeOnSelect
            {...props}
          />
        )}
      </Context.Consumer>

      {/* Errors */}
      <ErrorTag name={name} {...errorProps} />
    </GroupTag>
  );
};
