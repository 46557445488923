import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody } from 'reactstrap';
import Maps from 'wever-maps';
import 'wever-maps/src/assets/style.scss';

import View from '@/components/view';
import Context from '@/services/context';
import security from '@/services/security';

export default () => {
  const [id, setId] = useState(null);
  const [reportId, setReportId] = useState(null);
  const refCard = useRef();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setId(parseInt(urlParams.get('id'), 10));
    setReportId(parseInt(urlParams.get('report'), 10));
  }, []);

  if (!id) {
    return (
      <div>
        {' '}
        {`[In progress] missing map id and report id in url. "?id=:number&report=:number" (example -> id=3&report=1)`}{' '}
      </div>
    );
  }

  return (
    <View>
      <Card style={{ height: '100%' }} ref={refCard}>
        <CardBody style={{ position: 'relative', overflow: 'auto', padding: 0 }}>
          <Context.Consumer>
            {({ locale, editionMode }) => (
              <Maps
                token={security.token}
                locale={locale}
                mapId={id}
                reportId={reportId || 1}
                editionMode={editionMode}
              />
            )}
          </Context.Consumer>
        </CardBody>
      </Card>
    </View>
  );
};
