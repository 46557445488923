/* eslint-disable no-restricted-syntax */
import * as Yup from 'yup';

import extensions from './rules';

/**
 * Validator used to construct mapping definition by using extensions
 */
export default {
  /**
   * Transform mapping definition to scheme validation rules
   *
   * @param {object} mapping - definition of mapping
   *
   * @return {object}
   */
  build(mapping) {
    const rules = {};

    for (const prop in mapping) {
      if (!mapping.hasOwnProperty(prop)) {
        continue;
      }

      for (let i = 0, len = extensions.length; i < len; ++i) {
        const { supports } = extensions[i];
        const { type } = mapping[prop];

        if ((Array.isArray(supports) && supports.includes(type)) || type === supports || supports === '*') {
          rules[prop] = extensions[i].build(mapping[prop], rules[prop]);
        }
      }
    }

    return Yup.object(rules);
  }
};
