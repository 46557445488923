import { useField, useFormikContext } from 'formik';
import React from 'react';
import { FormGroup, Input } from 'reactstrap';

import { Error, Label } from '@/components/form';

export default ({
  id,
  name,
  label,
  value,
  size = 'sm',
  displayErrors = true,
  groupTag = FormGroup,
  labelTag = Label,
  errorTag = Error,
  inputTag = Input,
  groupProps = {},
  labelProps = {},
  inputProps = {},
  errorProps = {},
  ...props
}) => {
  const [field, meta] = useField({ name });
  const { submitCount } = useFormikContext();

  const GroupTag = groupTag;
  const LabelTag = labelTag;
  const ErrorTag = errorTag;
  const InputTag = inputTag;

  label = labelProps.text || label;
  if (label === undefined) {
    label = name;
  }

  const labelClassName = (labelProps.className || '') + ' custom-control-label';
  id = 'checkbox-input-' + (id || name);

  return (
    <GroupTag {...groupProps}>
      <div className="custom-control custom-radio">
        {/* Input */}
        <InputTag
          id={id}
          name={name}
          type="radio"
          value={String(value)}
          onBlur={field.onBlur}
          onChange={field.onChange}
          className="custom-control-input"
          checked={String(field.value === undefined ? null : field.value) === String(value)}
          invalid={(meta.touched || submitCount > 0) && meta.error !== undefined}
          {...props}
          {...inputProps}
        />

        {label && (
          <LabelTag name={name} size={size} className={labelClassName} {...labelProps} for={id}>
            {label}
          </LabelTag>
        )}
      </div>

      {/* Errors */}
      {displayErrors && <ErrorTag name={name} {...errorProps} />}
    </GroupTag>
  );
};
