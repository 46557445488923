import { useField, useFormikContext } from 'formik';
import React from 'react';
import { FormGroup, Input } from 'reactstrap';

import { Error, Label } from '@/components/form';

export default ({
  name,
  label,
  size = 'sm',
  groupTag = FormGroup,
  labelTag = Label,
  errorTag = Error,
  inputTag = Input,
  groupProps = {},
  labelProps = {},
  inputProps = {},
  errorProps = {},
  ...props
}) => {
  const [field, meta, helpers] = useField({ name });
  const { submitCount } = useFormikContext();

  const GroupTag = groupTag;
  const LabelTag = labelTag;
  const ErrorTag = errorTag;
  const InputTag = inputTag;

  label = labelProps.text || label;
  if (label === undefined) {
    label = name;
  }

  const id = 'checkbox-input-' + name;
  const labelClassName = (labelProps.className || '') + ' custom-control-label';

  return (
    <GroupTag {...groupProps}>
      <div className="custom-control custom-checkbox">
        {/* Input */}
        <InputTag
          id={id}
          name={name}
          type="checkbox"
          checked={Boolean(field.value)}
          invalid={(meta.touched || submitCount > 0) && meta.error !== undefined}
          onBlur={field.onBlur}
          onChange={(e) => helpers.setValue(e.target.checked)}
          className="custom-control-input"
          {...props}
          {...inputProps}
        />

        {label && (
          <LabelTag name={name} size={size} className={labelClassName} {...labelProps} for={id}>
            {label}
          </LabelTag>
        )}
      </div>

      {/* Errors */}
      <ErrorTag name={name} {...errorProps} />
    </GroupTag>
  );
};
