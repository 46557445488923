export default {
  fromValuesToChoices(value, options, isMulti) {
    const values = [];
    value = !Array.isArray(value) ? [value] : value;

    for (let n = 0, vlen = value.length; n < vlen; ++n) {
      for (let i = 0, len = options.length; i < len; ++i) {
        if (options[i].value !== undefined && String(options[i].value) === String(value[n])) {
          values.push(options[i]);
        }
      }
    }

    return isMulti ? values : values[0] || null;
  },

  fromChoicesToValues(option, options, isMulti) {
    let value = option === null ? option : option.value;
    if (isMulti && option) {
      value = [];
      for (let i = 0, len = option.length; i < len; ++i) {
        value.push(option[i].value);
      }
    }

    value = Array.isArray(value) && value.length === 0 ? [] : value;

    return value === null ? undefined : value;
  },

  buildStyles(size, invalid, valid) {
    let height;
    let borderRadius;
    let fontSize;

    switch (size) {
      case 'sm':
        height = 'calc(1.8125rem + 2px)';
        borderRadius = '0.25rem';
        fontSize = '0.875rem';
        break;
      case 'lg':
        height = 'calc(3.625rem + 2px)';
        borderRadius = '0.4375rem';
        fontSize = '1rem';
        break;
      case 'md':
      default:
        height = 'calc(2.75rem + 2px)';
        borderRadius = '0.375rem';
        fontSize = '0.875rem';
        break;
    }

    let borderColor = '#cad1d7';
    if (invalid) {
      borderColor = '#fb6340';
    } else if (valid) {
      borderColor = '#2dce89';
    }

    return {
      control: (base, state) => ({
        ...base,
        minHeight: height,
        borderColor,
        borderRadius,
        boxShadow: state.isFocused ? 0 : 0,
        '&:hover': {
          borderColor
        }
      }),
      dropdownIndicator: (base) => ({
        ...base,
        padding: 4
      }),
      clearIndicator: (base) => ({
        ...base,
        padding: 4
      }),
      singleValue: (base) => ({
        ...base,
        color: '#8898aa'
      }),
      valueContainer: (base) => ({
        ...base,
        padding: '0px 6px',
        fontSize: fontSize
      }),
      input: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
        fontSize: fontSize
      }),
      menuList: (base) => ({
        ...base,
        minHeight: 'fit-content'
      }),
      option: (provided) => ({
        ...provided,
        padding: 8,
        fontSize: fontSize
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        fontSize: fontSize
      })
    };
  }
};
