import React from 'react';
import { NavItem, NavLink, Nav, Container, Row, Col, UncontrolledTooltip } from 'reactstrap';

import { t } from '@/services/translator';

export default () => (
  <footer className="py-5">
    <Container>
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            © {new Date().getFullYear()}{' '}
            <a
              id="brand-copyright"
              className="font-weight-bold ml-1"
              href="https://www.wever.fr"
              target="_blank"
              rel="noopener noreferrer"
            >
              wever SAS
            </a>
            <UncontrolledTooltip placement="right" target="brand-copyright">
              {t('wever_address')}
            </UncontrolledTooltip>
          </div>
        </Col>
        <Col xl="6">
          <Nav className="nav-footer justify-content-center justify-content-xl-end">
            <NavItem>
              <NavLink href="https://app.wever.app/#/documents" target="_blank">
                {t('officials_document')}
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </Container>
  </footer>
);
