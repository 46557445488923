import React, { useState, useEffect } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import Icon from '@/components/common/icon';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

import AccordionItem from './accordion-item';

export default ({ submitting, name }) => {
  const [actions, setActions] = useState();

  const fetchOptions = async () => {
    const responses = await getResource('DocumentAction').getAvailableActions();

    const list = [];
    responses.map((response) => {
      list.push({ value: response, label: t(response[0]) });
      return null;
    });

    setActions(list);
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  if (!actions) {
    return null;
  }

  return (
    <Card>
      <CardBody className={submitting ? 'loading' : ''}>
        <Row>
          <Col lg="12">
            <div className="accordion" id="accordionActions">
              <AccordionItem
                name="actionValidations"
                actionList={actions}
                submitting={submitting}
                icon={<Icon name="check-circle" />}
              />
              <AccordionItem
                name="actionRevokes"
                actionList={actions}
                submitting={submitting}
                icon={<Icon name="times-circle" />}
              />
              <AccordionItem
                name="actionPauses"
                actionList={actions}
                submitting={submitting}
                icon={<Icon name="pause-circle" />}
              />
              <AccordionItem
                name="actionResumes"
                actionList={actions}
                submitting={submitting}
                icon={<Icon name="play-circle" />}
              />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
