import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Input, Autocomplete } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';

import Dashboard from './dashboard';

const Index = ({ match }) => {
  return (
    <View>
      <ResourceForm
        resource="DashboardLayout"
        id={match.params.id}
        initialValues={{ name: {}, items: [], report: null, type: null }}
      >
        {({ submitting, values, renderActions }) => (
          <>
            <Card>
              <CardBody className={submitting ? 'loading' : ''}>
                <Row>
                  <Col lg={6}>
                    <Input name="name" placeholder="" pattern="/^[a-zA-Z_0-9]+$" />
                  </Col>
                  <Col lg={6}>
                    <Autocomplete
                      name="type"
                      clearable
                      resource="DashboardLayoutType"
                      autoload
                      display="name"
                      tag="name"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">{values.type && <Dashboard type={values.type || 'graph'} />}</Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default Index;
