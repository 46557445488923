import PropTypes from 'prop-types';
import React from 'react';

import Tabs from '@/components/common/tabs';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';

import Actions from './actions';
import General from './general';
import Historic from './historics';

const TypeForm = ({ match }) => {
  const isEditMode = ({ resource }) => {
    if (!resource) {
      return true;
    }

    if (!resource.mappings) {
      return true;
    }

    return !resource.mappings.update;
  };

  return (
    <View>
      <ResourceForm resource="DocumentOfficial" id={match.params.id} initialValues={{ name: '', content: '' }}>
        {({ submitting, renderActions, values, ...args }) => (
          <>
            <Tabs baseUri={match.url} loading={submitting} showTabs={match.params.id !== undefined}>
              <General submitting={submitting} tab="generals" values={values} editMode={isEditMode(args)} />
              <Actions submitting={submitting} tab="actions" path="actions" data={values} />
              <Historic submitting={submitting} tab="historic" path="historic" data={values.historics} />
            </Tabs>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

TypeForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default TypeForm;
