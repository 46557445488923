/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { Col, FormGroup } from 'reactstrap';
import WeverDashboard from 'wever-dashboard';

import Context from '@/services/context';
import getResource from '@/services/resources';
import security from '@/services/security';
import { t } from '@/services/translator';

export default ({ dashboard, type, id }) => {
  const { locale } = useContext(Context);
  const [community, setCommunity] = useState();

  const loadMapCommunities = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const community = urlParams.get('community');
    if (!community) {
      return;
    }

    const element = await getResource('Community').getSheetActionDasboard(community, id, type);

    setCommunity(element);
  };

  useEffect(() => {
    loadMapCommunities();
  }, []);

  if (!dashboard || !dashboard.id) {
    return null;
  }

  try {
    return (
      <div>
        <FormGroup row>
          <Col>
            <WeverDashboard
              editionMode
              id={dashboard.id}
              dashboard={community?.id || dashboard.id}
              locale={locale}
              token={security.token}
              report={community && community.report ? community.report : '10631'}
            />
          </Col>
        </FormGroup>
      </div>
    );
  } catch (e) {
    return (
      <div className="w-100 text-center" style={{ color: '#ccc' }}>
        {t('adminapp_oopps_something_crashed')}
      </div>
    );
  }
};
