import React from 'react';
import { Col, Row, Table } from 'reactstrap';

import Date from '@/components/common/date';
import { Datepicker, Input } from '@/components/form';
import ResourceList from '@/components/resources/list';
import View from '@/components/view';

export default () => (
  <View>
    <ResourceList
      resource="ContractPartner"
      deleteMultiple
      filters={() => (
        <Row>
          <Col lg={3}>
            <Input name="name" />
          </Col>
          <Col lg={3}>
            <Datepicker name="['expireAt[after]']" />
          </Col>
          <Col lg={3}>
            <Datepicker name="['expireAt[before]']" />
          </Col>
        </Row>
      )}
      body={({ collection, renderColumn, renderItemActions, renderSelect }) => (
        <Table responsive hover>
          <thead className="thead-light">
            <tr>
              {renderColumn(renderSelect('*'), false)}
              {renderColumn('id')}
              {renderColumn('name')}
              {renderColumn('description')}
              {renderColumn('key')}
              {renderColumn('expireAt')}
              {renderColumn()}
            </tr>
          </thead>
          <tbody>
            {collection.map((item) => (
              <tr key={item.id}>
                <td>{renderSelect(item.id)}</td>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>{item.key}</td>
                <td>
                  <Date>{item.expireAt}</Date>
                </td>
                <td>{renderItemActions(item)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    />
  </View>
);
