/* eslint-disable no-else-return */
import * as yup from 'yup';

/**
 * Common extension validator
 *
 * @see @/services/mapping/validator
 */
export default {
  supports: '*',

  build({ constraints, ...props }, rule) {
    rule = rule || yup.mixed();

    for (let i = 0, len = constraints.length; i < len; ++i) {
      switch (constraints[i].alias) {
        case 'Blank':
          rule = rule.test(
            'blank',
            constraints[i].message,
            (value) => value === '' || value === null || value === undefined
          );
          break;
        case 'NotNull':
          rule = rule.required(constraints[i].message);
          break;
        case 'NotBlank':
          rule = rule.min(1);
          break;
        case 'EqualTo':
        case 'IdenticalTo':
          rule = rule.oneOf(
            [constraints[i].value],
            constraints[i].message.replace('{{ compared_value }}', constraints[i].value)
          );
          break;
        case 'NotIdenticalTo':
        case 'NotEqualTo':
          rule = rule.notOneOf(
            [constraints[i].value],
            constraints[i].message.replace('{{ compared_value }}', constraints[i].value)
          );
          break;
        case 'Range':
          rule = rule.test(
            'range',
            () => {
              let message = 'Invalid value';

              if (typeof constraints[i].min === 'number' && typeof constraints[i].max === 'number') {
                message = constraints[i].notInRangeMessage
                  .replace('{{ min }}', constraints[i].min)
                  .replace('{{ max }}', constraints[i].max);
              } else if (typeof constraints[i].min === 'number' && typeof constraints[i].max !== 'number') {
                message = constraints[i].minMessage.replace('{{ limit }}', constraints[i].min);
              } else if (typeof constraints[i].min !== 'number' && typeof constraints[i].max === 'number') {
                message = constraints[i].maxMessage.replace('{{ limit }}', constraints[i].max);
              }

              return message;
            },
            (value) => {
              value = Number(value);

              if (typeof constraints[i].min === 'number' && typeof constraints[i].max === 'number') {
                return value >= constraints[i].min && value <= constraints[i].max;
              } else if (typeof constraints[i].min === 'number' && typeof constraints[i].max !== 'number') {
                return value <= constraints[i].min;
              } else if (typeof constraints[i].min !== 'number' && typeof constraints[i].max === 'number') {
                return value >= constraints[i].min;
              }

              return false;
            }
          );
          break;
        case 'LessThan': {
          const lessThanMsg = constraints[i].message.replace('{{ limit }}', constraints[i].value);

          rule =
            rule.lessThan === 'function'
              ? rule.lessThan(constraints[i].value, lessThanMsg)
              : rule.max(constraints[i].value - 1, lessThanMsg);
          break;
        }
        case 'LessThanOrEqual':
          rule = rule.max(constraints[i].value, constraints[i].message.replace('{{ limit }}', constraints[i].value));
          break;
        case 'GreaterThan': {
          const greaterThanMsg = constraints[i].message.replace('{{ limit }}', constraints[i].value);

          // FIX DATE.
          let { value } = constraints[i];
          let min = value + 1;

          if (props.type === 'date') {
            value = value || new Date();
            min = value;
            min.setDate(min.getDate() + 1);
          }

          rule = rule.moreThan === 'function' ? rule.moreThan(value, greaterThanMsg) : rule.min(min, greaterThanMsg);
          break;
        }
        case 'GreaterThanOrEqual':
          rule = rule.min(constraints[i].value, constraints[i].message.replace('{{ limit }}', constraints[i].value));
          break;
        case 'Choice': {
          const { multiple, choices, min, max, message, multipleMessage, minMessage, maxMessage } = constraints[i];

          if (!Array.isArray(choices)) {
            break;
          }

          const allowedChoices = Object.keys(choices).map((p) => choices[p]);

          if (multiple) {
            if (typeof min === 'number') {
              let msg = minMessage;
              msg = msg.split('|');
              msg = max > 1 ? msg[0] : msg[1];
              rule = rule.min(min, msg.replace('{{ limit }}', min));
            }
            if (typeof max === 'number') {
              let msg = maxMessage;
              msg = msg.split('|');
              msg = max > 1 ? msg[1] : msg[0];
              rule = rule.max(max, msg.replace('{{ limit }}', max));
            }
          }

          rule = rule.test('choice-options', multiple ? multipleMessage : message, (value) => {
            if (!value) {
              return true;
            }

            if (!multiple) {
              return allowedChoices.includes(value);
            }

            for (let index = 0, valueLen = value.length; index < valueLen; ++index) {
              if (!allowedChoices.includes(value[index])) {
                return false;
              }
            }

            return true;
          });

          break;
        }
        default:
          break;
      }
    }

    return rule;
  }
};
