/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Table, Button, Spinner } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Resource } from '@/components/form';
import api from '@/services/api';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

// Api platform attend des IRIS. et pas des objets
const Communities = ({ values, setFieldValue }) => {
  const [isLoading, setLoading] = useState(false);
  const [communities, setCommunities] = useState();

  const handleDelete = (id, index) => {
    const newValCommunities = [...values.communities];

    newValCommunities.splice(index, 1);
    setFieldValue('communities', newValCommunities);
  };

  const handleAdd = async () => {
    setLoading(true);
    const id = values.community;

    let isExisting = false;
    communities.map((c) => {
      if (c.id === id) {
        isExisting = true;
      }

      return null;
    });

    if (isExisting) {
      return;
    }

    const community = await getResource('Community').read(id);

    const valCommunities = values.communities;
    valCommunities.push(community['@id']);

    setFieldValue('communities', valCommunities);
    setCommunities([...communities, community]);

    setLoading(false);
  };

  const getRoles = (managers) => {
    const id = values['@id'];
    let role = 'ROLE_USER';

    if (!managers) {
      return role;
    }

    managers.map((manager) => {
      if (manager === id) {
        role = 'ROLE_MANAGER';
      }

      return null;
    });

    return role;
  };

  const updateCommunities = async (vals) => {
    const comms = [];
    for (let i = 0; i < vals.length; i++) {
      const response = await api.get(vals[i]);
      comms.push(response.data);
    }

    setCommunities(comms);
  };

  useEffect(() => {
    if (!values || !values.communities) {
      return;
    }

    updateCommunities(values.communities);
  }, [values.communities]);

  return (
    <Row className="m-0">
      <Col lg={6} sm={12}>
        <Resource name="community" resource="Community" display="name" clearable useId />
      </Col>
      <Col lg={3} sm={12}>
        <Button onClick={handleAdd} disabled={isLoading} size="sm" color="default">
          {isLoading ? <Spinner color="primary" size="sm" /> : t('add')}
        </Button>
      </Col>
      <Col lg={12} sm={12}>
        <Table responsive striped>
          <thead>
            <tr>
              <th>{t('community.name')}</th>
              <th>{t('community.key')}</th>
              <th>{t('user.role')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {communities &&
              Object.keys(communities).map((key, index) => {
                const community = communities[key];
                if (!community) {
                  return null;
                }

                return (
                  <tr key={community.key}>
                    <td>
                      <Link to={`/communities/${community.id}`}>{community.name}</Link>
                    </td>
                    <td>{community.key}</td>
                    <td>{getRoles(community.managers)}</td>
                    <td>
                      <Button color="danger" size="sm" onClick={() => handleDelete(community.id, index)}>
                        <Icon name="trash" />
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

Communities.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    community: PropTypes.number,
    communities: PropTypes.array,
    permissions: PropTypes.oneOfType(PropTypes.array, PropTypes.shape({}))
  }).isRequired
};

export default Communities;
