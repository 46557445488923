/* eslint-disable no-sequences */
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Col, Card, CardBody, Button } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Input, Autocomplete, WysiwygEditor } from '@/components/form';
import api from '@/services/api';
import { Context } from '@/services/context';

const Item = ({ type, index, onDelete }) => {
  const [field, , helpers] = useField({ name: `items[${index}]` });
  const [colspan, , helpersColspan] = useField({ name: `items[${index}].colspan` });
  const { locale } = useContext(Context);
  const { graphType } = field.value;

  const getType = () => {
    switch (type) {
      case 'graph':
        return { name: `items[${index}].graphType`, tag: 'name', display: 'name', resource: 'GraphType' };
      case 'dashboard':
        return { name: `items[${index}].dashboard`, tag: 'name', display: 'name', resource: 'DashboardLayout' };
      case 'map':
        return { name: `items[${index}].map`, tag: 'name', display: 'name', resource: 'Map' };
      default:
        return null;
    }
  };

  const handleChangeType = async (value) => {
    const resultGet = await api.get(value);
    const graphTypeResult = resultGet.data;

    if (Array.isArray(graphTypeResult.parameters)) {
      // eslint-disable-next-line no-return-assign
      const result = graphTypeResult.parameters.reduce((acc, curr) => ((acc[curr] = ''), acc), {});
      const newValue = { ...field.value };
      newValue.parameters = result;
      newValue.graphType = value;

      helpers.setValue(newValue);
    }
  };

  if (!field.value) {
    return null;
  }

  return (
    <Col lg={field.value.colspan} className="mt-3">
      <Card className="bg-light">
        <Button
          size="sm"
          onClick={onDelete}
          className="bg-white rounded-circle border border-danger"
          style={{ position: 'absolute', top: '-10px', right: '-15px' }}
        >
          <Icon name="times" className="text-danger" />
        </Button>
        <CardBody>
          {getType() && <Autocomplete autoload {...getType()} onChange={handleChangeType} />}
          <Input name={`items[${index}].name`} />
          <Input
            value={colspan.value}
            type="number"
            min="1"
            max="12"
            onChange={(e) => helpersColspan.setValue(parseInt(e.target.value, 10))}
          />
          <Card>
            <CardBody>
              {type === 'text' && <WysiwygEditor name={`items[${index}].text`} />}

              {typeof field.value.parameters === 'object' &&
                Object.keys(field.value.parameters).map((key, indexParam) => {
                  return (
                    <div key={indexParam}>
                      {graphType && graphType.translation && graphType.translation[key]
                        ? graphType.translation[key][locale] || key
                        : key}{' '}
                      :
                      <Input name={`items[${index}].parameters[${key}]`} key={indexParam} label={null} />
                    </div>
                  );
                })}
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </Col>
  );
};

Item.propTypes = {
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.shape({}).isRequired
};

export default Item;
