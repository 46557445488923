/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Col, Row, Table, Button, Spinner } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Resource } from '@/components/form';
import Api from '@/services/api';
import { t } from '@/services/translator';

const ContractPartnersList = ({ values, setFieldValue }) => {
  // cannot use values.contractPartners, because it doesn't just have object, but @id too.
  // when adding an object to symfony we need to give it the url.
  const [partners, setPartners] = useState({});
  const [isLoading, setLoading] = useState(false);

  const deletePartner = (id) => {
    let deleteIndex = null;
    const { contractPartners, permissions } = values;

    contractPartners.map((partner, index) => {
      if (partner.id === id) {
        deleteIndex = parseInt(index, 10);
        return true;
      }

      return false;
    });

    if (deleteIndex !== null) {
      const newPartner = JSON.parse(JSON.stringify(partners));
      delete permissions[newPartner[`p_${id}`].key];
      delete newPartner[`p_${id}`];

      contractPartners.splice(deleteIndex, 1);

      setFieldValue('contractPartners', contractPartners);
      setFieldValue('permissions', permissions);
      setPartners(newPartner);
    }
  };

  const addPartner = async () => {
    const partnerId = values.select_contract_partner;

    // no ID or already existing
    if (!partnerId) return;

    let exist = false;
    partners.forEach((partner) => {
      if (partner.id === partnerId) {
        exist = true;
      }
    });

    if (exist) return;

    setLoading(true);

    const response = await Api.get(`/contract_partners/${partnerId}`);
    const partner = response.data;

    if (!partner) {
      setLoading(false);
      return;
    }

    setPartners([...partners, partner]);

    // Ajout des permissions du partenaires dans celle de l'utilisateur.
    let { contractPartners, permissions } = values;

    if (!contractPartners) {
      contractPartners = [];
    }

    if (Array.isArray(permissions)) {
      permissions = { default: [] };
    }

    if (!permissions[partner.key]) {
      permissions[partner.key] = partner.permissions;
      setFieldValue('permissions', permissions);
    }

    setFieldValue('contractPartners', [...contractPartners, partner['@id']]);

    setLoading(false);
  };

  const initializePartner = async (contractPartners) => {
    setLoading(true);
    const list = [];
    let response = null;
    for (let i = 0; i < contractPartners.length; i++) {
      response = await Api.get(contractPartners[i]);

      list.push(response.data);
    }

    setPartners(list);
    setLoading(false);
  };

  useEffect(() => {
    if (!values.contractPartners) {
      return;
    }

    initializePartner(values.contractPartners);
  }, [values.contractPartners]);

  return (
    <Row className="m-0 d-flex align-items-center">
      <Col lg={6} sm={12}>
        <Resource name="select_contract_partner" resource="ContractPartner" display="name" clearable useId />
      </Col>
      <Col lg={3} sm={12}>
        <Button onClick={addPartner} disabled={isLoading} size="sm" color="default">
          {isLoading ? <Spinner color="primary" size="sm" /> : t('add')}
        </Button>
      </Col>
      <Col lg={12} sm={12}>
        <Table responsive striped>
          <thead>
            <tr>
              <th>{t('contract_partners.name')}</th>
              <th>{t('contract_partners.key')}</th>
              <th>{t('contract_partners.expire_at')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {partners &&
              Object.keys(partners).map((key) => {
                if (!partners[key]) {
                  return null;
                }

                return (
                  <tr key={partners[key].key}>
                    <td>{partners[key].name}</td>
                    <td>{partners[key].key}</td>
                    <td>{partners[key].expireAt}</td>
                    <td>
                      <Button color="danger" size="sm" onClick={() => deletePartner(partners[key].id)}>
                        <Icon name="trash" />
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

ContractPartnersList.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    select_contract_partner: PropTypes.number,
    contractPartners: PropTypes.array,
    permissions: PropTypes.oneOfType(PropTypes.array, PropTypes.shape({}))
  }).isRequired
};

export default ContractPartnersList;
