/* eslint-disable no-else-return */
export default {
  /**
   * Get value of css property applied on specific element
   *
   * @param {DOMElement} el
   * @param {string}     prop
   *
   * @return {*}
   */
  getStyle(el, prop) {
    if (!el) return null;

    if (el.currentStyle) {
      // IE & Opera
      return el.currentStyle[prop];
    } else if (document.defaultView && document.defaultView.getComputedStyle) {
      // Gecko & WebKit
      return document.defaultView.getComputedStyle(el, '')[prop];
    }
    // try and get inline style
    return el.style[prop];
  }
};
