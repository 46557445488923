/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import getResource from '@/services/resources';

export const ResourceViewContext = React.createContext();

ResourceViewContext.displayName = 'ResourceViewContext';

const ResourceView = (props) => {
  const { children, resource, id } = props;

  const [resourceElement, setResourceElement] = useState();

  const loadResource = async () => {
    const response = await getResource(resource).read(id);
    setResourceElement(response);
  };

  useEffect(() => {
    loadResource();
  }, []);

  return <ResourceViewContext.Provider value={resourceElement}>{children}</ResourceViewContext.Provider>;
};

export default ResourceView;
