/* eslint-disable global-require */

export default [
  {
    path: 'partner_types',
    name: 'partner_types',
    resource: 'PartnerType',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/partner-type/list').default,
        title: 'partner_types_list_title',
        permissions: {
          PartnerType: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/partner-type/form').default,
        title: 'partner_types_create_title',
        permissions: {
          PartnerType: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/partner-type/form').default,
        title: 'partner_types_update_title',
        permissions: {
          PartnerType: ['read', 'update']
        }
      }
    ]
  }
];
