/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
const ObjectUtils = {
  isOwningProperty(object, property) {
    return Object.prototype.hasOwnProperty.call(object, property);
  },
  /**
   * Get property value by using an object path
   *
   * @param {string} propertyName
   * @param {object} object
   * @param {*}      byDefault
   *
   * @return {*}
   */
  get(propertyName, object, byDefault) {
    const formattedPropertyName = String(propertyName).replace(/\[/g, '.').replace(/]/g, '');
    const parts = String(formattedPropertyName).split('.');
    const { length } = parts;
    let i;
    let property = object || this;

    for (i = 0; i < length; i++) {
      if (!property || property[parts[i]] === undefined) {
        return byDefault;
      }

      property = property[parts[i]];
    }

    return property;
  },

  /**
   * Clone an object
   *
   * @param {object}  from
   * @param {?object} to
   *
   * @return {*}
   */
  clone(from, to) {
    if (from === null || typeof from !== 'object' || (from.constructor !== Object && from.constructor !== Array)) {
      return from;
    }

    if ([Date, RegExp, Function, String, Number, Boolean].includes(from.constructor)) {
      return new from.constructor(from);
    }

    // Object & Array
    const newCloneTo = to || new from.constructor();
    for (const name in from) {
      if (!ObjectUtils.isOwningProperty(from, name)) continue;

      if (from[name] !== undefined) {
        newCloneTo[name] = this.clone(from[name]);
      }
    }

    return newCloneTo;
  },

  /**
   * Define a "find" property to use this.get in the target object
   *
   * @param {object} object
   *
   * @return {*}
   */
  makeFindable(object) {
    if (typeof object === 'object' && typeof object.find !== 'function') {
      Object.defineProperty(object, 'find', {
        value: (path, byDefault) => this.get(path, object, byDefault),
        writable: false
      });
    }

    return object;
  },

  /**
   * Merge 2 object recursively
   *
   * @param {object} obj1
   * @param {object} obj2
   *
   * @return {*}
   */
  merge(obj1, obj2) {
    // eslint-disable-next-line no-restricted-syntax
    for (const p in obj2) {
      if (!ObjectUtils.isOwningProperty(obj2, p)) continue;

      try {
        obj1[p] = obj2[p].constructor === Object ? this.merge(obj1[p], obj2[p]) : obj2[p];
      } catch (e) {
        obj1[p] = obj2[p];
      }
    }

    return obj1;
  },

  /**
   * Unset properties with a specific value recursively
   *
   * @param {object} obj
   * @param {array} valuesOfPropsToUnset
   *
   * @return {*}
   */
  unsetProperties(obj, valuesOfPropsToUnset = []) {
    for (const prop in obj) {
      if (!ObjectUtils.isOwningProperty(obj, prop)) {
        continue;
      }

      if (obj[prop] !== null && typeof obj[prop] === 'object') {
        obj[prop] = this.unsetProperties(obj[prop], valuesOfPropsToUnset);
        continue;
      }

      if (valuesOfPropsToUnset.includes(obj[prop])) {
        delete obj[prop];
      }
    }

    return obj;
  }
};

export default ObjectUtils;
