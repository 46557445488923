import { useField } from 'formik';
import React from 'react';
import MonacoEditor from 'react-monaco-editor';
import { FormGroup } from 'reactstrap';

import { Error, Label } from '@/components/form';

export default ({
  name,
  label,
  type,
  size = 'sm',
  groupTag = FormGroup,
  labelTag = Label,
  errorTag = Error,
  inputTag = MonacoEditor,
  groupProps = {},
  labelProps = {},
  inputProps = {},
  errorProps = {},
  height = '700',
  value = null,
  ...props
}) => {
  const [field, ...helpers] = useField({ name });

  const GroupTag = groupTag;
  const LabelTag = labelTag;
  const ErrorTag = errorTag;
  const InputTag = inputTag;

  label = labelProps.text || label;
  if (label === undefined) {
    label = name;
  }

  return (
    <GroupTag {...groupProps}>
      {/* Label */}
      {label && (
        <LabelTag name={name} size={size} {...labelProps}>
          {label}
        </LabelTag>
      )}

      {/* Input */}
      <InputTag
        name={name}
        // width="100%"
        height={height}
        theme="vs-dark"
        language="json"
        value={field.value || value || ''}
        onChange={(value) => {
          helpers[1].setValue(value);
        }}
        options={{
          tabSize: 2,
          automaticLayout: true
        }}
        {...props}
        {...inputProps}
      />

      {/* Errors */}
      <ErrorTag name={name} {...errorProps} />
    </GroupTag>
  );
};
