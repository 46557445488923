/* eslint-disable react-hooks/exhaustive-deps */
import { useField } from 'formik';
import React, { useState, useEffect } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { WysiwygEditor } from '@/components/form';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

export default ({ index }) => {
  const [field, ,] = useField({ name: `contents[${index}]` });
  const [language, setLanguage] = useState();

  const loadLanguage = async () => {
    let apiLanguage = field.value.language;

    let id = null;

    if (typeof field.value.language === 'string') {
      id = field.value.language.replace('/languages/', '');
    } else {
      id = field.value.language.id;
    }

    apiLanguage = await getResource('Language').read(id);

    setLanguage(apiLanguage);
  };

  useEffect(() => {
    loadLanguage();
  }, []);

  if (!language) {
    return null;
  }

  return (
    <Card className="mt-2 p-1">
      <CardBody>
        <Row>
          <Col className="fw-bold">
            {t('adminapp_cookie_for_language')} : {t(language.name)}
          </Col>
          <Col className="col-12">
            <WysiwygEditor name={`contents[${index}].content`} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
