import React from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';

import AuthFooter from '@/components/layout/footers/auth-footer.js';

export default class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add('bg-default');
  }

  componentWillUnmount() {
    document.body.classList.remove('bg-default');
  }

  render() {
    const { routes, loading } = this.props;

    return (
      <>
        <div className="main-content">
          <div className="header bg-gradient-info py-7 py-lg-8">
            <Container>
              <div className="header-body text-center mb-4">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <img className="mb-3" alt="wever" src={require('@/assets/img/brand/logo-w.png')} height={100} />
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon className="fill-default" points="2560 0 2560 100 0 100" />
              </svg>
            </div>
          </div>
          {typeof routes === 'function' && routes()}
          {loading && (
            <Container className="mt--8 pb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="7">
                  <p className="text-center mt-6">
                    <Spinner style={{ width: '4rem', height: '4rem' }} color="secondary" />
                  </p>
                </Col>
              </Row>
            </Container>
          )}
        </div>
        {!loading && <AuthFooter />}
      </>
    );
  }
}
