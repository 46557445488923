import React from 'react';
import { Col, Row, Table } from 'reactstrap';

import { Input } from '@/components/form';
import ResourceList from '@/components/resources/list';
import View from '@/components/view';
import { t } from '@/services/translator/index';

export default () => {
  return (
    <View>
      <ResourceList
        resource="Server"
        deleteMultiple
        filters={() => (
          <Row>
            <Col lg={3}>
              <Input name="key" />
            </Col>
          </Row>
        )}
        body={({ collection, renderColumn, renderItemActions, renderSelect }) => (
          <Table responsive hover>
            <thead className="thead-light">
              <tr>
                {renderColumn(renderSelect('*'), false)}
                {renderColumn('id')}
                {renderColumn('key')}
                {renderColumn('name')}
                {renderColumn()}
              </tr>
            </thead>
            <tbody>
              {collection.map((item) => (
                <tr key={item.id}>
                  <td>{renderSelect(item.id)}</td>
                  <td>{item.id}</td>
                  <td>{item.key}</td>
                  <td>{item.name ? t(item.name) : ''}</td>
                  <td>{renderItemActions(item)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      />
    </View>
  );
};
