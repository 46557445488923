import PropTypes from 'prop-types';
import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';

import { Input } from '@/components/form';
import { t } from '@/services/translator';

const SheetActionDuration = ({ index }) => (
  <div key={`duration-${index}`}>
    <FormGroup row>
      <Label sm={5}>{t('adminapp_sheet_action_duration')}</Label>
      <Col sm={6}>
        <Input name={`durations[${index}].value`} label={null} required />
      </Col>
    </FormGroup>
  </div>
);

SheetActionDuration.propTypes = {
  index: PropTypes.number
};

export default SheetActionDuration;
