import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import Icon from '@/components/common/icon';

export default ({ perPage, total, active, onClick, loading }) => {
  const pages = Math.ceil(total / perPage);

  return (
    pages > 0 && (
      <Pagination listClassName="pagination-sm">
        {pages > 1 && active !== 1 && (
          <PaginationItem>
            <PaginationLink disabled={loading} onClick={() => onClick && onClick(active - 1)}>
              <Icon name="chevron-left" />
            </PaginationLink>
          </PaginationItem>
        )}
        {Array(pages)
          .fill(null)
          .map((o, i) => {
            if (pages > 8 && i !== 0 && i !== pages - 1) {
              if (active - 5 === i || active + 3 === i) {
                return (
                  <PaginationItem key={i} disabled>
                    <PaginationLink className="bg-transparent border-0">
                      <Icon name="ellipsis-h" />
                    </PaginationLink>
                  </PaginationItem>
                );
              }

              if (active - 4 > i || active + 2 < i) {
                return null;
              }
            }

            return (
              <PaginationItem active={active === i + 1} disabled={loading} key={i}>
                <PaginationLink onClick={() => onClick && active !== i + 1 && onClick(i + 1)}>{i + 1}</PaginationLink>
              </PaginationItem>
            );
          })}
        {pages > 1 && active !== pages && (
          <PaginationItem>
            <PaginationLink disabled={loading} onClick={() => onClick && onClick(active + 1)}>
              <Icon name="chevron-right" />
            </PaginationLink>
          </PaginationItem>
        )}
      </Pagination>
    )
  );
};
