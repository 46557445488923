import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Input, Resource, TableLangValue, Autocomplete } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';
import { t } from '@/services/translator';

import PrioritizedResponses from './form/prioritized-responses';

const ExpectedResponsePatternForm = ({ match }) => {
  return (
    <View>
      <ResourceForm
        resource="QuestionPattern"
        id={match.params.id}
        initialValues={{ alias: '', values: null, name: {}, why: null, order: '' }}
      >
        {({ submitting, renderActions }) => (
          <>
            <Card>
              <CardBody className={submitting ? 'loading' : ''}>
                <Row>
                  <Col lg={4}>
                    <Input name="alias" placeholder="ex: alias_one_a" pattern="/^[a-zA-Z_0-9]+$" />
                  </Col>
                  <Col lg={4}>
                    <Resource name="type" clearable resource="Type" display="name" />
                  </Col>
                  <Col lg={4} md={12}>
                    <Autocomplete autoload name="tags" clearable multiple resource="Tag" display="name" tag="name" />
                  </Col>
                  <Col lg={12} md={12}>
                    <Autocomplete
                      name="expectedResponses"
                      clearable
                      multiple
                      resource="ExpectedResponsePattern"
                      display="alias"
                      tag="alias"
                    />
                  </Col>
                  <Col lg={12} md={12}>
                    <PrioritizedResponses />
                  </Col>
                  <Col lg={12}>
                    <p>{t('question_pattern.name')}</p>
                    <TableLangValue name="name" />
                  </Col>
                  <Col lg={12}>
                    <p>{t('question_pattern.description')}</p>
                    <TableLangValue name="description" />
                  </Col>
                  <Col lg={12}>
                    <p>{t('question_pattern.why')}</p>
                    <TableLangValue name="why" inputType="textarea" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

ExpectedResponsePatternForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default ExpectedResponsePatternForm;
