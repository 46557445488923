import classnames from 'classnames';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { FormGroup, ButtonGroup, Button } from 'reactstrap';

import { Error, Label } from '@/components/form';

export default ({
  name,
  label,
  value,
  buttonClass,
  size = 'sm',
  options = [],
  groupTag = FormGroup,
  labelTag = Label,
  errorTag = Error,
  inputTag = 'input',
  buttonTag = Button,
  buttonProps = {},
  groupProps = {},
  labelProps = {},
  inputProps = {},
  errorProps = {}
}) => {
  const { mapping, setFieldValue } = useFormikContext();
  const fieldContext = useField({ name });

  const GroupTag = groupTag;
  const LabelTag = labelTag;
  const ErrorTag = errorTag;
  const InputTag = inputTag;
  const ButtonTag = buttonTag;

  label = labelProps.text || label;
  if (label === undefined) {
    label = name;
  }

  if (!Array.isArray(options)) {
    options = mapping.getChoices(name);
  }

  return (
    <GroupTag {...groupProps}>
      {label && (
        <LabelTag name={name} size={size} {...labelProps}>
          {label}
        </LabelTag>
      )}

      <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
        {options.map((item, i) => {
          const active = String(item.value) === String(value === undefined ? fieldContext[0].value : value);
          const className = typeof buttonClass === 'function' ? buttonClass(item, active) : buttonClass;

          return (
            <ButtonTag
              tag="label"
              key={i}
              className={classnames({
                active,
                'shadow-none': !active,
                ...className
              })}
              color="secondary"
              size={size}
              {...buttonProps}
            >
              <InputTag
                type="radio"
                onChange={() => {
                  setFieldValue(name, item.value);
                }}
                value={String(item.value)}
                checked={active}
                {...inputProps}
              />
              {item.label}
            </ButtonTag>
          );
        })}
      </ButtonGroup>

      <ErrorTag name={name} {...errorProps} />
    </GroupTag>
  );
};
