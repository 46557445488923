import React from 'react';

import environment from '@/services/environment';
import storage from '@/services/storage';
import object from '@/services/utils/object';

/**
 * Current context updated by component which declare the context Provider
 *
 * @type {object}
 */
export const context = {
  locale: navigator.language || environment.get('default_locale'),
  editionMode: false,
  community: storage.get('community'),
  update(state) {
    for (const prop in state) {
      if (!state.hasOwnProperty(prop)) {
        continue;
      }

      this[prop] = state[prop];
    }
  }
};

/**
 * Initialize app context used to store data used by multiple component
 * whenever they are positioned in components cluster.
 */
export const Context = React.createContext(context);

/**
 * Display name used for dev tools
 *
 * @see https://fr.reactjs.org/docs/context.html#contextdisplayname
 */
Context.displayName = 'AppContext';

/**
 * Display value of context.
 * You can access nested property by using dots between objects properties.
 *
 * @example: <Context.Display path="theme.color" />
 */
Context.Display = ({ path }) => <Context.Consumer>{(values) => object.get(path, values)}</Context.Consumer>;

/**
 * Provider is wrapper of Context.Provider used to update context object
 * for usage of context outside components
 */
export class Provider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...context,
      ...props.value,
      update: this.updateContext.bind(this)
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (JSON.stringify(value) !== JSON.stringify(prevProps.value)) {
      this.updateContext(value);
    }
  }

  updateContext(value) {
    this.setState(value, () => {
      for (let prop in this.state) {
        if (this.state.hasOwnProperty(prop)) {
          if (prop === 'community') {
            storage.set(prop, this.state[prop]);
          }

          context[prop] = this.state[prop];
        }
      }
    });
  }

  render() {
    return <Context.Provider value={this.state}>{this.props.children}</Context.Provider>;
  }
}

export default Context;
