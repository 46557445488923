import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Card, CardBody } from 'reactstrap';
import WeverCommunities from 'wever-communities';

import View from '@/components/view';
import { Context } from '@/services/context';
import security from '@/services/security';

const CommunityForm = ({ match }) => {
  const { locale } = useContext(Context);

  return (
    <View>
      <Card style={{ minHeight: '70vh' }}>
        <CardBody className="p-0 pb-1">
          <div>
            <WeverCommunities token={security.token} locale={locale} communityId={match.params.id} editionMode />
          </div>
        </CardBody>
      </Card>
    </View>
  );
};

CommunityForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default CommunityForm;
