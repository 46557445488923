import { useField } from 'formik';
import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';

import { Error } from '@/components/form';
import { Context } from '@/services/context';
import { t } from '@/services/translator';

import Card from './geometries/card';
import ModalContent from './geometries/modal-content';

const Geometries = () => {
  const [geometries, ...helpers] = useField({ name: 'geometries' });
  const { openModal, closeModal } = useContext(Context);
  let { value } = geometries || { value: {} };

  const updateGeometry = (type, name, responses) => {
    if (!name) {
      return;
    }

    let geometry = 'points';
    switch (type) {
      case '/paths/query':
        geometry = 'lines';
        break;
      case '/zones/query':
        geometry = 'polygons';
        break;
      default:
    }

    if (!value || Array.isArray(value)) {
      value = {};
    }

    if (!value[geometry]) {
      value[geometry] = [];
    }

    value[geometry].push({ name, value: responses });

    helpers[1].setValue(value);

    closeModal();
  };

  const addElement = (api, label) => {
    openModal(label, <ModalContent api={api} onUpdate={updateGeometry} utils={{ closeModal }} />, null, 'lg');
  };

  return (
    <Row>
      <Col lg={12}>
        <Error name="geometries" />
        <Card
          list={value.points || []}
          addClick={() => addElement('/locations/query', t('add_point'))}
          label={t('add_point')}
        />
        <Card
          list={value.lines || []}
          addClick={() => addElement('/paths/query', t('add_line'))}
          label={t('add_line')}
        />
        <Card
          list={value.polygons || []}
          addClick={() => addElement('/zones/query', t('add_polygon'))}
          label={t('add_polygon')}
        />
      </Col>
    </Row>
  );
};

Geometries.propTypes = {
  // values: PropTypes.shape({ points: PropTypes.array, lines: PropTypes.array, polygons: PropTypes.array }).isRequired,
  // getFieldHelpers: PropTypes.func.isRequired
};

export default Geometries;
