import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import View from '@/components/view';
import { t } from '@/services/translator';

/**
 * Common error page
 *
 * @param {number|string} code
 * @param {string}        title
 * @param {string}        description
 *
 * @return {JSX.Element}
 */
export const Error = ({ code, title, description }) => (
  <View>
    <div id="error-page">
      <Container fluid className="text-center mt--7">
        <div className="text-center">
          <h1 className="text-white">{code}</h1>
        </div>
        <div className="content">
          <h2>{t(title)}</h2>
          <p>{t(description)}</p>
          <Link to="/">{t('back_to_homepage')}</Link>
        </div>
      </Container>
    </div>
  </View>
);

Error.propTypes = {
  code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

/**
 * Page not found
 */
export const NotFound = () => (
  <Error code="404" title="content_not_found" description="content_not_found_description" />
);

/**
 * Page denied
 */
export const AccessDenied = () => <Error code="403" title="access_denied" description="access_denied_description" />;
