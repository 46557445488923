import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Col, Row } from 'reactstrap';

import { Input, Autocomplete } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';
import notifications from '@/services/notifications';
import { t } from '@/services/translator';

import CardForm from './card-form';
import Cost from './cost';
import Duration from './duration';
import EvaluationCriteria from './evaluation-criteria';
import Media from './media';
import Objective from './objective';

const Form = ({ match }) => {
  const handleCustomValidation = (values, formik) => {
    let isValid = true;
    values.medias.forEach((media, index) => {
      if (media.mimeType === 'text/plain' && !media.webUrl) {
        notifications.error(
          t('managerapp_form_error'),
          <span>
            {t('managerapp_form_error_webUrl_missing')} : {index + 1}
          </span>
        );
        console.log('hey ho');
        isValid = false;
        return;
      }
    });

    return isValid;
  };

  return (
    <View>
      <ResourceForm
        resource="SheetAction"
        id={match.params.id}
        initialValues={{
          enabled: true,
          durations: [],
          objectives: [],
          costs: [],
          medias: []
        }}
        customValidation={handleCustomValidation}
      >
        {({ submitting, mapping, setSubmitting, fetch, renderActions, ...props }) => (
          <>
            <Card>
              <CardBody>
                <Row>
                  <Col sm={1} lg={12}>
                    <Input name="name" />
                    <Input name="presentation" />
                    <Input name="description" />
                    {mapping.has('categories') && (
                      <Autocomplete
                        name="categories"
                        tag="name"
                        clearable
                        multiple
                        display="name"
                        resource="SheetActionCategory"
                        autoload
                      />
                    )}
                    {mapping.has('dashboard') && (
                      <Autocomplete
                        tag="name"
                        name="dashboard"
                        resource="DashboardLayout"
                        autoload
                        display="name"
                        clearable
                      />
                    )}
                    <Autocomplete tag="name" name="community" resource="Community" autoload display="name" clearable />
                    {/* DASHBOARD */}
                    <Autocomplete
                      tag="name"
                      name="feedbackDashboard"
                      resource="DashboardLayout"
                      autoload
                      display="name"
                      clearable
                    />
                    <Autocomplete
                      tag="name"
                      name="dimensionDashboard"
                      resource="DashboardLayout"
                      autoload
                      display="name"
                      clearable
                    />
                    <Autocomplete
                      tag="name"
                      name="evaluationDashboard"
                      resource="DashboardLayout"
                      autoload
                      display="name"
                      clearable
                    />
                    <CardForm
                      name="durations"
                      initialValues={{ value: '' }}
                      formComponent={Duration}
                      hide={!mapping.has('durations')}
                    />
                    <CardForm
                      name="objectives"
                      initialValues={{ name: '' }}
                      formComponent={Objective}
                      hide={!mapping.has('objectives')}
                    />
                    <CardForm
                      name="costs"
                      initialValues={[{ name: '', price: '' }]}
                      formComponent={Cost}
                      hide={!mapping.has('costs')}
                    />
                    <CardForm
                      name="medias"
                      initialValues={{
                        originalName: '',
                        mimeType: '',
                        webUrl: '',
                        description: '',
                        file: null
                      }}
                      formComponent={Media}
                      hide={!mapping.has('medias')}
                    />
                    <CardForm
                      name="evaluationCriteria"
                      initialValues={{ value: '' }}
                      formComponent={EvaluationCriteria}
                      hide={!mapping.has('evaluationCriteria')}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
    url: PropTypes.string
  }).isRequired
};

export default Form;
