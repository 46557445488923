import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Input } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';

const Form = ({ match }) => (
  <View>
    <ResourceForm resource="LocationType" id={match.params.id}>
      {({ submitting, renderActions }) => (
        <>
          <Card>
            <CardBody className={submitting ? 'loading' : ''}>
              <Row>
                <Col lg={12}>
                  <Input name="name" />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="mt-4 mb-4">{renderActions()}</div>
        </>
      )}
    </ResourceForm>
  </View>
);

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default Form;
