import * as yup from 'yup';

/**
 * Array extension validator
 *
 * @see @/services/mapping/validator
 */
export default {
  supports: ['array', 'collection', 'hash', 'many'],

  build({ constraints }, rule) {
    rule = yup.array().ensure();

    for (let i = 0, len = constraints.length; i < len; ++i) {
      switch (constraints[i].alias) {
        case 'Count':
          if (typeof constraints[i].min === 'number' && constraints[i].min === constraints[i].max) {
            rule = rule.test(
              'count-exact',
              constraints[i].exactMessage.replace('{{ limit }}', constraints[i].min),
              (value) => value.length === constraints[i].min
            );
          }

          if (typeof constraints[i].min === 'number') {
            rule = rule.min(constraints[i].min, constraints[i].minMessage.replace('{{ limit }}', constraints[i].min));
          }

          if (typeof constraints[i].max === 'number') {
            rule = rule.max(constraints[i].max, constraints[i].maxMessage.replace('{{ limit }}', constraints[i].max));
          }
          break;
        default:
          break;
      }
    }

    return rule;
  }
};
