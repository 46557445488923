import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import Dashboard from 'wever-dashboard';

import { t } from '@/services/translator';

export default (props) => {
  return (
    <Row>
      <Col className="col-4 pl-2 pt-2 d-flex justify-content-start align-items-center">
        <Button className="ml-2 bg-darkpink rounded-pill" onClick={props.onClose}>
          {t('back')}
        </Button>
      </Col>
      <Col className="col-8 d-flex justify-content-center align-items-center">{props.name}</Col>
      <Col className="col-12">
        <Dashboard editionMode {...props} report={parseInt(props.report.replace('undefined', ''))} />
      </Col>
    </Row>
  );
};
