import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';

import getResource from '@/services/resources';

/**
 * Permet d'uploader des fichiers de façon dissimuler à travers un lien ou text (dépendant du composant passé en paramètre)
 *
 * @param [string] resource C'est l'entité au pluriel, ex: communities
 * @param [string] name C'est la propriété sur laquelle on upload
 * @param [object] component C'est le composant visuel pour déclancher l'input file.
 */
const Upload = ({ component, multiple, resource, name, onChange, ...props }) => {
  // State
  const [file, setFile] = useState('');
  const inputRef = useRef();
  // Props;
  const Component = component;

  const handleFileChange = async (e) => {
    setFile(e.target.value);

    const media = await getResource('Media').createResource(resource, name, e.currentTarget.files, multiple);

    let realMedia = null;
    if (multiple) {
      realMedia = [];
      media.forEach((item) => {
        realMedia.push(item['@id']);
      });
    } else {
      realMedia = media['@id'];
    }

    onChange(realMedia, media);
  };

  const handleActiveInput = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  return (
    <div className="coreUpload">
      {Component && <Component onClick={(...e) => handleActiveInput(...e)} />}
      <input
        type="file"
        ref={inputRef}
        value={file}
        onChange={handleFileChange}
        hidden
        style={{ visibility: 'hidden' }}
        {...props}
      />
    </div>
  );
};

Upload.propTypes = {
  component: PropTypes.elementType,
  multiple: PropTypes.bool,
  resource: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

Upload.defaultProps = {
  component: null,
  multiple: false
};

export default Upload;
