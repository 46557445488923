import PropTypes from 'prop-types';
import React from 'react';

import Tabs from '@/components/common/tabs';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';

import ContractPartnerInformation from './information';
import Permissions from './permissions';

const Index = ({ match }) => (
  <View>
    <ResourceForm
      resource="ContractPartner"
      id={match.params.id}
      initialValues={{ name: '', description: '', key: '', expireAt: null, permissions: { empty: [] } }}
    >
      {({ submitting, renderActions, ...props }) => (
        <>
          <Tabs baseUri={match.url} loading={submitting}>
            <ContractPartnerInformation tab="information" {...props} />
            <Permissions tab="permissions" path="permissions" {...props} />
          </Tabs>
          <div className="mt-4 mb-4">{renderActions()}</div>
        </>
      )}
    </ResourceForm>
  </View>
);

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
    url: PropTypes.string
  }).isRequired
};

export default Index;
