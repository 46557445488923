import { useField, useFormikContext } from 'formik';
import React from 'react';
import { FormGroup } from 'reactstrap';

import { Error, Label, Radio } from '@/components/form';

export default ({
  name,
  label,
  inline,
  value,
  size = 'sm',
  options = [],
  optionsValuesType = 'string',
  emptyMeansNull = true,
  nullable = true,
  groupTag = FormGroup,
  labelTag = Label,
  errorTag = Error,
  inputTag = Radio,
  groupProps = {},
  labelProps = {},
  inputProps = {},
  errorProps = {}
}) => {
  const { mapping } = useFormikContext();
  const fieldContext = useField({ name });
  const radiosContainerClassName = getContainerClassName(inline);

  const GroupTag = groupTag;
  const LabelTag = labelTag;
  const ErrorTag = errorTag;
  const InputTag = inputTag;

  label = labelProps.text || label;
  if (label === undefined) {
    label = name;
  }

  if (!Array.isArray(options)) {
    options = mapping.getChoices(name);
  }

  return (
    <GroupTag {...groupProps}>
      {label && (
        <LabelTag name={name} size={size} {...labelProps}>
          {label}
        </LabelTag>
      )}

      <div className={radiosContainerClassName}>
        {options.map((item, i) => (
          <div key={name + i} className={inline ? 'mr-4' : ''}>
            <InputTag
              id={name + i}
              name={name + i}
              label={item.label}
              value={item.value}
              groupTag={inline ? React.Fragment : undefined}
              displayErrors={false}
              checked={String(item.value) === String(value === undefined ? fieldContext[0].value : value)}
              onChange={({ currentTarget }) => {
                fieldContext[2].setValue(castValue(currentTarget.value, optionsValuesType, emptyMeansNull, nullable));
              }}
              {...inputProps}
            />
          </div>
        ))}
      </div>

      <ErrorTag name={name} {...errorProps} />
    </GroupTag>
  );
};

const getContainerClassName = (inline) => {
  let className = '';

  if (inline) {
    className += 'flex align-items-center';
    switch (inline) {
      case 'center':
        className += ' justify-content-center';
        break;
      case 'end':
        className += ' justify-content-end';
        break;
      case 'start':
        className += ' justify-content-start';
        break;
      default:
        break;
    }
  }

  return className;
};

const castValue = (value, type, emptyMeansNull, nullable) => {
  if ((emptyMeansNull && value === '') || (nullable && value === 'null')) {
    return null;
  }

  switch (type) {
    case 'string':
      return String(value);
    case 'number':
      return Number(value);
    case 'bool':
    case 'boolean':
      if (value === 'true') {
        value = 1;
      }

      if (value === 'false') {
        value = 0;
      }

      return Boolean(value);
    default:
      return value;
  }
};
