import { useFormikContext } from 'formik';
import React from 'react';
import { Label } from 'reactstrap';

import { t } from '@/services/translator';

export default ({ name, children, text, size, ...props }) => {
  let display = text || children;
  const context = useFormikContext();
  const isRequired =
    props.required !== undefined
      ? props.required
      : name !== undefined && context.mapping !== undefined && context.mapping.isRequired(name);
  if (name === display && context.resource) {
    display = context.resource.getTranslationKey(name.replace(/[[']['\]]/g, '').replace(/\[.*]/g, ''));
  }

  return (
    <Label size={size} {...props}>
      {typeof display === 'string' ? t(display) : display}
      {/* Is required asterisk */}
      {isRequired && ' *'}
    </Label>
  );
};
