import api from '@/services/api';
import Resource from '@/services/resources/common';

export default class ResponseResource extends Resource {
  constructor(entrypoint = '/responses', alias = 'Response') {
    super(entrypoint, alias);
  }

  query(requestData) {
    return api.post(this.buildUri('query'), requestData).then(({ data }) => data);
  }
}
