import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Input, TableLangValue, Datepicker, Autocomplete } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';
import { t } from '@/services/translator';

const DiagnosticForm = ({ match }) => {
  return (
    <View>
      <ResourceForm
        resource="Diagnostic"
        id={match.params.id}
        initialValues={{ name: {}, alias: '', startDate: null, endDate: null }}
      >
        {({ submitting, values, renderActions }) => (
          <>
            <Card>
              <CardBody className={submitting ? 'loading' : ''}>
                <Row>
                  {values.monoBloc ? (
                    <Col lg={12}>
                      <p className="text-primary">{t('is_monobloc')}</p>
                    </Col>
                  ) : null}
                  <Col lg={6}>
                    <Input name="alias" placeholder="ex: my_alias" pattern="/^[a-zA-Z_0-9]+$" />
                  </Col>

                  <Col lg={6}>
                    <Autocomplete name="community" clearable resource="Community" display="name" tag="name" autoload />
                  </Col>

                  <Col lg={6}>
                    <Datepicker name="startDate" />
                  </Col>
                  <Col lg={6}>
                    <Datepicker name="endDate" />
                  </Col>
                  <Col lg={6}>
                    <Autocomplete
                      name="type"
                      clearable
                      resource="DiagnosticType"
                      display="alias"
                      tag="alias"
                      autoload
                    />
                  </Col>

                  <Col lg={12}>
                    <Input name="description" type="textarea" />
                  </Col>

                  <Col lg={12}>
                    <TableLangValue name="name" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

DiagnosticForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default DiagnosticForm;
