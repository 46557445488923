/* eslint-disable global-require */

export default [
  {
    path: 'document_officials',
    name: 'document_officials',
    resource: 'DocumentOfficial',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/document-official/list').default,
        title: 'document_title',
        roles: ['ROLE_ADMIN'],
        permissions: {
          DocumentOfficial: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/document-official/form').default,
        title: 'document_types_create_title',
        roles: ['ROLE_ADMIN'],
        permissions: {
          DocumentOfficial: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/document-official/form').default,
        title: 'document_update_title',
        roles: ['ROLE_ADMIN'],
        permissions: {
          DocumentOfficial: ['read', 'update']
        }
      }
    ]
  },
  {
    path: 'document_types',
    name: 'document_types',
    resource: 'DocumentType',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        roles: ['ROLE_ADMIN'],
        context: 'read:list',
        exact: true,
        component: require('@/views/document-type/list').default,
        title: 'document_types_title',
        permissions: {
          DocumentType: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        roles: ['ROLE_ADMIN'],
        component: require('@/views/document-type/form').default,
        title: 'document_types_create_title',
        permissions: {
          DocumentType: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        roles: ['ROLE_ADMIN'],
        component: require('@/views/document-type/form').default,
        title: 'document_types_update_title',
        permissions: {
          DocumentType: ['read', 'update']
        }
      }
    ]
  },
  {
    path: 'optin_cookie',
    name: 'optin_cookie',
    resource: 'Cookie',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/cookie/list').default,
        title: 'cookie_list',
        roles: ['ROLE_ADMIN'],
        permissions: {
          Cookie: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/cookie/form').default,
        title: 'cookie_create_title',
        roles: ['ROLE_ADMIN'],
        permissions: {
          Cookie: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/cookie/form').default,
        title: 'cookie_update_title',
        roles: ['ROLE_ADMIN'],
        permissions: {
          Cookie: ['read', 'update']
        }
      }
    ]
  }
];
