import React, { useEffect, useState, useContext } from 'react';
import DiagEditor from 'wever-diag-editor';
import 'wever-diag-editor/dist/styles/bootstrap.css';

import Context from '@/services/context';
import Logger from '@/services/logger';
import Security from '@/services/security';

export default () => {
  const [id, setId] = useState(null);
  const [isPattern, setPattern] = useState(false);
  const [isSet, setIsSet] = useState(false);
  const isGranted = Security.isGranted('ROLE_ADMIN');
  const { locale } = useContext(Context);

  useEffect(() => {
    if (!isGranted) {
      return;
    }

    /** @todo check if user is admin. */
    const urlParams = new URLSearchParams(window.location.search);
    Logger.info('Url', 'info => ', { urlParams, pattern: urlParams.get('pattern'), id: urlParams.get('id') });
    setId(parseInt(urlParams.get('id'), 10));
    setPattern(urlParams.get('pattern') === 'true');

    setIsSet(true);
  }, [isGranted]);

  if (isGranted && !isSet) {
    return null;
  }

  return (
    <div className="adminapp-editorapp-container">
      <DiagEditor token={Security.token} locale={locale} editionMode diagId={id} isPattern={isPattern} />
    </div>
  );
};
