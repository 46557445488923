import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Datepicker, Editor, Input } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';

import Calculation from './calculations';
import DataView from './data-view';
import Geometries from './geometries';

const Index = ({ match }) => (
  <View>
    <ResourceForm
      resource="ReportPattern"
      id={match.params.id}
      initialValues={{ query: '[]', geometries: { points: [], lines: [], polygons: [] } }}
    >
      {({ submitting, renderActions, values, ...props }) => (
        <>
          <Card>
            <CardBody className={submitting ? 'loading' : ''}>
              <Row className="m-0">
                <Col lg={6}>
                  <Input name="name" />
                  <Datepicker name="startDate" />
                  <Datepicker name="endDate" />
                  <Calculation values={values} {...props} />
                  <Geometries {...props} />
                </Col>
                <Col lg={6}>
                  <Editor name="query" />
                </Col>
              </Row>
              <DataView query={values.query} id={match.params.id} />
            </CardBody>
          </Card>
          <div className="mt-4 mb-4">{renderActions()}</div>
        </>
      )}
    </ResourceForm>
  </View>
);

Index.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default Index;
