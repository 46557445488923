import React from 'react';
import { Badge, Table } from 'reactstrap';

import Icon from '@/components/common/icon';
import ResourceList from '@/components/resources/list';
import View from '@/components/view';
import Context from '@/services/context';
import getResource from '@/services/resources';
import translator, { t } from '@/services/translator';
import object from '@/services/utils/object';

import filters from './filters';

class List extends React.Component {
  /**
   * Get user resource service
   *
   * @return {null|UserResource}
   */
  static get resource() {
    return getResource('User');
  }

  /**
   * Anonymize user(s)
   *
   * @param {string|integer} id
   * @param {callback}       setLoading
   * @param {callback}       fetch
   */
  anonymize(id, { setLoading, fetch }) {
    const { confirm } = this.context;

    confirm(`${translator.translate('confirm_anonymize')} : ${Array.isArray(id) ? id.join(', ') : id}`, () => {
      setLoading(true, () => {
        List.resource.anonymize(id).then(() => fetch());
      });
    });
  }

  /**
   * Delete user(s)
   *
   * @param {string|integer} id
   * @param {callback}       setLoading
   * @param {callback}       fetch
   */
  delete(id, { setLoading, fetch }) {
    const { confirm } = this.context;

    confirm(`${translator.translate('confirm_delete')} : ${Array.isArray(id) ? id.join(', ') : id}`, () => {
      setLoading(true);
      List.resource.delete({ id }).then(() => fetch());
    });
  }

  /**
   * @return {JSX.Element}
   */
  render() {
    const multipleActions = [];

    if (List.resource.canAnonymizeMultiple()) {
      multipleActions.push({
        value: this.anonymize.bind(this),
        label: (
          <>
            <Icon name="user-ninja" className="mr-2" /> {t('anonymize')}
          </>
        )
      });
    }

    return (
      <View>
        <ResourceList
          deleteMultiple
          resource="User"
          multipleActions={multipleActions}
          filters={filters}
          body={({ collection, renderColumn, renderSelect, fetch, setLoading, renderItemActions }) => (
            <Table responsive hover>
              <thead className="thead-light">
                <tr>
                  {renderColumn(renderSelect('*'), false)}
                  {renderColumn('id')}
                  {renderColumn('username')}
                  {renderColumn('person.firstName')}
                  {renderColumn('person.lastName')}
                  {renderColumn('roles')}
                  {renderColumn('enabled')}
                  {renderColumn()}
                </tr>
              </thead>
              <tbody>
                {collection.map((item) => (
                  <tr key={item.id}>
                    <td>{renderSelect(item.id)}</td>
                    <td>{object.get('id', item)}</td>
                    <td>{object.get('username', item)}</td>
                    <td>{object.get('person.firstName', item)}</td>
                    <td>{object.get('person.lastName', item)}</td>
                    <td>
                      {object.get('roles', item, []).map((role) => (
                        <div key={role}>
                          <Badge color="primary" pill>
                            {t(role)}
                          </Badge>
                        </div>
                      ))}
                    </td>
                    <td>
                      {object.get('enabled', item) ? (
                        <Badge className="badge-dot">
                          <i className="bg-success" />
                        </Badge>
                      ) : (
                        <Badge className="badge-dot">
                          <i className="bg-danger" />
                        </Badge>
                      )}
                    </td>
                    <td>
                      {renderItemActions(item, (ActionItem) => (
                        <>
                          {List.resource.canAnonymize() && (
                            <ActionItem onClick={() => this.anonymize(item.id, { fetch, setLoading })}>
                              <Icon name="user-ninja" /> {t('anonymize')}
                            </ActionItem>
                          )}
                        </>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        />
      </View>
    );
  }
}

List.contextType = Context;

export default List;
