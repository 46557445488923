import { useField } from 'formik';
import React, { useState } from 'react';
import { Col, Row, Button } from 'reactstrap';

import { Autocomplete } from '@/components/form';
import { t } from '@/services/translator';

import Content from './content';

export default () => {
  const [field, , helpers] = useField({ name: 'contents' });
  const [langueSelect, setLangueSelect] = useState();

  const handleAddContent = () => {
    const values = [...field.value];

    let isDusplicated = false;
    values.forEach((cookieContent) => {
      isDusplicated = cookieContent.language === langueSelect;
    });

    if (isDusplicated) {
      return;
    }

    values.push({ language: langueSelect, content: '' });

    helpers.setValue(values);
  };

  return (
    <>
      <Row>
        <Col lg="6">
          <Autocomplete
            name={null}
            value={langueSelect}
            onChange={(option) => setLangueSelect(option)}
            resource="Language"
            tag="name"
            display="name"
            autoload
            placeholder={t('admin_add_language_choice')}
          />
        </Col>
        <Col lg="6" className="d-flex justify-content-start">
          <Button color="primary" onClick={handleAddContent}>
            {t('admin_add_content')}
          </Button>
        </Col>
      </Row>

      <Row>{Array.isArray(field.value) && field.value.map((c, index) => <Content key={index} index={index} />)}</Row>
    </>
  );
};
