/* eslint-disable global-require */

export default [
  {
    path: 'configurations',
    name: 'configurations',
    resource: 'Configuration',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/configuration/list').default,
        title: 'configuration_list_title',
        permissions: {
          Configuration: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/configuration/form').default,
        title: 'configuration_create_title',
        permissions: {
          Configuration: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/configuration/form').default,
        title: 'configuration_update_title',
        permissions: {
          Configuration: ['read', 'update']
        }
      }
    ]
  }
];
