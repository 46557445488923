import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, FormGroup, Col, Card } from 'reactstrap';

import Icon from '@/components/common/icon';
import { t } from '@/services/translator';

const CardForm = ({ name, initialValues, formComponent, hide, color }) => {
  const FormComponent = formComponent;
  const [formComponentState, , helper] = useField({ name: `${name}` });
  const props = { name, initialValues, formComponent, hide, color };

  const addFormComponent = () => {
    helper.setValue([...formComponentState.value, { ...initialValues }]);
  };

  const removeFormComponent = (idx) => {
    const newList = [...formComponentState.value];
    newList.splice(idx, 1);
    helper.setValue(newList);
  };

  return (
    <>
      {Array.isArray(formComponentState.value) &&
        formComponentState.value.map((val, idx) => (
          <Card xs={3} className="bg-light mt-3 p-2 vh-2" key={`${name}-${idx}`}>
            <div className={`SheetAction${name.charAt(0).toString().toUpperCase()}`}>
              <FormComponent index={idx} {...props} />
              {formComponentState.value.length < 1 ? null : (
                <Button
                  type="button"
                  className="bg-white rounded-circle border border-danger"
                  style={{ position: 'absolute', top: '-20px', right: '-20px' }}
                  onClick={() => removeFormComponent(`${name}-${idx}`)}
                >
                  <Icon name="times" className="text-danger" />
                </Button>
              )}
            </div>
          </Card>
        ))}
      {formComponentState?.value?.length < 1 ? null : <span> &nbsp; </span>}
      <FormGroup hidden={hide} row>
        <Col sm={7}>
          <Button color={color} type="button" onClick={addFormComponent}>
            <Icon name="plus-circle" className="me-4" />
            {t(`adminapp_sheet_action_add_${name}`)}
          </Button>
        </Col>
      </FormGroup>
    </>
  );
};

CardForm.defaultProps = {
  color: 'danger'
};

CardForm.propTypes = {
  name: PropTypes.string.isRequired,
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  formComponent: PropTypes.elementType.isRequired,
  hide: PropTypes.bool,
  color: PropTypes.string
};

export default CardForm;
