/* eslint-disable global-require */
import AccountTypeRoutes from '@/_configs/routes/account-type.routes';
import CommunityRoutes from '@/_configs/routes/community.routes';
import ConfigurationRoutes from '@/_configs/routes/configuration.routes';
import DashboardRoutes from '@/_configs/routes/dashboard.routes';

/** @todo WIP: transform all routes as file. avoid a big file  */
import DiagnosticRoutes from '@/_configs/routes/diagnostic.routes';
import DocumentRoutes from '@/_configs/routes/document.routes';
import ExternalRoutes from '@/_configs/routes/external_routes.routes';
import FakeRoutes from '@/_configs/routes/fake.routes';
import ManagerRoutes from '@/_configs/routes/manager.routes';
import MapsRoute from '@/_configs/routes/maps.routes';
import PartnerTypeRoutes from '@/_configs/routes/partner-type.routes';
import ServerRoutes from '@/_configs/routes/server.routes';
import SheetAction from '@/_configs/routes/sheet-action.routes';
import environment from '@/services/environment';
import security from '@/services/security';

/**
 * Application routing
 * @todo A mettre à jour pour séparer en plusieurs fichiers. (lecture plus simple)
 */
export default [
  {
    path: environment.get('login_path'),
    name: 'login',
    component: require('@/components/layout/auth-layout').default,
    routes: [
      {
        path: '',
        exact: true,
        component: require('@/views/login').default
      },
      {
        path: '/magic',
        name: 'magic',
        exact: true,
        component: require('@/views/magic-login').default
      },
      {
        path: '/oauth',
        name: 'oauth',
        component: require('@/views/login-oauth').default
      }
    ]
  },
  ...ExternalRoutes,
  {
    path: '',
    component: require('@/components/layout/admin-layout').default,
    roles: ['ROLE_USER'],
    routes: [
      {
        path: '',
        exact: true,
        component: require('@/views/dashboard').default,
        title: 'dashboard'
      },
      ...DiagnosticRoutes,
      ...ConfigurationRoutes,
      ...PartnerTypeRoutes,
      ...CommunityRoutes,
      ...ServerRoutes,
      ...DocumentRoutes,
      ...ManagerRoutes,
      ...AccountTypeRoutes,
      ...DashboardRoutes,
      ...MapsRoute,
      ...FakeRoutes, // FOR FAKE ROUTES
      ...SheetAction,
      {
        path: 'viewer',
        name: 'viewer',
        roles: ['ROLE_MANAGER'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '/map',
            name: 'map',
            exact: true,
            component: require('@/views/map/form').default,
            title: 'viewer_map',
            permissions: {
              Maps: ['all']
            }
          }
        ]
      },
      {
        path: 'users',
        name: 'user',
        resource: 'User',
        roles: ['ROLE_ADMIN'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            component: require('@/views/user/list').default,
            title: 'user_list_title',
            permissions: {
              User: ['read:list']
            }
          },
          {
            path: '/create',
            name: 'create',
            component: require('@/views/user/form').default,
            title: 'user_create_title',
            access: () => {
              const canCreate = security.hasPermission('User', 'create', false);
              if (typeof canCreate === 'boolean') {
                return canCreate;
              }

              return security.isGranted('ROLE_ADMIN');
            }
          },
          {
            path: '/:id',
            name: 'update',
            component: require('@/views/user/form').default,
            title: 'user_update_title',
            permissions: {
              User: ['read', 'update']
            }
          }
        ]
      },
      {
        path: 'reports',
        name: 'report',
        resource: 'Report',
        roles: ['ROLE_ACCOUNT_MANAGER'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            component: require('@/views/report/list').default,
            title: 'report_list_title',
            roles: ['ROLE_ACCOUNT_MANAGER'],
            permissions: {
              Report: ['read:list']
            }
          },
          {
            path: '/create',
            name: 'create',
            component: require('@/views/report/form').default,
            title: 'report_create_title',
            permissions: {
              Report: ['create']
            }
          },
          {
            path: '/:id',
            name: 'update',
            component: require('@/views/report/form').default,
            title: 'report_update_title',
            permissions: {
              Report: ['read', 'update']
            }
          }
        ]
      },
      // PATTERN
      {
        path: 'report_patterns',
        name: 'report_patterns',
        resource: 'ReportPattern',
        roles: ['ROLE_ADMIN'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            component: require('@/views/report-pattern/list').default,
            title: 'report_pattern_list_title',
            permissions: {
              ReportPattern: ['read:list']
            }
          },
          {
            path: '/create',
            name: 'create',
            component: require('@/views/report-pattern/form').default,
            title: 'report_pattern_create_title',
            permissions: {
              ReportPattern: ['create']
            }
          },
          {
            path: '/:id',
            name: 'update',
            component: require('@/views/report-pattern/form').default,
            title: 'report_pattern_update_title',
            permissions: {
              ReportPattern: ['read', 'update']
            }
          }
        ]
      },
      {
        path: 'zone_types',
        name: 'zone_type',
        resource: 'ZoneType',
        roles: ['ROLE_ADMIN'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            component: require('@/views/zone-type/list').default,
            title: 'zone_type_list_title',
            permissions: {
              ZoneType: ['read:list']
            }
          },
          {
            path: '/create',
            name: 'create',
            component: require('@/views/zone-type/form').default,
            title: 'zone_type_create_title',
            permissions: {
              ZoneType: ['create']
            }
          },
          {
            path: '/:id',
            name: 'update',
            component: require('@/views/zone-type/form').default,
            title: 'zone_type_update_title',
            permissions: {
              ZoneType: ['read', 'update']
            }
          }
        ]
      },
      {
        path: 'zones',
        name: 'zone',
        resource: 'Zone',
        roles: ['ROLE_ADMIN'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            component: require('@/views/zone/list').default,
            title: 'zone_list_title',
            permissions: {
              Zone: ['read:list']
            }
          },
          {
            path: '/create',
            name: 'create',
            component: require('@/views/zone/form').default,
            title: 'zone_create_title',
            permissions: {
              Zone: ['create']
            }
          },
          {
            path: '/:id',
            name: 'update',
            component: require('@/views/zone/form').default,
            title: 'zone_update_title',
            permissions: {
              Zone: ['read', 'update']
            }
          }
        ]
      },
      {
        path: 'location_types',
        name: 'location_type',
        roles: ['ROLE_ADMIN'],
        resource: 'LocationType',
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            component: require('@/views/location-type/list').default,
            title: 'location_type_list_title',
            permissions: {
              LocationType: ['read:list']
            }
          },
          {
            path: '/create',
            name: 'create',
            component: require('@/views/location-type/form').default,
            title: 'location_type_create_title',
            permissions: {
              LocationType: ['create']
            }
          },
          {
            path: '/:id',
            name: 'update',
            component: require('@/views/location-type/form').default,
            title: 'location_type_update_title',
            permissions: {
              LocationType: ['read', 'update']
            }
          }
        ]
      },
      {
        path: 'locations',
        name: 'location',
        resource: 'Location',
        roles: ['ROLE_ADMIN'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            component: require('@/views/location/list').default,
            title: 'location_list_title',
            permissions: {
              Location: ['read:list']
            }
          },
          {
            path: '/create',
            name: 'create',
            component: require('@/views/location/form').default,
            title: 'location_create_title',
            permissions: {
              Location: ['create']
            }
          },
          {
            path: '/:id',
            name: 'update',
            component: require('@/views/location/form').default,
            title: 'location_update_title',
            permissions: {
              Location: ['read', 'update']
            }
          }
        ]
      },
      {
        path: 'paths',
        name: 'path',
        resource: 'Path',
        roles: ['ROLE_ADMIN'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            component: require('@/views/path/list').default,
            title: 'path_list_title',
            permissions: {
              Path: ['read:list']
            }
          },
          {
            path: '/create',
            name: 'create',
            component: require('@/views/path/form').default,
            title: 'path_create_title',
            permissions: {
              Path: ['create']
            }
          },
          {
            path: '/:id',
            name: 'update',
            component: require('@/views/path/form').default,
            title: 'path_update_title',
            permissions: {
              Path: ['read', 'update']
            }
          }
        ]
      },
      {
        path: 'languages',
        name: 'language',
        resource: 'Language',
        roles: ['ROLE_ADMIN'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            component: require('@/views/language/list').default,
            title: 'language_list_title',
            access: () => {
              const canCreate = security.hasPermission('Language', 'read:list', false);
              if (typeof canCreate === 'boolean') {
                return canCreate;
              }

              return security.isGranted('ROLE_ADMIN');
            }
          },
          {
            path: '/create',
            name: 'create',
            component: require('@/views/language/form').default,
            title: 'language_create_title',
            permissions: {
              Language: ['create']
            }
          },
          {
            path: '/:id',
            name: 'update',
            component: require('@/views/language/form').default,
            title: 'language_update_title',
            permissions: {
              Language: ['read', 'update']
            }
          }
        ]
      },
      {
        path: 'translations',
        name: 'translation',
        resource: 'Translation',
        roles: ['ROLE_ADMIN'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            component: require('@/views/translation/list').default,
            title: 'translation_list_title',
            permissions: {
              Translation: ['read:list']
            }
          },
          {
            path: '/create',
            name: 'create',
            component: require('@/views/translation/form').default,
            title: 'translation_create_title',
            permissions: {
              Translation: ['create']
            }
          },
          {
            path: '/:id',
            name: 'update',
            component: require('@/views/translation/form').default,
            title: 'translation_update_title',
            permissions: {
              Translation: ['read', 'update']
            }
          }
        ]
      },
      {
        path: 'contract_partner',
        name: 'contract_partner',
        resource: 'ContractPartner',
        roles: ['ROLE_ADMIN'],
        component: require('@/components/layout/view-layout').default,
        routes: [
          {
            path: '',
            name: 'list',
            context: 'read:list',
            exact: true,
            component: require('@/views/contract-partner/list').default,
            title: 'contract_partner_list_title',
            permissions: {
              ContractPartner: ['read:list']
            }
          },
          {
            path: '/create',
            name: 'create',
            component: require('@/views/contract-partner/form').default,
            title: 'contract_partner_create_title',
            permissions: {
              ContractPartner: ['create']
            }
          },
          {
            path: '/:id',
            name: 'update',
            component: require('@/views/contract-partner/form').default,
            title: 'contract_partner_update_title',
            permissions: {
              ContractPartner: ['read', 'update']
            }
          }
        ]
      },
      {
        path: '*',
        title: 'content_not_found',
        component: require('@/views/errors').NotFound
      }
    ]
  }
];
