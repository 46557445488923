import React from 'react';
import { Col, Row } from 'reactstrap';

import { Input, Select, Resource } from '@/components/form';
import { t } from '@/services/translator';

export default () => (
  <Row>
    <Col lg={3}>
      <Input name="username" />
    </Col>
    <Col lg={3}>
      <Input name="['person.firstName']" />
    </Col>
    <Col lg={3}>
      <Input name="['person.lastName']" />
    </Col>
    <Col lg={3}>
      <Select
        name="enabled"
        clearable
        options={[
          { label: t('yes'), value: 1 },
          { label: t('no'), value: 0 }
        ]}
      />
    </Col>
    <Col lg={3}>
      <Select name="roles" clearable multiple />
    </Col>
    <Col lg={3}>
      <Resource name="['person.language.id']" clearable multiple resource="Language" display="name" />
    </Col>
    <Col lg={3}>
      <Select
        name="anonymized"
        clearable
        options={[
          { label: t('yes'), value: 1 },
          { label: t('no'), value: 0 }
        ]}
      />
    </Col>
    <Col lg={3}>
      <Resource name="communities" clearable multiple />
    </Col>
  </Row>
);
