import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'adminapp_menu_account_type',
    icon: <Icon name="restroom" className="iconRightMarginMid" />,
    items: [
      {
        label: 'adminapp_menu_account_type_list',
        route: 'account_types_list'
      },
      {
        label: 'adminapp_menu_account_type_create',
        route: 'account_types_create'
      }
    ]
  }
];
