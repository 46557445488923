import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'adminapp_menu_documents',
    roles: ['ROLE_ADMIN'],
    icon: <i className="ni ni-book-bookmark text-yellow" />,
    items: [
      {
        label: 'adminapp_menu_documents_list',
        route: 'document_officials_list'
      },
      {
        label: 'adminapp_menu_documents_create',
        route: 'document_officials_create'
      },
      {
        label: 'adminapp_menu_document_types',
        icon: <Icon name="bookmark" className="text-primary iconRightMarginMid" />,
        items: [
          {
            label: 'adminapp_menu_document_types_list',
            route: 'document_types_list'
          },
          {
            label: 'adminapp_menu_document_types_create',
            route: 'document_types_create'
          }
        ]
      },
      {
        label: 'adminapp_menu_cookie_list',
        route: 'optin_cookie_list'
      }
    ]
  }
];
