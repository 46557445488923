/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'reactstrap';

import api from '@/services/api';
import storage from '@/services/storage';
import { t } from '@/services/translator';

/*
 * Charge la liste des connexions oAuth
 */
const OauthList = ({ setLoading }) => {
  const [list, setList] = useState();

  const load = async () => {
    const response = await api.get('/oauth/list?context=ManagerApp');

    if (!response.data || response.data.length === 0) {
      return;
    }

    // setLoading(false);
    setList(response.data);
  };

  const createQuery = (connector, key) => {
    const redirect = `https://${window.location.hostname}/login/oauth?key=${key}`;
    const clientId = connector.client_id;
    const state = Date.now();

    storage.set('state', state);

    return `client_id=${clientId}&redirect_uri=${redirect}&response_type=code&state=${state}&scope=openid`;
  };

  const handleRedirect = (connector, key) => {
    window.location.href = `${connector.api_login}?${createQuery(connector, key)}`;
  };

  useEffect(() => {
    // setLoading(true);
    load();
  }, []);

  if (!list) {
    return (
      <div>
        {t('userapp_load_connection')} <Spinner size="sm" color="primary" />
      </div>
    );
  }

  return (
    <Row>
      <Col className="d-flex justify-content-center col-12 mb-2">
        <span>{list && Object.keys(list).length > 0 ? <>--------- {t('managerapp_or')} --------- </> : null}</span>
      </Col>
      {list &&
        Object.keys(list).map((key) => {
          const connector = list[key];

          return (
            <Col className="col-12" key={key}>
              <button
                type="button"
                className={`btn-wever bg-wever-${key}-${connector.color} w-100`}
                onClick={() => handleRedirect(connector, key)}
              >
                {t(`managerapp_${connector.label}`)}
              </button>
            </Col>
          );
        })}
    </Row>
  );
};

export default OauthList;
