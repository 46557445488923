import React, { useEffect, useState } from 'react';
import { Form } from 'reactstrap';

export default ({
  formContent,
  formik,
  subChildren,
  submitting,
  resource,
  mapping,
  renderSubmit,
  renderBackToList,
  renderActions,
  fetch,
  setSubmitting
}) => {
  const [initContext, setInitContext] = useState(true);
  const FormContent = formContent;

  useEffect(() => {
    formik.setFormikState({ ...formik, resource, mapping });
    setInitContext(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (initContext || !FormContent) {
    return null;
  }

  return (
    <Form onSubmit={formik.handleSubmit} noValidate>
      <FormContent
        {...formik}
        mapping={mapping}
        resource={resource}
        submitting={submitting}
        fetch={fetch}
        setSubmitting={setSubmitting}
        renderSubmit={renderSubmit}
        renderBackToList={renderBackToList}
        renderActions={renderActions}
      />
      {subChildren}
    </Form>
  );
};
