import React from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from 'reactstrap';

import logo from '@/assets/img/brand/logo.png';
import UserMenu from '@/components/layout/navbars/user-menu';

import Navigation from './navigation';

// @todo make a componenent 'user panel' that can be used with mobile view and web view
export default class Sidebar extends React.Component {
  state = {
    collapseOpen: false
  };

  // toggles collapse between opened and closed (true/false)
  toggleCollapse = (value) => {
    this.setState({
      collapseOpen: value === undefined ? !this.state.collapseOpen : value
    });
  };

  render() {
    return (
      <Navbar className="navbar-vertical fixed-left navbar-light bg-white" expand="md" id="sidenav-main">
        <Container fluid>
          {/* Toggler */}
          <button className="navbar-toggler" type="button" onClick={this.toggleCollapse}>
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          <NavbarBrand to="/communities" tag={Link} className="pt-0">
            <img alt="logo" className="navbar-brand-img" src={logo} />
          </NavbarBrand>
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UserMenu />
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img alt="logo" src={logo} />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" type="button" onClick={this.toggleCollapse}>
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Search"
                  className="form-control-rounded form-control-prepended"
                  placeholder="Search"
                  type="search"
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <span className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form>
            <Navigation onClick={() => this.toggleCollapse(true)} />
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}
