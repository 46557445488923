/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import logger from '@/services/logger';
import getResource from '@/services/resources';

const Media = ({ data, isLocal, className, height, width, ...propsImg }) => {
  // state
  const [url, setUrl] = useState();

  const videoTypes = ['qt', 'webm', 'mp4', 'avi', 'mkv', 'flv', 'ogg', 'gif', 'mpeg', 'flv', '3gp'];
  const imgTypes = ['tif', 'bmp', 'jpg', 'png', 'eps', 'raw', 'img', 'jpeg'];

  const fetchMedia = async (id) => {
    const response = await getResource('Media').read(id.replace('/media/', ''));
    logger.log('Fetch media', '', { response });
    setUrl(response.path);
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    if (typeof data === 'object' || isLocal) {
      setUrl(data.path);
      return;
    }

    fetchMedia(data);
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <div className={`coreMedia ${className}`}>
      {imgTypes.some((type) => data?.filePath?.includes(type)) ? (
        <img src={url} height={height} width={width} alt="media" {...propsImg} />
      ) : videoTypes.some((type) => data?.filePath?.includes(type)) ? (
        <video height={200} width={200} src={data?.path} controls autoplay>
          <source type="video/*" alt="video" />
        </video>
      ) : (
        data.filePath
      )}
    </div>
  );
};

Media.propTypes = {
  data: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};

Media.defaultProps = {
  className: '',
  height: '50px',
  width: 'auto'
};

export default Media;
