/* eslint-disable global-require */
export default [
  require('./string').default,
  require('./number').default,
  require('./date').default,
  require('./boolean').default,
  require('./array').default,
  require('./object').default,
  require('./common').default
];
