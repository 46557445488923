import React from 'react';
import { Badge, Col, Row, Table } from 'reactstrap';

import { Input, Select } from '@/components/form';
import ResourceList from '@/components/resources/list';
import View from '@/components/view';
import { t } from '@/services/translator';

export default () => (
  <View>
    <ResourceList
      resource="Language"
      deleteMultiple
      filters={() => (
        <Row>
          <Col lg={3}>
            <Input name="name" />
          </Col>
          <Col lg={3}>
            <Input name="locale" />
          </Col>
          <Col lg={3}>
            <Select
              name="enabled"
              clearable
              options={[
                { label: t('yes'), value: 1 },
                { label: t('no'), value: 0 }
              ]}
            />
          </Col>
        </Row>
      )}
      body={({ collection, renderColumn, renderItemActions, renderSelect }) => (
        <Table responsive hover>
          <thead className="thead-light">
            <tr>
              {renderColumn(renderSelect('*'), false)}
              {renderColumn('id')}
              {renderColumn('locale')}
              {renderColumn('name')}
              {renderColumn('enabled')}
              {renderColumn()}
            </tr>
          </thead>
          <tbody>
            {collection.map((item) => (
              <tr key={item.id}>
                <td>{renderSelect(item.id)}</td>
                <td>{item.id}</td>
                <td>{item.locale}</td>
                <td>{item.name}</td>
                <td>
                  {item.enabled ? (
                    <Badge className="badge-dot">
                      <i className="bg-success" />
                    </Badge>
                  ) : (
                    <Badge className="badge-dot">
                      <i className="bg-danger" />
                    </Badge>
                  )}
                </td>
                <td>{renderItemActions(item)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    />
  </View>
);
