/* eslint-disable react-hooks/exhaustive-deps */
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Card, CardBody, Button } from 'reactstrap';

import { Input, TableLangValue } from '@/components/form';
import { t } from '@/services/translator';

const Parameters = (props) => {
  // props
  const { name } = props;

  const [field, , helper] = useField({ name });
  const [fieldTrans, , helperTrans] = useField({ name: 'translation' });

  const addParameter = () => {
    const val = field.value || [];
    val.push('');

    helper.setValue(val);
  };

  const handleTranslationChange = (param, valueTr) => {
    const valueTrans = !Array.isArray(fieldTrans.value) ? fieldTrans.value : {};
    valueTrans[param] = valueTr;
    helperTrans.setValue(valueTrans);
  };

  return (
    <Card>
      <CardBody>
        <p>{t('weverapp_parameters_list')}</p>
        <Alert color="info">{t('graph_type_parameters_alerte')}</Alert>
        <br />
        <Button size="sm" onClick={addParameter}>
          {t('weverapp_add_parameter')}
        </Button>
        <br />
        {Array.isArray(field.value) &&
          field.value.map((param, indexParam) => {
            return (
              <Card className="my-3 bg-light">
                <CardBody>
                  <Input name={`parameters[${indexParam}]`} key={indexParam} label={null} />
                  <TableLangValue
                    name={`translation[${param}]`}
                    onChange={(value) => handleTranslationChange(param, value)}
                  />
                </CardBody>
              </Card>
            );
          })}
      </CardBody>
    </Card>
  );
};

Parameters.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string
};

Parameters.defaultProps = {
  value: null,
  name: null
};

export default Parameters;
