/* eslint-disable global-require */

export default [
  {
    path: 'diagnostics',
    name: 'diagnostic',
    roles: ['ROLE_ACCOUNT_MANAGER'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '/editor',
        name: 'editor',
        exact: true,
        component: require('@/views/diagnostic-editor/form').default,
        title: 'diag_editor',
        permissions: {
          Diagnostic: ['create']
        }
      }
    ]
  },
  {
    path: 'expected_response_pattern',
    name: 'expected_response_pattern',
    resource: 'ExpectedResponsePattern',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/expected-response-pattern/list').default,
        title: 'expected_response_pattern_list_title',
        permissions: {
          ExpectedResponsePattern: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/expected-response-pattern/form').default,
        title: 'expected_response_pattern_create_title',
        permissions: {
          ExpectedResponsePattern: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/expected-response-pattern/form').default,
        title: 'expected_response_pattern_update_title',
        permissions: {
          ExpectedResponsePattern: ['read', 'update']
        }
      }
    ]
  },
  {
    path: 'question_pattern',
    name: 'question_pattern',
    resource: 'QuestionPattern',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/question-pattern/list').default,
        title: 'question_pattern_list_title',
        permissions: {
          QuestionPattern: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/question-pattern/form').default,
        title: 'question_pattern_create_title',
        permissions: {
          QuestionPattern: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/question-pattern/form').default,
        title: 'question_pattern_update_title',
        permissions: {
          QuestionPattern: ['read', 'update']
        }
      }
    ]
  },
  {
    path: 'tag',
    name: 'tag',
    resource: 'Tag',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/tag/list').default,
        title: 'tag_list_title',
        permissions: {
          Tag: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/tag/form').default,
        title: 'tag_create_title',
        permissions: {
          Tag: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/tag/form').default,
        title: 'tag_update_title',
        permissions: {
          Tag: ['read', 'update']
        }
      }
    ]
  },
  {
    path: 'type',
    name: 'type',
    resource: 'Type',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/type/list').default,
        title: 'type_list_title',
        permissions: {
          Type: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/type/form').default,
        title: 'type_create_title',
        permissions: {
          Type: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/type/form').default,
        title: 'type_update_title',
        permissions: {
          Type: ['read', 'update']
        }
      }
    ]
  },
  {
    path: 'diagnostic',
    name: 'diagnostic',
    resource: 'Diagnostic',
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/diagnostic/list').default,
        title: 'diagnostic_list_title',
        permissions: {
          Diagnostic: ['diagnostic:read:list:manager']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/diagnostic/form').default,
        title: 'diagnostic_form_title',
        roles: ['ROLE_ADMIN'],
        permissions: {
          Diagnostic: ['read', 'update']
        }
      }
    ]
  },
  {
    path: 'diagnostic_pattern',
    name: 'diagnostic_pattern',
    resource: 'DiagnosticPattern',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/diagnostic-pattern/list').default,
        title: 'diagnostic_list_title',
        permissions: {
          DiagnosticPattern: ['read:list']
        }
      }
    ]
  },
  {
    path: 'condition_types',
    name: 'condition_types',
    resource: 'ConditionType',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/condition-type/list').default,
        title: 'condition_types_list_title',
        permissions: {
          ConditionType: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/condition-type/form').default,
        title: 'condition_types_create_title',
        permissions: {
          ConditionType: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/condition-type/form').default,
        title: 'condition_types_update_title',
        permissions: {
          ConditionType: ['read', 'update']
        }
      }
    ]
  },
  {
    path: 'diagnostic_type',
    name: 'diagnostic_type',
    resource: 'DiagnosticType',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/diagnostic-type/list').default,
        title: 'type_list_title',
        permissions: {
          DiagnosticType: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/diagnostic-type/form').default,
        title: 'diagnostic_type_create_title',
        permissions: {
          DiagnosticType: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/diagnostic-type/form').default,
        title: 'diagnostic_type_update_title',
        permissions: {
          DiagnosticType: ['read', 'update']
        }
      }
    ]
  },
  // ----- ACTIONS
  {
    path: 'action_type',
    name: 'action_type',
    resource: 'ActionType',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/actions/type/list').default,
        title: 'action_type_list_title',
        permissions: {
          ActionType: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/actions/type/form').default,
        title: 'action_type_create_title',
        permissions: {
          ActionType: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/actions/type/form').default,
        title: 'action_type_update_title',
        permissions: {
          ActionType: ['read', 'update']
        }
      }
    ]
  },
  // ---
  {
    path: 'actions',
    name: 'actions',
    resource: 'Action',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/actions/action/list').default,
        title: 'action_list_title',
        permissions: {
          Action: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/actions/action/form').default,
        title: 'action_create_title',
        permissions: {
          Action: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/actions/action/form').default,
        title: 'action_update_title',
        permissions: {
          Action: ['read', 'update']
        }
      }
    ]
  }
];
