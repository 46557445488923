import api from '@/services/api';
import Resource from '@/services/resources/common';

export default class Translation extends Resource {
  constructor(entrypoint = '/translations', alias = 'Translation') {
    super(entrypoint, alias);
  }

  fetchByLocale(locale) {
    return api.get(this.buildUri('by_locale'), { locale }).then(({ data }) => data);
  }
}
