import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink, UncontrolledCollapse, Fade } from 'reactstrap';

import navigation, { bottomNavigationItem, subNavigation, subNavigationTitle } from '@/navigation';
import router from '@/services/router';
import security from '@/services/security';
import { t } from '@/services/translator';

const renderNavigation = (data, depth = 1) =>
  data.map((item, i) => {
    const { label, icon, items, href, target, route, params, roles, access } = item;

    if (roles && !security.isGranted(roles)) {
      return null;
    }

    // Build path
    let path = href;
    if (!path) {
      path = item.path || router.path(route, params);

      if (path.indexOf('/') !== 0) {
        path = '/' + path;
      }
    }

    // In some case, the depth and i can be the same. So we add the formated label to create a diff.
    const formatedLabel = label.replace(' ', '_');

    const id = `nav-item-${depth}-${i}-${formatedLabel}`;
    const pathname = router.currentUri;

    // Check access

    if (typeof access === 'function' && !access()) {
      return null;
    }

    if (!router.isAccessible(route)) {
      return null;
    }

    // Display children of an item only if user has access at least to one item
    if (items) {
      let hasAccessibleItems = false;

      for (let n = 0, len = items.length; n < len; ++n) {
        if (typeof items[n].access === 'function' && !items[n].access()) {
          continue;
        }

        if (router.isAccessible(items[n].route)) {
          hasAccessibleItems = true;
          break;
        }
      }

      if (!hasAccessibleItems) {
        return null;
      }
    }

    return (
      <NavItem key={id}>
        <NavLink
          tag={href ? undefined : Link}
          href={href}
          target={target}
          to={items ? '#' : path}
          id={items ? id : undefined}
          active={items ? pathname.indexOf(path) === 0 : pathname === path}
        >
          {icon} {t(label)}
        </NavLink>
        {items && (
          <Nav>
            <UncontrolledCollapse toggler={'#' + id} defaultOpen={pathname.indexOf(path) === 0}>
              {renderNavigation(items, depth + 1)}
            </UncontrolledCollapse>
          </Nav>
        )}
      </NavItem>
    );
  });

export default ({ onClick }) => (
  <Fade>
    {/* Root navigation */}
    <Nav className="navigation" navbar onClick={onClick}>
      {renderNavigation(navigation)}
    </Nav>

    {subNavigation.length > 0 && (
      <>
        <hr className="my-3" />
        {subNavigationTitle && <h6 className="navbar-heading text-muted">{t(subNavigationTitle)}</h6>}
      </>
    )}

    {/*/!* Sub navigation *!/*/}
    <Nav className="mb-md-3" navbar>
      {renderNavigation(subNavigation)}
    </Nav>

    {/* Bottom navigation */}
    {bottomNavigationItem && (
      <Nav className="mb-md-3" navbar>
        <NavItem className="active-pro active">
          <NavLink to={bottomNavigationItem.path} tag={Link}>
            {bottomNavigationItem.icon}
            {t(bottomNavigationItem.label)}
          </NavLink>
        </NavItem>
      </Nav>
    )}
  </Fade>
);
