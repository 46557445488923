import { useContext, useEffect } from 'react';

import Context from '@/services/context';

export default ({ title, children }) => {
  const context = useContext(Context);

  useEffect(() => {
    if (title && context.title !== title) {
      context.update({ title });
    }
  });

  return children;
};
