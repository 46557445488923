/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import getResource from '@/services/resources';

import AccordionItem from './accordion-item';

export default ({ match }) => {
  const [information, setInformation] = useState();
  const id = match.params.id;

  const loadDocuments = async () => {
    const data = await getResource('Community').readPublicInformation(id);

    setInformation(data);
  };

  useEffect(() => {
    if (!id) {
      return;
    }

    loadDocuments();
  }, [id]);

  if (!id || !information) {
    return null;
  }

  return (
    <>
      {/* <div className="shared-community-logo">{information && information.logo && <Media data={information.logo} />}</div> */}
      <div>
        {information &&
          Array.isArray(information.documentOfficials) &&
          information.documentOfficials.map((document, index) => {
            return <AccordionItem key={index} document={document} show={index === 0} />;
          })}
      </div>
    </>
  );
};
