/**
 * Environment is used to provide vars declared in .env
 * All of react env variable must be prefixed by REACT_APP_...
 * This service allows you to access env variable without prefix REACT_APP_
 */
export default {
  /**
   * Store env vars
   */
  storage: process.env,

  /**
   * Is development context
   *
   * @return {boolean}
   */
  get dev() {
    return this.get('node_env') === 'development';
  },

  /**
   * Get var value
   *
   * @param {string} key - REACT_APP_ can be omitted
   *
   * @return {string|undefined}
   */
  get(key) {
    key = key.toUpperCase();
    return this.storage[`REACT_APP_${key}`] || this.storage[key];
  },

  /**
   * Check is var name exist in environment storage
   *
   * @param {string} key
   *
   * @return {boolean}
   */
  has(key) {
    return this.get(key) !== undefined;
  }
};
