import { useField, useFormikContext } from 'formik';
import React from 'react';
import { Button, FormGroup, Table } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Error, Label } from '@/components/form';
import { t } from '@/services/translator';
import string from '@/services/utils/string';

export default ({
  name,
  label,
  size = 'sm',
  groupTag = FormGroup,
  labelTag = Label,
  errorTag = Error,
  inputTag = 'input',
  groupProps = {},
  labelProps = {},
  inputProps = {},
  errorProps = {}
}) => {
  const [field] = useField({ name });
  const { setFieldValue } = useFormikContext();

  const GroupTag = groupTag;
  const LabelTag = labelTag;
  const ErrorTag = errorTag;
  const InputTag = inputTag;

  label = labelProps.text || label;
  if (label === undefined) {
    label = name;
  }

  const collection = field.value || [];

  return (
    <GroupTag {...groupProps}>
      {/* Label */}
      {label && (
        <>
          <LabelTag name={name} size={size} {...labelProps}>
            <h3>{typeof label === 'string' ? string.ucfirst(label) : label}</h3>
          </LabelTag>
          <Button
            size="md"
            className="bg-transparent border-0 shadow-none"
            onClick={() => {
              collection.unshift([]);
              setFieldValue(name, collection);
            }}
          >
            <Icon name="plus-circle" />
          </Button>
        </>
      )}

      {collection.length > 0 && (
        <>
          <Table>
            <thead>
              <tr>
                <th>{t('longitude')}</th>
                <th>{t('latitude')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {collection.map((coord, i) => (
                <tr key={i}>
                  <td>
                    <InputTag
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        setFieldValue(`${name}[${i}][0]`, e.target.value);
                      }}
                      value={coord[0] || ''}
                      {...inputProps}
                    />
                  </td>
                  <td>
                    <InputTag
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        setFieldValue(`${name}[${i}][1]`, e.target.value);
                      }}
                      value={coord[1] || ''}
                      {...inputProps}
                    />
                  </td>
                  <td>
                    <Button
                      size="sm"
                      color="warning"
                      className="mt-1 shadow-none"
                      onClick={() => {
                        collection.splice(i, 1);
                        setFieldValue(name, collection);
                      }}
                    >
                      <Icon name="times" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="mt-2">
            <Button
              size="sm"
              className="shadow-none"
              onClick={() => {
                collection.push([]);
                setFieldValue(name, collection);
              }}
            >
              {t('add')}
              <Icon name="plus-circle" className="ml-2" />
            </Button>
          </div>
        </>
      )}

      {/* Errors */}
      <ErrorTag name={name} {...errorProps} />
    </GroupTag>
  );
};
