import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  NavItem,
  NavLink,
  Nav,
  Media,
  PopoverBody,
  UncontrolledPopover,
  Spinner
} from 'reactstrap';

import userImg from '@/assets/img/theme/female.png';
import Context from '@/services/context';
import router from '@/services/router';
import security from '@/services/security';
import translator, { t } from '@/services/translator';

const UserMenu = () => {
  const [languageLoading, setLanguageLoading] = useState(true);

  useEffect(() => {
    translator.fetchLanguages().then(() => setLanguageLoading(false));
  });

  return (
    <UncontrolledDropdown nav>
      <DropdownToggle className="pr-0" nav>
        <Media className="align-items-center">
          <span className="avatar avatar-md rounded-circle">
            <img alt="My account" src={userImg} />
          </span>
        </Media>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" right>
        {/* <DropdownItem to="/admin/user-profile" tag={Link}>
          <i className="ni ni-single-02" />
          <span>{t('my_profile')}</span>
        </DropdownItem> */}
        {security.hasPermissions({ Translation: ['update'] }) && (
          <Context.Consumer>
            {({ update, editionMode }) => (
              <DropdownItem onClick={() => update({ editionMode: !editionMode })}>
                <i className={`ni ni-ruler-pencil ${editionMode === true ? 'text-warning' : ''}`} />
                <span>{t(editionMode === false ? 'enable_edition_mode' : 'disable_edition_mode')}</span>
              </DropdownItem>
            )}
          </Context.Consumer>
        )}
        <DropdownItem id="tooltip876279349">
          <i className="ni ni-world-2" />
          <span>{t('change_language')}</span>
          <UncontrolledPopover placement="left" target="tooltip876279349" trigger="hover">
            <PopoverBody>
              {languageLoading ? (
                <Spinner size="md" />
              ) : (
                <Nav className="nav-pills-circle" pills role="tablist">
                  <Context.Consumer>
                    {(context) =>
                      translator.languages.map(({ locale, name }) => (
                        <NavItem key={locale}>
                          <NavLink
                            role="tab"
                            aria-selected={locale === context.locale}
                            onMouseUp={() => translator.setLocale(locale)}
                            className={`pointer rounded-circle ${locale === context.locale ? 'active' : ''}`}
                          >
                            {name}
                          </NavLink>
                        </NavItem>
                      ))
                    }
                  </Context.Consumer>
                </Nav>
              )}
            </PopoverBody>
          </UncontrolledPopover>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          href="#disconnect"
          onClick={(e) => {
            e.preventDefault();
            security.deAuthenticate();
            router.navigate('login');
          }}
        >
          <i className="ni ni-user-run" />
          <span>{t('logout')}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserMenu;
