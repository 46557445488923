import React from 'react';
import Moment from 'react-moment';

import Context from '@/services/context';

export default ({
  date = true, // show date
  time = false, // show time
  seconds = false, // show time with seconds
  children, // formatted date or moment/date object
  ...props // extra props
}) => {
  if (children === null) {
    return null;
  }

  if (typeof children !== 'string') {
    throw new Error('Only string nodes are accepted as children');
  }

  return (
    <Context.Consumer>
      {({ locale }) => (
        <>
          {date && (
            <Moment locale={locale} format="L" {...props}>
              {children}
            </Moment>
          )}
          {time && (
            <Moment className="ml-1" locale={locale} format={seconds ? 'LTS' : 'LT'} {...props}>
              {children}
            </Moment>
          )}
        </>
      )}
    </Context.Consumer>
  );
};
