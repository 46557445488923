import React from 'react';
import { Col, Row, Table } from 'reactstrap';

import { Input, Resource } from '@/components/form';
import ResourceList from '@/components/resources/list';
import View from '@/components/view';
import translator from '@/services/translator';

export default () => (
  <View>
    <ResourceList
      resource="Translation"
      deleteMultiple
      initialFilters={{ 'lang.locale': translator.getLocale() }}
      filters={() => (
        <Row>
          <Col lg={3}>
            <Input name="key" />
          </Col>
          <Col lg={3}>
            <Input name="value" />
          </Col>
          <Col lg={3}>
            <Resource name="['lang.locale']" targetProperty="locale" resource="Language" display="name" clearable />
          </Col>
        </Row>
      )}
      body={({ collection, renderColumn, renderItemActions, renderSelect }) => (
        <Table responsive hover>
          <thead className="thead-light">
            <tr>
              {renderColumn(renderSelect('*'), false)}
              {renderColumn('id')}
              {renderColumn('key')}
              {renderColumn('value')}
              {renderColumn('lang.locale')}
              {renderColumn()}
            </tr>
          </thead>
          <tbody>
            {collection.map((item) => (
              <tr key={item.id}>
                <td>{renderSelect(item.id)}</td>
                <td>{item.id}</td>
                <td>{item.key}</td>
                <td>{item.value}</td>
                <td>{item.lang && item.lang.locale}</td>
                <td>{renderItemActions(item)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    />
  </View>
);
