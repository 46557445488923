import React from 'react';
import { Col, Row, Table } from 'reactstrap';

import Date from '@/components/common/date';
import { Datepicker, Input } from '@/components/form';
import ResourceList from '@/components/resources/list';
import View from '@/components/view';

export default () => (
  <View>
    <ResourceList
      resource="ReportPattern"
      deleteMultiple
      filters={() => (
        <Row>
          <Col lg={3}>
            <Input name="name" />
          </Col>
          <Col lg={3}>
            <Datepicker name="['startDate[after]']" />
          </Col>
          <Col lg={3}>
            <Datepicker name="['startDate[before]']" />
          </Col>
          <Col lg={3}>
            <Datepicker name="['endDate[after]']" />
          </Col>
          <Col lg={3}>
            <Datepicker name="['endDate[before]']" />
          </Col>
          <Col lg={3}>
            <Datepicker name="['lastSync[after]']" />
          </Col>
          <Col lg={3}>
            <Datepicker name="['lastSync[before]']" />
          </Col>
        </Row>
      )}
      body={({ collection, renderColumn, renderItemActions, renderSelect }) => (
        <Table responsive hover>
          <thead className="thead-light">
            <tr>
              {renderColumn(renderSelect('*'), false)}
              {renderColumn('id')}
              {renderColumn('name')}
              {renderColumn('startDate')}
              {renderColumn('endDate')}
              {renderColumn('lastSync')}
              {renderColumn()}
            </tr>
          </thead>
          <tbody>
            {collection.map((item) => (
              <tr key={item.id}>
                <td>{renderSelect(item.id)}</td>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>
                  <Date>{item.startDate}</Date>
                </td>
                <td>
                  <Date>{item.endDate}</Date>
                </td>
                <td>
                  <Date time>{item.lastSync}</Date>
                </td>
                <td>{renderItemActions(item)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    />
  </View>
);
