import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { Button, Table, Row, Col } from 'reactstrap';

import { Editor, Input } from '@/components/form';
import Api from '@/services/api';
import notifications from '@/services/notifications';
import { t } from '@/services/translator';

const ModalContent = (props) => {
  const { api, onUpdate, utils } = props;
  const [tableData, setTableData] = useState();
  const [thead, setThead] = useState();

  const btnRef = useRef();

  const apiCall = async (values, hasLimit = true) => {
    const { query, name } = values;

    const response = await Api.post(api, {
      query,
      limit: hasLimit ? 5 : null
    });

    const data = response.data.preview;

    if (!hasLimit && (!name || !data)) {
      notifications.warning(t('add point'), t('cannot_add_without_name_or_data'));

      return false;
    }

    if (!hasLimit && name && data) {
      onUpdate(api, name, data);

      return true;
    }

    setTableData(data);

    if (Array.isArray(data) && data.length > 0) {
      setThead(Object.keys(data[0]));
    }

    return true;
  };

  const onSubmit = async (values) => {
    apiCall(values, false);
  };

  const getSearchValues = (values) => {
    apiCall(values);
  };

  return (
    <Formik initialValues={{ query: '[]', name: '' }} onSubmit={onSubmit}>
      {({ values }) => (
        <Form>
          <Input name="name" />
          <Editor name="query" height="100" />
          <Button onClick={() => getSearchValues(values)} size="sm" color="info" ref={btnRef}>
            {' '}
            {t('search')}
          </Button>
          <br />
          <Table>
            <thead>
              <tr>
                {thead &&
                  thead.map((th) => {
                    return <th key={`th_${th}`}>{th}</th>;
                  })}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(tableData) &&
                tableData.map((tr, i) => {
                  return (
                    <tr key={i}>
                      {Object.keys(tr).map((keyTd) => {
                        return <td key={`td_${keyTd}`}>{JSON.stringify(tr[keyTd])}</td>;
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button title="save" type="submit">
                {t('save')}
              </Button>{' '}
              <Button title="cancel" onClick={utils.closeModal}>
                {t('cancel')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

ModalContent.propTypes = {
  api: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  utils: PropTypes.shape({ closeModal: PropTypes.func })
};

ModalContent.defaultProps = {
  api: '/locations/query',
  utils: {}
};

export default ModalContent;
