import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Input, Resource } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';
import translator from '@/services/translator';

const Form = ({ match }) => (
  <View>
    <ResourceForm
      resource="Translation"
      id={match.params.id}
      onSuccess={(item) => translator.fetch(item.lang.locale, true)}
    >
      {({ submitting, renderActions }) => (
        <>
          <Card>
            <CardBody className={submitting ? 'loading' : ''}>
              <Row>
                <Col lg={6}>
                  <Input name="key" />
                </Col>
                <Col lg={6}>
                  <Resource name="lang" display="name" />
                </Col>
                <Col lg={6}>
                  <Input name="value" />
                </Col>
                <Col lg={6}>
                  <Input name="pluralValue" />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="mt-4 mb-4">{renderActions()}</div>
        </>
      )}
    </ResourceForm>
  </View>
);

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default Form;
