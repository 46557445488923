import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Error, Label } from '@/components/form';
import IconsList from '@/fontawesome';
import { t } from '@/services/translator';

/**
 * @todo à revoir quand le temps s y pretera.
 */
const SelectIcon = (props) => {
  const { name } = props;
  // hook
  const [field, meta, helpers] = useField({ name });
  const { submitCount } = useFormikContext();
  const { value } = field;
  const { setValue } = helpers;
  // state
  const [selection, setSelection] = useState();

  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        setSelection({ value, label: <Icon name={value} /> });
      } else {
        setSelection(value);
      }
    }
  }, [value]);

  const getIconsOptions = useCallback(() => {
    const options = IconsList.map((icon) => ({ value: icon.iconName, label: <Icon name={icon.iconName} /> }));

    return options;
  }, []);

  return (
    <FormGroup>
      <Label name={name} />
      <Select
        options={getIconsOptions()}
        value={selection}
        placeholder={t('icon')}
        onChange={(option) => setValue(option.value)}
        isClearable
        invalid={(meta.touched || submitCount > 0) && meta.error !== undefined}
      />
      <Error name={name} />
    </FormGroup>
  );
};

SelectIcon.propTypes = {
  name: PropTypes.string.isRequired
};

export default SelectIcon;
