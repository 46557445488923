import * as yup from 'yup';

/**
 * Number extension validator
 *
 * @see @/services/mapping/validator
 */
export default {
  supports: ['number', 'int', 'integer', 'float'],

  build({ constraints }) {
    let rule = yup.number();

    for (let i = 0, len = constraints.length; i < len; ++i) {
      switch (constraints[i].alias) {
        case 'Positive':
          rule = rule.positive(constraints[i].message);
          break;
        case 'PositiveOrZero':
          rule = rule.min(0, constraints[i].message);
          break;
        case 'Negative':
          rule = rule.negative(constraints[i].message);
          break;
        case 'NegativeOrZero':
          rule = rule.max(0, constraints[i].message);
          break;
        case 'DivisibleBy':
          rule = rule.test(
            'divisible-by',
            constraints[i].message.replace('{{ compared_value }}', constraints[i].value),
            (value) => Number(value) % constraints[i].value === 0
          );
          break;
        default:
          break;
      }
    }

    return rule;
  }
};
