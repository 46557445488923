import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Input } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';

import Autocomplete from './autocomplete-fonction';
import Parameters from './parameters';

const TypeForm = ({ match }) => {
  return (
    <View>
      <ResourceForm
        resource="GraphType"
        id={match.params.id}
        initialValues={{ name: '', parameters: [], translation: {} }}
      >
        {({ submitting, values, renderActions }) => (
          <>
            <Card>
              <CardBody className={submitting ? 'loading' : ''}>
                <Row>
                  <Col lg={6}>
                    <Input
                      name="name"
                      placeholder="<type>_<name>: ex : radar_transport_mode; stat_traverler_sheet_car"
                    />
                  </Col>
                  <Col lg={6}>
                    <Autocomplete name="function" />
                  </Col>
                  <Col lg={12}>
                    <Parameters name="parameters" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

TypeForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default TypeForm;
