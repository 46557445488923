import * as yup from 'yup';

import validator from '@/services/mapping/validator';

/**
 * Nested object extension validator
 *
 * @see @/services/mapping/validator
 */
export default {
  supports: ['one', 'many', 'object'],

  build({ /* constraints, */ type, properties, cascade }, rule) {
    const isCollection = type === 'many';

    if (!cascade) {
      return null;
    }

    if (!isCollection) {
      return validator.build(properties);
    }

    rule = rule || yup.array();
    if (typeof properties === 'object' && Object.keys(properties).length > 0) {
      rule = rule.of(validator.build(properties));
    }

    return rule;
  }
};
