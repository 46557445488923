import React from 'react';
import { Container } from 'reactstrap';

export default ({ children }) => (
  <div className="header bg-gradient-info pb-8 pt-5 pt-md-8 z-index--1">
    <Container fluid>
      <div className="header-body">{children}</div>
    </Container>
  </div>
);
