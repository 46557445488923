import { useField, useFormikContext } from 'formik';
import React from 'react';
import { FormGroup, Input } from 'reactstrap';

import { Error, Label } from '@/components/form';
import string from '@/services/utils/string';

export default ({
  name,
  label,
  size = 'sm',
  groupTag = FormGroup,
  labelTag = Label,
  errorTag = Error,
  inputTag = Input,
  groupProps = {},
  labelProps = {},
  inputProps = {},
  errorProps = {},
  ...props
}) => {
  const [field, meta, helper] = useField({ name });
  const { submitCount } = useFormikContext();

  const GroupTag = groupTag;
  const LabelTag = labelTag;
  const ErrorTag = errorTag;
  const InputTag = inputTag;

  const invalid = (meta.touched || submitCount > 0) && meta.error !== undefined;
  const id = 'toggle-input-' + name;

  label = labelProps.text || label;
  if (label === undefined) {
    label = name;
  }

  return (
    <GroupTag {...groupProps}>
      {/* INPUT */}
      <div className="flex">
        <div>
          <label className="custom-toggle">
            <InputTag
              id={id}
              name={name}
              type="checkbox"
              invalid={invalid}
              checked={Boolean(field.value)}
              onBlur={field.onBlur}
              onChange={(e) => {
                helper.setValue(e.target.checked);
              }}
              {...props}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </div>

        {/* LABEL */}
        {label && (
          <div className="ml-2">
            <LabelTag name={name} size={size} {...labelProps} for={id}>
              {typeof label === 'string' ? string.ucfirst(label) : label}
            </LabelTag>
          </div>
        )}
      </div>

      {/* ERROR */}
      <ErrorTag name={name} {...errorProps} />
    </GroupTag>
  );
};
