/* eslint-disable global-require */

export default [
  {
    path: '/shared',
    name: 'shared',
    component: require('@/components/layout/shared/layout').default,
    routes: [
      {
        path: '/community/:id/information',
        resource: 'Community',
        component: require('@/views/community/documents').default
      }
    ]
  }
];
