import React from 'react';
import { Input } from 'reactstrap';

import Icon from './icon';

const InputWever = ({ icon, type, ...args }) => {
  return (
    <div className="userapp-wever-input d-flex align-items-center">
      <Icon name={icon} className="input-icon" />
      <Input type={type} {...args} />
    </div>
  );
};

export default InputWever;
