/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardText, Input, InputGroup, Spinner } from 'reactstrap';
import { _Api } from 'wever-dashboard/dist/services/core';

import '@/assets/scss/components/discussion.scss';
import View from '@/components/view';
import getResource from '@/services/resources';
import security from '@/services/security';
import { t } from '@/services/translator';

const Discussion = ({ actionSheet }) => {
  const [discussions, setDiscussions] = useState([]);
  const [localMessage, setLocalMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [headPosition, setHeadPosition] = useState([]);
  const [messageBody, setMessageBody] = useState([]);
  const [currentUserData, setCurrentUserData] = useState();
  const scrollRef = useRef(null);

  const updateStyle = (discussions) => {
    const headPositionHelper = [],
      messageBodyHelper = [];
    const currentDiscussion = discussions.filter((discussion) => discussion.actionSheet.id === actionSheet.id);

    if (currentDiscussion.length === 0) {
      headPositionHelper.push('discussionRight');
      messageBodyHelper.push('discussionSelf');
    } else {
      currentDiscussion.forEach((element) =>
        element.messages.forEach((message) => {
          if (
            message.user.id === security.user.id ||
            (typeof message.user === 'string' ? parseInt(message.user.replace(/\D/g, '')) : undefined) ===
              security.user.id
          ) {
            headPositionHelper.push('discussionRight');
            messageBodyHelper.push('discussionSelf');
          } else {
            headPositionHelper.push('discussionLeft');
            messageBodyHelper.push('discussionOther');
          }
        })
      );
    }

    setHeadPosition(headPositionHelper);
    setMessageBody(messageBodyHelper);
  };

  const loading = async () => {
    let response = await _Api.get('/discussions');
    setDiscussions(response.data['hydra:member']);
    updateStyle(response.data['hydra:member']);

    response = await getResource('User').read(security.user.id);
    setCurrentUserData(response);
  };

  const messageBuilder = () => {
    return {
      user: '/users/' + security.user.id,
      date: new Date().toLocaleString('en-US'),
      message: localMessage
    };
  };

  const discussionBodyBuilder = async () => {
    const communities = await getResource('Community').list();
    const community = Object.assign(
      {},
      ...communities['hydra:member'].filter((community) =>
        community.sheetActions.some((action) => action.sheetAction.id === actionSheet.id)
      )
    );
    const messagesHelper = discussions.filter((el) => el.actionSheet.id === actionSheet.id).messages ?? [];

    messagesHelper.push(messageBuilder());

    return {
      community: community,
      actionSheet: actionSheet,
      messages: messagesHelper
    };
  };

  const sendMessage = async () => {
    if (!localMessage.trim() || isProcessing) return;

    setIsProcessing(true);
    if (discussions.some((el) => el.actionSheet.id === actionSheet.id)) {
      const toUpdateDiscussion = Object.assign({}, ...discussions.filter((el) => el.actionSheet.id === actionSheet.id));
      const index = discussions.findIndex((element) => element.actionSheet.id === actionSheet.id);

      toUpdateDiscussion.messages.push(messageBuilder());
      await _Api.put('/discussions/' + toUpdateDiscussion.id, toUpdateDiscussion);

      setDiscussions(discussions.splice(index, 1, toUpdateDiscussion));

      updateStyle(discussions);
    } else {
      await _Api.post('/discussions', await discussionBodyBuilder());

      setDiscussions(discussions.concat([await discussionBodyBuilder()]));
      updateStyle(discussions);
    }
    scrollToBottom();
    setLocalMessage('');
    setIsProcessing(false);
  };

  const handleOnMessageChange = (e) => {
    setLocalMessage(e.target.value);
  };

  const scrollToBottom = () => {
    scrollRef.current.style.scrollTop = scrollRef.current.offsetTop + scrollRef.current.offsetHeight;
  };

  useEffect(() => {
    loading();
    scrollToBottom();
  }, []);

  return (
    <>
      <View>
        <Card>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div id="left">
                <h2>
                  {t(`adminapp_operation_name`)} : {t(actionSheet.name)}
                </h2>
                <div style={{ color: '#D73755', display: 'flex' }}>
                  <div>{t('adminapp_categories')} :</div>
                  <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '3px' }}>
                    {Array.isArray(actionSheet.categories) && actionSheet.categories.length === 0 ? (
                      <p>N/A</p>
                    ) : (
                      actionSheet.categories?.map((category) => (
                        <div className="discussionCategory">
                          <p>{category.name}</p>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
              <div id="right"></div>
            </div>
          </CardBody>
        </Card>
        <Card style={{ maxHeight: '80vh', minHeight: '80vh' }}>
          <CardBody style={{ position: 'relative', overflow: 'auto', padding: 0, height: '100%' }} innerRef={scrollRef}>
            {discussions
              .filter((el) => el.actionSheet.id === actionSheet.id)
              .map((element) =>
                element.messages.map((message, index) => (
                  <View>
                    <div className={headPosition[index]}>
                      {message.user.person?.profilePicture?.path !== undefined &&
                      message.user.person?.profilePicture?.path ? (
                        <img
                          className="discussionProfilePicture"
                          src={message.user.person?.profilePicture?.path}
                          alt="img"
                        />
                      ) : (message?.user['@id'] ?? parseInt(message?.user?.replace(/\D/g, ''))) === security.user.id &&
                        currentUserData?.person?.profilePicture?.path ? (
                        <img
                          className="discussionProfilePicture"
                          src={currentUserData?.person?.profilePicture?.path}
                          alt="img"
                        />
                      ) : (
                        <div className="discussionProfilePicture">
                          <p>
                            {message.user?.fullName
                              ? message.user?.fullName?.split(' ')[0].charAt(0).toUpperCase() +
                                message.user?.fullName?.split(' ')[1].charAt(0).toUpperCase()
                              : security.user?.firstName.charAt(0)?.toUpperCase() +
                                security.user?.lastName.charAt(0)?.toUpperCase()}
                          </p>
                        </div>
                      )}
                      <b>{message.user.fullName ?? security.user.firstName + ' ' + security.user.lastName}</b>
                      <span style={{ fontSize: '13px' }}>
                        - {t('adminapp_sent_on')} {new Date(message.date).toLocaleDateString()} {t('adminapp_at_')}{' '}
                        {new Date(message.date).toLocaleTimeString().slice(0, -3)}
                      </span>
                    </div>
                    <Card key={index} className={messageBody[index]}>
                      <CardBody>
                        <CardText style={{ fontFamily: 'sans-serif' }}>{message.message}</CardText>
                      </CardBody>
                    </Card>
                  </View>
                ))
              )}
          </CardBody>
        </Card>
      </View>
      <br />
      <div>
        <p style={{ color: '#00AAD7', fontWeight: 'bold' }}>{t('adminapp_write_a_new_message')}</p>
        <div>
          <InputGroup style={{ position: 'relative' }}>
            <Input
              type="textarea"
              disabled={isProcessing}
              value={localMessage}
              style={{ background: 'transparent', zIndex: '100' }}
              onChange={(e) => handleOnMessageChange(e)}
            />
            {!localMessage && (
              <span
                className="textarea-placeholer"
                style={{ position: 'absolute', top: '0.2em', left: '0.5em', zIndex: '50', color: '#ccc' }}
              >
                {t('adminapp_sheet_action_discussion_placeholder')}
              </span>
            )}
          </InputGroup>
          <br />
          <Button
            color="danger"
            disabled={isProcessing}
            style={{ float: 'right', height: '10%' }}
            onClick={() => sendMessage()}
          >
            {isProcessing ? <Spinner color="white" /> : <div>{t('adminapp_sheet_action_discussion_send')}</div>}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Discussion;
