import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import { Form, FormGroup, FormFeedback, Spinner } from 'reactstrap';
import * as Yup from 'yup';

import Input from '@/components/common/input-wever';
import api from '@/services/api';
import notifications from '@/services/notifications';
import translator, { t } from '@/services/translator';

export default () => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    setLoading(true);

    const host = `${window.location.protocol}//${window.location.hostname}`;

    api
      .post('/users/magic_token/generate', { ...values, host })
      .then(() => notifications.success(t('success'), t('success_magic_link')))
      .catch(() => notifications.error(t('error'), t('problem_magic_link')))
      .finally(() => setLoading(false));
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ username: '' }}
      validationSchema={Yup.object({
        username: Yup.string().required(translator.translate('field_required'))
      })}
    >
      {({ handleSubmit, handleBlur, errors, touched }) => (
        <Form noValidate role="form" onSubmit={handleSubmit}>
          <FormGroup className="mb-5">
            <Input
              tag={Field}
              placeholder={translator.translate('magic_key')}
              name="username"
              autoComplete="false"
              icon="envelope"
              onBlur={handleBlur}
              invalid={errors.username && touched.username}
            />
            {touched.username && <FormFeedback>{errors.username}</FormFeedback>}
          </FormGroup>
          <div className="text-center">
            {loading ? (
              <Spinner size="md" />
            ) : (
              <button outline className="btn-wever bg-pink-wever" type="submit">
                {t('managerapp_submit_magic_key')}
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
