import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Input, Checkbox, Editor } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';

const TypeForm = ({ match }) => {
  return (
    <View>
      <ResourceForm resource="Type" id={match.params.id} initialValues={{ name: '', parameters: {} }}>
        {({ submitting, renderActions }) => (
          <>
            <Card>
              <CardBody className={submitting ? 'loading' : ''}>
                <Row>
                  <Col lg="6" sm="12">
                    <Row className="d-flex align-items-center">
                      <Col lg={7}>
                        <Input name="name" />
                      </Col>
                      <Col lg={4} className="d-flex align-items-center">
                        <Checkbox name="aliasQuestion" />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6" sm="12">
                    <Editor name="parameters" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

TypeForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default TypeForm;
