import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

import Icon from '@/components/common/icon';
import Tabs from '@/components/common/tabs';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';
import Context from '@/services/context';
import notifications from '@/services/notifications';
import getResource from '@/services/resources';
import router from '@/services/router';
import { t } from '@/services/translator';

import Communities from './communities';
import Configuration from './configuration';
import ContractPartnersList from './contract-partners';
import Permissions from './permissions';
import Profile from './profile';

export default class Form extends React.Component {
  /**
   * @return {UserResource}
   */
  static get resource() {
    return getResource('User');
  }

  /**
   * @return {?number|?string}
   */
  get id() {
    const { match } = this.props;

    return match.params.id;
  }

  /**
   * Anonymize item
   *
   * @param {function} setSubmitting
   * @param {function} fetch
   * @param {function} setValues
   * @param {object}   values
   */
  anonymize(setSubmitting, fetch, setValues, values) {
    const { confirm } = this.context;

    confirm(`You will anonymize user #${this.id}`, () => {
      setSubmitting(true);
      Form.resource.anonymize(this.id).then(() => {
        fetch({ setValues, values }).then(() => {
          setSubmitting(false);
        });
      });
    });
  }

  /**
   * Delete item
   *
   * @param {function} setSubmitting
   */
  delete(setSubmitting) {
    const { confirm } = this.context;

    confirm(`You will delete user #${this.id}`, () => {
      setSubmitting(true);
      Form.resource.delete(this.id).then(() => {
        notifications.success('Success', 'User has been deleted');
        router.navigate('user_list');
      });
    });
  }

  /**
   * @return {JSX.Element}
   */
  render() {
    const { match } = this.props;

    return (
      <View>
        <ResourceForm
          resource="User"
          id={this.id}
          initialValues={{
            enabled: true,
            server: null
          }}
        >
          {({ submitting, mapping, setSubmitting, fetch, renderActions, ...props }) => (
            <>
              <Tabs baseUri={match.url} loading={submitting} showTabs={this.id !== undefined}>
                <Profile tab="profile" {...props} mapping={mapping} />
                <Configuration tab="configuration" path="configuration" />
                {/* Show others tabs on update only */}
                {mapping.has('permissions') && this.id && (
                  <Permissions tab="permissions" path="permissions" {...props} />
                )}
                <ContractPartnersList tab="contract-partners" mapping={mapping} path="contract-partners" {...props} />
                <Communities tab="communities" path="communities" {...props} />
              </Tabs>
              <div className="mt-4 mb-4">
                {renderActions(
                  this.id && (
                    <>
                      {Form.resource.canAnonymize() && (
                        <Button
                          size="md"
                          color="warning"
                          disabled={submitting}
                          onClick={() => this.anonymize(setSubmitting, fetch, props.setValues)}
                        >
                          <Icon name="user-ninja" className="mr-2" /> {t('anonymize')}
                        </Button>
                      )}
                    </>
                  )
                )}
              </div>
            </>
          )}
        </ResourceForm>
      </View>
    );
  }
}

Form.contextType = Context;

Form.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
    url: PropTypes.string
  }).isRequired
};
