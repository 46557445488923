/* eslint-disable class-methods-use-this */
import Api from '@/services/api';
import Resource from '@/services/resources/common';

export default class MediaResource extends Resource {
  constructor(entrypoint = '/media', alias = 'Media') {
    super(entrypoint, alias);
  }

  /** resource au pluriel ex: communities  */
  async createResource(resource, prop, files) {
    let medias = [];
    for (let f = 0; f < files.length; f++) {
      medias.push(this.fetchMedia(resource, prop, files[f]));
    }

    const response = await Promise.all(medias);

    return response.length > 1 ? response : response[0];
  }

  async fetchMedia(resource, prop, file) {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file, 'logo');

    const response = await Api.post(`/${resource}/media?prop=${prop}`, bodyFormData, null, null, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    return response.data;
  }
}
