import { Formik } from 'formik';
import React from 'react';

import useListAdmin from './list.hooks';
import ListRenderer from './list.render';

/**
 * @todo A transformer en contexte pour séparer les filtres dans un autres composant etc...
 *
 *
 * @param {*} props
 * @returns
 */
const ListAdmin = (props) => {
  const { state, dispatchState, components, functions } = useListAdmin(props); // controller/hooks, il va gérer tous les rafraichissements + actions

  const { filters } = state;

  if (!state || !dispatchState || !components || !functions) {
    return null;
  }

  return (
    <div className="crud-list">
      <Formik initialValues={filters} onSubmit={(v) => fetch(v, false)}>
        {(formik) => (
          <ListRenderer
            formik={formik}
            state={state}
            dispatchState={dispatchState}
            components={components}
            functions={functions}
          />
        )}
      </Formik>
    </div>
  );
};

export default ListAdmin;
