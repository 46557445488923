import React from 'react';
import { Card, CardBody } from 'reactstrap';

import ResourceView, { ResourceViewContext } from '@/components/resources/view';
import View from '@/components/view';

import Tab from './tab';

export default ({ match }) => (
  <ResourceView resource="SheetAction" id={match.params.id}>
    <View>
      <Card style={{ height: '100%', minHeight: '80vh' }}>
        <CardBody style={{ position: 'relative', overflow: 'auto', padding: 0, height: '100%' }}>
          <ResourceViewContext.Consumer>
            {(resource) => (!resource ? null : <Tab sheetAction={resource} />)}
          </ResourceViewContext.Consumer>
        </CardBody>
      </Card>
    </View>
  </ResourceView>
);
