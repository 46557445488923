import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Table, DropdownItem } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Input } from '@/components/form';
import ResourceList from '@/components/resources/list';
import View from '@/components/view';
import { t } from '@/services/translator';

export default () => {
  const redirectAction = (item) => {
    return (
      <DropdownItem>
        <Link to={`/diagnostics/editor?id=${item.id}&pattern=true`}>
          <Icon name="directions" className="iconRightMarginMax" /> {t('editor')}
        </Link>
      </DropdownItem>
    );
  };

  return (
    <View>
      <ResourceList
        resource="DiagnosticPattern"
        deleteMultiple
        filters={() => (
          <Row>
            <Col lg={3}>
              <Input name="alias" />
            </Col>
          </Row>
        )}
        body={({ collection, renderColumn, renderItemActions, renderSelect }) => (
          <Table responsive hover>
            <thead className="thead-light">
              <tr>
                {renderColumn(renderSelect('*'), false)}
                {renderColumn('id')}
                {renderColumn('alias')}
                {renderColumn()}
              </tr>
            </thead>
            <tbody>
              {collection.map((item) => (
                <tr key={item.id}>
                  <td>{renderSelect(item.id)}</td>
                  <td>{item.id}</td>
                  <td>{item.alias}</td>
                  <td>{renderItemActions(item, redirectAction(item))}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      />
    </View>
  );
};
