/**
 * Resources storage
 *
 * @type {object}
 */
const resources = {};

// Require all file of current directory
const req = require.context('./', true, /^(.*\.(js$))[^.]*$/);

// Load all resource files
req.keys().forEach((key) => {
  // check if require is the current file
  if (key === `.${__filename}`) {
    return;
  }

  // eslint-disable-next-line new-cap
  const resource = new (req(key).default)();
  const { alias } = resource;

  if (resources[alias] !== undefined) {
    throw new Error(`"${alias}" is used by multiple resource and must be unique !`);
  }

  resources[alias] = resource;
});

/**
 * Get resource instance present in resource collection
 *
 * @param {string} alias
 *
 * @return {null|Resource}
 */
export default function getResource(alias) {
  if (typeof alias !== 'string' || !resources.hasOwnProperty(alias)) {
    throw new Error(`The resource ${alias} was not found, available are ${Object.keys(resources).join(', ')}`);
  }

  return resources[alias];
}
