import React from 'react';
import ReactNotification, { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

export default {
  /**
   * Display a success notification
   *
   * @see this.push
   */
  success(title, message, container, insert) {
    return this.push(title, message, 'success', container, insert);
  },

  /**
   * Display an info notification
   *
   * @see this.push
   */
  info(title, message, container, insert) {
    return this.push(title, message, 'info', container, insert);
  },

  /**
   * Display an error notification
   *
   * @see this.push
   */
  error(title, message, container, insert, duration) {
    return this.push(title, message, 'danger', container, insert, duration);
  },

  /**
   * Display a warn notification
   *
   * @see this.push
   */
  warning(title, message, container, insert) {
    return this.push(title, message, 'warning', container, insert);
  },

  /**
   * Display a default notification
   *
   * @see this.push
   */
  default(title, message, container, insert) {
    return this.push(title, message, 'default', container, insert);
  },

  /**
   * Push a notification on screen
   *
   * @param {string}  title
   * @param {string}  message
   * @param {string}  type
   * @param {string}  container
   * @param {string}  insert
   * @param {?number} duration
   *
   * @return {string|number} - notification id
   */
  push(title, message, type, container, insert, duration) {
    return store.addNotification({
      title,
      message,
      type: type || 'info',
      insert: insert || 'top',
      container: container || 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: duration || 5000,
        onScreen: true
      }
    });
  },

  /**
   * Remove a notification visible on screen
   *
   * @param {number|string} id
   */
  remove(id) {
    store.removeNotification(id);
  },

  /**
   * Render notification container
   *
   * @return {JSX.Element}
   */
  render() {
    return <ReactNotification />;
  }
};
