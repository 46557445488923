import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Input, Checkbox } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';
import { t } from '@/services/translator';

const TypeForm = ({ match }) => {
  const isEditMode = ({ resource }) => {
    if (!resource) {
      return true;
    }

    if (!resource.mappings) {
      return true;
    }

    return !resource.mappings.update;
  };

  return (
    <View>
      <ResourceForm resource="Server" id={match.params.id} initialValues={{ key: '' }}>
        {({ submitting, renderActions, values, ...args }) => (
          <>
            <Card>
              <CardBody className={submitting ? 'loading' : ''}>
                <Row>
                  <Col lg={4} sm={12}>
                    <Input name="key" />
                  </Col>
                  <Col lg={4} sm={12}>
                    {isEditMode(args) ? <Input name="name" /> : values.name && t(values.name)}
                  </Col>
                  <Col lg={4} sm={12}>
                    <Checkbox name="enabled" />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="mt-4 mb-4">{renderActions()}</div>
          </>
        )}
      </ResourceForm>
    </View>
  );
};

TypeForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default TypeForm;
