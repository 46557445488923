import api from '@/services/api';
import Resource from '@/services/resources/common';
import security from '@/services/security';

export default class DocumentActionResource extends Resource {
  constructor(entrypoint = '/document_actions', alias = 'DocumentAction') {
    super(entrypoint, alias);
  }

  async getAvailableActions() {
    if (!security.isGranted('ROLE_ADMIN')) {
      return null;
    }

    const response = await api.get(`${this.entrypoint}/actions`);

    return response.data;
  }
}
