/* eslint-disable react-hooks/exhaustive-deps */
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useField } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FormGroup } from 'reactstrap';

import { Error, Label } from '@/components/form';

export default ({
  name,
  label,
  size = 'sm',
  groupTag = FormGroup,
  labelTag = Label,
  errorTag = Error,
  groupProps = {},
  labelProps = {},
  errorProps = {}
}) => {
  const [field, , helper] = useField({ name });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const GroupTag = groupTag;
  const LabelTag = labelTag;
  const ErrorTag = errorTag;

  label = labelProps.text || label;
  if (label === undefined) {
    label = name;
  }

  const handleUpdate = (state) => {
    // form
    helper.setValue(draftToHtml(convertToRaw(state.getCurrentContent())));
    // STATE
    setEditorState(state);
  };

  useEffect(() => {
    if (!field.value || field.value.length === 0) {
      return;
    }

    const { contentBlocks, entityMap } = htmlToDraft(field.value);
    if (contentBlocks && entityMap) {
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const generatatedEditorState = EditorState.createWithContent(contentState);
      setEditorState(generatatedEditorState);
    }
  }, []);

  return (
    <GroupTag {...groupProps}>
      {/* Label */}
      {label && (
        <LabelTag name={name} size={size} {...labelProps}>
          {label}
        </LabelTag>
      )}

      <Editor
        editorState={editorState}
        toolbarClassName="wysiwygToolbarClassName"
        wrapperClassName="wysiwygWrapperClassName"
        editorClassName="wysiwygEditorClassName"
        onEditorStateChange={handleUpdate}
      />
      {/* Errors */}
      <ErrorTag name={name} {...errorProps} />
    </GroupTag>
  );
};
