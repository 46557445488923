import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Col, Row, Card, CardBody, Table, Button, Input as ReactInput } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Input } from '@/components/form';
import { Error } from '@/components/form';
import logger from '@/services/logger';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

import helper from '../select/helper';

const TableLangValue = ({ name, inputType, onChange }) => {
  const [field, , helpers] = useField({ name });
  const [values, setValues] = useState();
  const [wordsTrans, setWordTrans] = useState();
  const [timer, setTimer] = useState();

  let { value } = field;
  const { setValue } = helpers;

  const addLang = () => {
    if (!values) {
      return;
    }

    if (!value || Array.isArray(value)) {
      value = {};
    }

    if (value[values.value]) {
      return;
    }

    value[values.value] = wordsTrans || 'N/A';
    setValue(value);
    setWordTrans('');

    if (typeof onChange === 'function') {
      onChange(value);
    }
  };

  const removeLang = (e, lang) => {
    e.preventDefault();

    if (value[lang]) {
      delete value[lang];
      setValue(value);

      if (typeof onChange === 'function') {
        onChange(value);
      }
    }
  };

  const fetchOptions = async (terms) => {
    try {
      const response = await getResource('Language').list({ name: terms });

      if (!response || !response['hydra:member'] || !Array.isArray(response['hydra:member'])) {
        return [];
      }

      return response['hydra:member'].map((lang) => {
        return { value: lang.locale, label: lang.name };
      });
    } catch (e) {
      logger.error('Langue fetcher', 'option', e);
      return [];
    }
  };

  /**
   * @param {string} value
   */
  const loadAsyncOptions = (terms) => {
    clearTimeout(timer);

    return new Promise((resolve) => {
      const newTimer = setTimeout(() => {
        resolve(fetchOptions(terms));
      }, 500);

      setTimer(newTimer);
    });
  };

  return (
    <Card className="bg-light">
      <CardBody>
        <Card>
          <CardBody>
            <Row>
              <Col md="3" className="d-flex align-items-center fs-6">
                {t('add_lang')}
              </Col>
              <Col md="3">
                <AsyncSelect
                  loadOptions={loadAsyncOptions}
                  cacheOptions
                  defaultOptions
                  value={values}
                  placeholder="Select lang..."
                  onChange={(option) => setValues(option)}
                  styles={helper.buildStyles('sm', false)}
                />
              </Col>
              <Col md="3">
                <ReactInput
                  value={wordsTrans}
                  placeholder="trad"
                  onChange={(e) => setWordTrans(e.target.value)}
                  size="sm"
                />
              </Col>
              <Col md="2">
                <Button size="sm" className="rounded-pill px-3" color="danger" onClick={addLang}>
                  {t('add')}
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Table>
          <thead>
            <tr>
              <th>{t('lang')}</th>
              <th>{t('value')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {value &&
              Object.keys(value).map((lang) => {
                return (
                  <tr key={lang}>
                    <td>{lang}</td>
                    <td>
                      <Input name={`${name}['${lang}']`} label={null} type={inputType} />
                    </td>
                    <td>
                      <Button size="sm" color="danger" type="button" onClick={(e) => removeLang(e, lang)}>
                        <Icon name="trash" />
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Error name={name} />
      </CardBody>
    </Card>
  );
};

TableLangValue.propTypes = {
  name: PropTypes.string.isRequired,
  inputType: PropTypes.string
};

TableLangValue.defaultProps = {
  inputType: 'text'
};

export default TableLangValue;
