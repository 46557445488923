import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { FormGroup } from 'reactstrap';

import { Autocomplete, Error, Label } from '@/components/form';
import api from '@/services/api';
import getResource from '@/services/resources';

const ServerField = (props) => {
  const { name, onChange } = props;
  const [serverValue, setServerValue] = useState();

  const [field, ...helper] = useField({ name });
  const { value } = field;

  const handleChange = async (valueChanged) => {
    if (typeof valueChanged === 'object') {
      helper[1].setValue(valueChanged.key);
      onChange(valueChanged.key);
    } else {
      const response = await api.get(valueChanged);
      helper[1].setValue(response.data.key);

      if (onChange) {
        onChange(response.data.key);
      }
    }
  };

  const updateAutocomplete = async (key) => {
    const response = await getResource('Server').list({ key });

    setServerValue(response['hydra:member'][0] || null);
  };

  useEffect(() => {
    if (!value) {
      return;
    }

    updateAutocomplete(value);
  }, [value]);

  return (
    <FormGroup>
      <Label name={name}>{name}</Label>
      <Autocomplete
        value={serverValue || ''}
        onChange={(optionValue) => handleChange(optionValue)}
        clearable
        resource="Server"
        display="name"
        tag="key"
      />
      <Error name={name} />
    </FormGroup>
  );
};

ServerField.propTypes = {
  name: PropTypes.string.isRequired
};

export default ServerField;
