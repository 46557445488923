import { Formik } from 'formik';
import React, { useContext } from 'react';
import { Form, FormGroup, InputGroupAddon, InputGroupText, InputGroup, Navbar, Nav, Container } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Autocomplete } from '@/components/form';
import UserMenu from '@/components/layout/navbars/user-menu';
import Context from '@/services/context';
import security from '@/services/security';
import { t } from '@/services/translator';

export default () => {
  const { community, title, update } = useContext(Context);

  const updateCommunity = (value) => {
    update({ community: value });
  };

  return (
    <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
      <Container fluid>
        <h4 className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">{t(title)}</h4>
        <Formik initialValues={{ community }}>
          {() => (
            <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              {security.isGranted('ROLE_ADMIN') || security.isGranted('ROLE_SUPER_ADMIN') ? null : (
                <FormGroup className="mb-0 weverapp-select-community">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <Icon name="building" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Autocomplete
                      name="community"
                      className="community-choice"
                      hasLabel={false}
                      resource="Community"
                      tag="name"
                      display="name"
                      autoload
                      onChange={updateCommunity}
                    />
                  </InputGroup>
                </FormGroup>
              )}
            </Form>
          )}
        </Formik>
        <Nav className="align-items-center d-none d-md-flex" navbar>
          <UserMenu />
        </Nav>
      </Container>
    </Navbar>
  );
};
