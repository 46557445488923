import React from 'react';
import { Container } from 'reactstrap';

export default ({ routes }) => (
  <Container
    className="mt--5 d-flex justify-content-center view-layout-container"
    fluid
    style={{ position: 'relative' }}
  >
    <>{routes && routes()}</>
  </Container>
);
