import React from 'react';
import { Col, Row, Card, CardBody, CardHeader, Button } from 'reactstrap';

import Icon from '@/components/common/icon';
import { Select } from '@/components/form';
import { t } from '@/services/translator';

export default ({ value, index, name, actionList, submitting, onChange, onDelete }) => {
  const handleOnSelect = (valueSelect) => {
    onChange(valueSelect, index, 'action');
  };

  return (
    <Card className="appDocAction mt-2">
      <CardHeader className="d-flex justify-content-end p-1">
        <Button color="danger" size="sm" type="button" onClick={() => onDelete(index)}>
          <Icon name="times" />
        </Button>
      </CardHeader>
      <CardBody className={submitting ? 'loading' : ''}>
        <Row>
          <Col lg="12">
            <Select
              name={`${name}.action`}
              value={{ label: value.action, value: [value.action, value.parameters] }}
              options={actionList}
              onSelect={handleOnSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            {value.parameters &&
              Object.keys(value.parameters).map((key) => {
                return (
                  <Row key={`${key}_${name}_${index}`}>
                    <Col>{t(key)}</Col>
                    <Col>
                      <input
                        value={value.parameters[key]}
                        onChange={(e) => onChange(e.target.value, index, 'parameters', key)}
                      />
                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
