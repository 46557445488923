/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import View from '@/components/view';

export default ({ history }) => {
  const redirectToCommunities = () => {
    history.push({
      pathname: 'communities',
      state: { from: 'login' }
    });
  };

  useEffect(() => {
    redirectToCommunities();
  }, []);

  return <View>Dashboard</View>;
};
