/* eslint-disable class-methods-use-this */
import Resource from '@/services/resources/common';

export default class DiagnosticPatternResource extends Resource {
  constructor(entrypoint = '/diagnostic_patterns', alias = 'DiagnosticPattern') {
    super(entrypoint, alias);
  }

  canCreate() {
    return false;
  }

  canUpdate() {
    return false;
  }
}
