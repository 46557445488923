/* eslint-disable global-require */

/** @todo TO FINISH IT. */
export default [
  {
    path: 'servers',
    name: 'servers',
    resource: 'Server',
    roles: ['ROLE_ADMIN'],
    component: require('@/components/layout/view-layout').default,
    routes: [
      {
        path: '',
        name: 'list',
        context: 'read:list',
        exact: true,
        component: require('@/views/server/list').default,
        title: 'server_list_title',
        permissions: {
          Server: ['read:list']
        }
      },
      {
        path: '/create',
        name: 'create',
        component: require('@/views/server/form').default,
        title: 'server_create_title',
        permissions: {
          Server: ['create']
        }
      },
      {
        path: '/:id',
        name: 'update',
        component: require('@/views/server/form').default,
        title: 'server_update_title',
        permissions: {
          Server: ['read', 'update']
        }
      }
    ]
  }
];
