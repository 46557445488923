import { useField, useFormikContext } from 'formik';
import React from 'react';
import { FormGroup, Input } from 'reactstrap';

import { Error, Label } from '@/components/form';

export default ({
  name,
  label,
  type,
  size = 'sm',
  groupTag = FormGroup,
  labelTag = Label,
  errorTag = Error,
  inputTag = Input,
  groupProps = {},
  labelProps = {},
  inputProps = {},
  errorProps = {},
  ...props
}) => {
  const [field, meta] = useField({ name });
  const { submitCount } = useFormikContext();

  const GroupTag = groupTag;
  const LabelTag = labelTag;
  const ErrorTag = errorTag;
  const InputTag = inputTag;

  label = labelProps.text || label;
  if (label === undefined) {
    label = name;
  }

  return (
    <GroupTag {...groupProps}>
      {/* Label */}
      {label && (
        <LabelTag name={name} size={size} {...labelProps}>
          {label}
        </LabelTag>
      )}

      {/* Input */}
      <InputTag
        type={type || 'text'}
        invalid={(meta.touched || submitCount > 0) && meta.error !== undefined}
        autoComplete="off"
        bsSize={size}
        {...field}
        value={field.value || ''}
        {...props}
        {...inputProps}
      />

      {/* Errors */}
      <ErrorTag name={name} {...errorProps} />
    </GroupTag>
  );
};
