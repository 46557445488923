import React from 'react';
import { Col, Row, Table } from 'reactstrap';

import Date from '@/components/common/date';
import { Input } from '@/components/form';
import ResourceList from '@/components/resources/list';
import View from '@/components/view';
import { t } from '@/services/translator/index';

export default () => {
  return (
    <View>
      <ResourceList
        resource="DocumentOfficial"
        deleteMultiple
        filters={() => (
          <Row>
            <Col lg={3}>
              <Input name="name" />
            </Col>
          </Row>
        )}
        body={({ collection, renderColumn, renderItemActions, renderSelect }) => (
          <Table responsive hover>
            <thead className="thead-light">
              <tr>
                {renderColumn(renderSelect('*'), false)}
                {renderColumn('id')}
                {renderColumn('name')}
                {renderColumn('type')}
                {renderColumn('createdAt')}
                {renderColumn('updatedAt')}
                {renderColumn()}
              </tr>
            </thead>
            <tbody>
              {collection.map((item) => (
                <tr key={item.id}>
                  <td>{renderSelect(item.id)}</td>
                  <td>{item.id}</td>
                  <td>{t(item.name || '')}</td>
                  <td>{t(item.type.name || '')}</td>
                  <td>{item.createdAt ? <Date>{item.createdAt}</Date> : null}</td>
                  <td>{renderItemActions(item)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      />
    </View>
  );
};
