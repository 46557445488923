/* eslint-disable react-hooks/exhaustive-deps */
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { FormGroup } from 'reactstrap';

import { Error, Label } from '@/components/form';
import api from '@/services/api';
import Logger from '@/services/logger';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

import helper from './select/helper';

const Autocomplete = (props) => {
  // props
  const {
    resource,
    display,
    name,
    tag,
    size,
    multiple,
    filters,
    clearable,
    value,
    hasLabel,
    onChange,
    autoload,
    placeholder
  } = props;
  // hooks
  const [field, meta, fieldHelper] = useField({ name });
  const { submitCount } = useFormikContext();
  // state
  const [values, setValues] = useState();
  const [timer, setTimer] = useState();
  // var
  const invalid = name ? (meta.touched || submitCount > 0) && meta.error !== undefined : false;

  /**
   * Format value or received options (from api)
   * used in fetchOptions and useEffect.
   *
   * @param {array} collection
   */
  const formatCollectionToOptions = async (collection, isMultiple, isSettingValue = false) => {
    if (!Array.isArray(collection) && (multiple || isMultiple)) {
      collection = [collection];
    }

    let response = null;
    if (multiple || isMultiple) {
      const objCollection = [];

      for (let i = 0; i < collection.length; i++) {
        if (typeof collection[i] === 'object') {
          let entity = collection[i];

          if (!entity[display] && entity['@id']) {
            const response = await api.get(entity['@id']);
            entity = response.data;
          }

          objCollection.push({ label: entity[display], value: entity });
        } else {
          response = await api.get(collection[i]);

          if (response.data) {
            objCollection.push({ label: response.data[display], value: response.data });
          }
        }
      }

      if (isSettingValue) {
        setValues(objCollection);
        return;
      }

      return objCollection;
    }

    let obj = collection;

    if (typeof collection !== 'object') {
      response = await api.get(collection);

      if (response.data) {
        obj = response.data;
      }
    }

    if (isSettingValue) {
      setValues({ label: t(obj[display] || ''), value: obj });
    }

    return { label: t(obj[display] || ''), value: obj };
  };

  /**
   * @param {string} terms
   */
  const fetchOptions = async (terms) => {
    try {
      let filtersList = { [tag]: terms };

      // on peut rajouter manuellement des choses
      if (filters) {
        filtersList = { ...filtersList, ...filters };
      }

      const response = await getResource(resource).list(filtersList);

      return formatCollectionToOptions(response['hydra:member'], true);
    } catch (e) {
      Logger.error('Autocomplete', 'option', e);
      return [];
    }
  };

  /**
   * @param {string} value
   */
  const loadAsyncOptions = (terms) => {
    clearTimeout(timer);

    return new Promise((resolve) => {
      const newTimer = setTimeout(() => {
        resolve(fetchOptions(terms));
      }, 500);

      setTimer(newTimer);
    });
  };

  /**
   * @param {array} options
   */
  const handleOnChange = (options) => {
    if (!options) {
      return;
    }
    let optionsToValue = [];

    if (multiple) {
      options.map((option) => {
        return optionsToValue.push(option.value['@id']);
      });
    } else {
      optionsToValue = options.value['@id'];
    }

    setValues(options);

    if (onChange && typeof onChange === 'function') {
      onChange(optionsToValue, options);
    }

    if (name) {
      fieldHelper.setValue(optionsToValue);
    }
  };

  // load values with existing one.
  useEffect(() => {
    if (!field || !field.value) {
      return;
    }

    formatCollectionToOptions(field.value, multiple, true);
  }, []);

  useEffect(() => {
    if (!value) {
      return;
    }

    formatCollectionToOptions(value, multiple, true);
  }, [value]);

  return (
    <FormGroup>
      {name && hasLabel && (
        <Label name={name} size={size}>
          {name}
        </Label>
      )}
      <AsyncSelect
        isMulti={multiple}
        isClearable={clearable}
        cacheOptions
        loadOptions={loadAsyncOptions}
        invalid={invalid}
        onChange={handleOnChange}
        value={values}
        onBlur={() => name && fieldHelper.setTouched(true)}
        styles={helper.buildStyles(size, invalid)}
        defaultOptions={autoload}
        className="autocomplete-async"
        placeholder={placeholder}
      />
      {name && <Error name={name} />}
    </FormGroup>
  );
};

Autocomplete.propTypes = {
  size: PropTypes.string,
  resource: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
  tag: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
  autoload: PropTypes.bool,
  hasLabel: PropTypes.bool
};

Autocomplete.defaultProps = {
  size: 'sm',
  multiple: false,
  clearable: false,
  autoload: false,
  hasLabel: true,
  name: null,
  value: null
};

export default Autocomplete;
