import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { Input, Editor } from '@/components/form';
import ResourceForm from '@/components/resources/form';
import View from '@/components/view';

const ConfigurationForm = ({ match }) => (
  <View>
    <ResourceForm resource="Configuration" id={match.params.id} initialValues={{ key: '', values: '{}' }}>
      {({ submitting, renderActions }) => (
        <>
          <Card>
            <CardBody className={submitting ? 'loading' : ''}>
              <Row>
                <Col lg={4}>
                  <Input name="key" placeholder="ex: ReportParttern" />
                </Col>
                <Col lg={12}>
                  <Editor name="values" height="300" />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="mt-4 mb-4">{renderActions()}</div>
        </>
      )}
    </ResourceForm>
  </View>
);

ConfigurationForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
  }).isRequired
};

export default ConfigurationForm;
