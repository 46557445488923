/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';

import api from '@/services/api';
import { t } from '@/services/translator';

export default ({ document, show }) => {
  const [data, setData] = useState();

  const load = async () => {
    const id = document.replace('/document_officials/', '');

    const response = await api.get(`/documents/${id}/public`);

    if (response.data) {
      setData(response.data);
    }
  };

  useEffect(() => {
    if (!document) {
      return;
    }

    load();
  }, []);

  if (!data) {
    return <Spinner color="primary" />;
  }

  const index = data.name.toLowerCase().replace(/\s/g, '_').trim();

  return (
    <div className="accordion-item wever">
      <h2 className="accordion-header" id={index}>
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${index}_collapse`}
          aria-expanded="true"
          aria-controls={`#${index}_collapse`}
        >
          {t(data.name)}
        </button>
      </h2>
      <div
        id={`${index}_collapse`}
        className={`accordion-collapse collapse ${show ? 'show' : ''}`}
        aria-labelledby={index}
      >
        <div className="accordion-body">
          <iframe srcDoc={data.content} title="content" width="100%" style={{ minHeight: '500px' }} />
        </div>
      </div>
    </div>
  );
};
