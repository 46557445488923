/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Spinner } from 'reactstrap';

import api from '@/services/api';
import logger from '@/services/logger';
import notifications from '@/services/notifications';
import router from '@/services/router';
import security from '@/services/security';
import { t } from '@/services/translator';

/*
 * Charge la liste des connexions oAuth
 */
export default () => {
  const loadExternalConnection = async () => {
    // const state = urlParams.get('state');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    const key = urlParams.get('key');

    logger.info('TRY ACCESS', 'auth', { code, key });

    if (!code) {
      logger.error('No code', '', { queryString });
      // router.navigate('login');

      return;
    }

    const redirect = `https://${window.location.hostname}/login/oauth?key=${key}`;
    logger.info('OAUTH', 'auth', { redirect });

    try {
      const response = await api.post('/oauth/connect', { code, context: 'ManagerApp', key: key || 'tpg', redirect });

      if (!response.data || !response.data.token) {
        notifications.error(t('userapp_error'), t(`userapp_${response.data.error}`));
      }
      try {
        security.authenticate(response.data.token, null);
      } catch (e) {
        logger.error('Cannot authenticate', '', { response });
      }

      router.navigate('/');
    } catch (e) {
      logger.error('Connect oAuth', '', { e });
      notifications.error(t('userapp_error'), t('userapp_errorapi'));
    }
  };

  useEffect(() => {
    loadExternalConnection();
  }, []);

  return (
    <div className="w-100 h-50 d-flex justify-content-center align-items-center" style={{ flexDirection: 'column' }}>
      <div className="mb-2">{t('userapp_is_connecting')}</div>
      <Spinner />
    </div>
  );
};
