import React from 'react';

import Icon from '@/components/common/icon';

export default [
  {
    label: 'adminapp_menu_dashboard',
    icon: <Icon name="table" className="iconRightMarginMax" />,
    roles: ['ROLE_ADMIN'],
    items: [
      {
        label: 'adminapp_menu_dashboard_layout',
        icon: <Icon name="border-all" className="iconRightMarginMid" />,
        items: [
          {
            label: 'adminapp_menu_dashboard_layout_list',
            route: 'dashboard_layout_list'
          },
          {
            label: 'adminapp_menu_dashboard_layout_create',
            route: 'dashboard_layout_create'
          }
        ]
      },
      {
        label: 'adminapp_menu_dashboard_type',
        icon: <Icon name="border-style" className="iconRightMarginMid" />,
        items: [
          {
            label: 'adminapp_menu_dashboard_type_list',
            route: 'dashboard_type_list'
          },
          {
            label: 'adminapp_menu_dashboard_type_create',
            route: 'dashboard_type_create'
          }
        ]
      },
      {
        label: 'adminapp_menu_graph_type',
        icon: <Icon name="chart-pie" className="iconRightMarginMid" />,
        items: [
          {
            label: 'adminapp_menu_graph_type_list',
            route: 'graph_type_list'
          },
          {
            label: 'adminapp_menu_graph_type_create',
            route: 'graph_type_create'
          }
        ]
      }
    ]
  }
];
