/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

const Collection = (props) => {
  const { elements, tag } = props;
  const [collection, setCollection] = useState();

  useEffect(() => {
    if (!elements || !Array.isArray(elements)) {
      return;
    }

    const newCollection = elements.map((element) => {
      if (tag) {
        return element[tag];
      }

      return element;
    });

    setCollection(newCollection.join(', '));
  }, []);

  if (!collection || !elements) {
    return null;
  }

  return <div>{collection}</div>;
};

Collection.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.shape({})),
  tag: PropTypes.string.isRequired
};

Collection.defaultProps = {
  elements: null
};

export default Collection;
