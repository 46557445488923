import React, { useContext } from 'react';
import { Col, Row, Table } from 'reactstrap';

import { Input } from '@/components/form';
import ResourceList from '@/components/resources/list';
import View from '@/components/view';
import Context from '@/services/context';

export default () => {
  const { locale } = useContext(Context);

  return (
    <View>
      <ResourceList
        resource="ExpectedResponsePattern"
        deleteMultiple
        filters={() => (
          <Row>
            <Col lg={3}>
              <Input name="value" />
            </Col>
            <Col lg={3}>
              <Input name="alias" />
            </Col>
          </Row>
        )}
        body={({ collection, renderColumn, renderItemActions, renderSelect }) => (
          <Table responsive hover>
            <thead className="thead-light">
              <tr>
                {renderColumn(renderSelect('*'), false)}
                {renderColumn('id')}
                {renderColumn('name')}
                {renderColumn('alias')}
                {renderColumn('value')}
                {renderColumn()}
              </tr>
            </thead>
            <tbody>
              {collection.map((item) => (
                <tr key={item.id}>
                  <td>{renderSelect(item.id)}</td>
                  <td>{item.id}</td>
                  <td>{item.name[locale]}</td>
                  <td>{item.alias}</td>
                  <td>{item.value}</td>
                  <td>{renderItemActions(item)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      />
    </View>
  );
};
