import React from 'react';

export default [
  {
    label: 'adminapp_menu_maps',
    path: '/maps',
    roles: ['ROLE_ADMIN'],
    icon: <i className="ni ni-world-2 text-orange" />,
    items: [
      {
        label: 'adminapp_menu_maps_viewer',
        route: 'viewer_map',
        roles: ['ROLE_ADMIN']
      },
      {
        label: 'adminapp_menu_maps_list',
        path: '/maps'
      },
      {
        label: 'adminapp_menu_maps_create',
        path: '/maps/create'
      },
      {
        label: 'adminapp_menu_maps_zone_type',
        route: 'zone_type_list',
        roles: ['ROLE_ADMIN']
      },
      {
        label: 'adminapp_menu_maps_zone',
        route: 'zone_list',
        roles: ['ROLE_ADMIN']
      },
      {
        label: 'adminapp_menu_maps_location_type',
        route: 'location_type_list',
        roles: ['ROLE_ADMIN']
      },
      {
        label: 'adminapp_menu_maps_location',
        route: 'location_list',
        roles: ['ROLE_ADMIN']
      },
      {
        label: 'adminapp_menu_maps_path',
        route: 'path_list',
        roles: ['ROLE_ADMIN']
      }
    ]
  }
];
