import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col, Button, Table, Spinner } from 'reactstrap';

import notifications from '@/services/notifications';
import getResource from '@/services/resources';
import { t } from '@/services/translator';

export default class DataView extends React.Component {
  state = {
    total: null,
    collection: [],
    submitting: false,
    ran: 0,
    preview: null
  };

  fetchData() {
    const { id } = this.props;
    const { ran } = this.state;

    this.setState({ submitting: true, collection: [], ran: ran + 1, preview: 'data' }, () => {
      getResource('ReportPattern')
        .fetchPreview(id)
        .then((data) => {
          this.setState({ submitting: false, collection: data.preview, total: data.total });
        })
        .catch(() => {
          this.setState({ submitting: false });
        });
    });
  }

  runQuery() {
    const { query } = this.props;
    const { ran } = this.state;

    try {
      JSON.parse(query);
    } catch (e) {
      return notifications.error('Query syntax invalid', e.message);
    }

    this.setState({ submitting: true, collection: [], ran: ran + 1, preview: 'query' }, () => {
      getResource('Response')
        .query(query)
        .then((data) => {
          this.setState({ submitting: false, collection: data.preview, total: data.total });
        })
        .catch(({ response }) => {
          if (response && response.status === 400) {
            notifications.error('Oops query error detected', response.data.error);
          }
          this.setState({ submitting: false });
        });
    });

    return true;
  }

  render() {
    const { submitting, collection, ran, preview, total } = this.state;
    const { id } = this.props;
    const columns = collection[0] !== undefined ? Object.keys(collection[0]) : [];

    return (
      <Row className="m-0">
        <Col lg={12} className={`flex justify-content-${id ? 'between' : 'end'}`}>
          {id !== undefined && (
            <Button
              size="sm"
              type="button"
              color="terciary"
              outline
              disabled={submitting}
              className={`mb-2 shadow-none ${preview === 'data' ? 'border-dark' : ''}`}
              onClick={() => this.fetchData()}
            >
              {!submitting || preview !== 'data' ? (
                t('report.show_current_data')
              ) : (
                <i>
                  <Spinner size="sm" />
                </i>
              )}
            </Button>
          )}

          <div className="flex align-items-center">
            {ran > 0 && !submitting && typeof total === 'number' && (
              <div className="text-black-50 mr-3">
                <small>{t('nb_elements', total)}</small>
              </div>
            )}
            {ran > 0 && (
              <Button
                size="sm"
                type="button"
                color="terciary"
                outline
                disabled={submitting}
                className="shadow-none"
                onClick={() => this.setState({ ran: 0, collection: [], preview: null })}
              >
                {t('report.hide_preview')}
              </Button>
            )}

            <Button
              size="sm"
              type="button"
              color="terciary"
              outline
              disabled={submitting}
              className={`shadow-none ${preview === 'query' ? 'border-dark' : ''}`}
              onClick={() => this.runQuery()}
            >
              {!submitting || preview !== 'query' ? (
                t('report.show_preview')
              ) : (
                <i>
                  <Spinner size="sm" />
                </i>
              )}
            </Button>
          </div>
        </Col>
        {ran > 0 && (
          <Col lg={12} style={{ maxHeight: '500px', overflow: 'scroll' }}>
            <Table className="mt-2">
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th key={column}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {collection.map((item) => (
                  <tr key={item.id}>
                    {Object.keys(item).map((prop) => (
                      <td key={prop}>
                        {item[prop] && typeof item[prop] === 'object' ? JSON.stringify(item[prop]) : item[prop]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
            {collection.length === 0 && !submitting && (
              <div className="text-center mt-3">
                <i>{t('no_result')}</i>
              </div>
            )}
          </Col>
        )}
      </Row>
    );
  }
}

DataView.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  query: PropTypes.string
};

DataView.defaultProps = {
  id: null,
  query: ''
};
