import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Col, Container } from 'reactstrap';

import MagicLoginForm from '@/components/security/magic-login';
import { t } from '@/services/translator';

export default () => (
  <Container className="mt--8 pb-5">
    <Row className="justify-content-center">
      <Col lg="5" md="7">
        <p className="text-center">{t('magic_token_title')}</p>
        <Card className="bg-white shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <p className="text-center">{t('magic_token_subtitle')}</p>
            <MagicLoginForm />
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="12" className="text-center">
            <Link className="text-light" to="/login">
              <small>{t('back_to_login')}</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
);
