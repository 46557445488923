/* eslint-disable react-hooks/exhaustive-deps */
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { FormGroup } from 'reactstrap';

import { Error, Label } from '@/components/form';
import helper from '@/components/form/select/helper';
import api from '@/services/api';
import Logger from '@/services/logger';
import { t } from '@/services/translator';

const Autocomplete = (props) => {
  // props
  const { name } = props;

  const [field, , helpers] = useField({ name });

  // state
  const [values, setValues] = useState();
  const [timer, setTimer] = useState();

  /**
   * @param {string} terms
   */
  const fetchOptions = async (terms) => {
    try {
      const response = await api.get('/graphs/fetch_functions');

      if (typeof response.data === 'object') {
        return Object.keys(response.data).map((key) => ({ value: key, label: t(key) }));
      }

      return [];
    } catch (e) {
      Logger.error('Autocomplete', 'option', e);
      return [];
    }
  };

  /**
   * @param {string} value
   */
  const loadAsyncOptions = (terms) => {
    clearTimeout(timer);

    return new Promise((resolve) => {
      const newTimer = setTimeout(() => {
        resolve(fetchOptions(terms));
      }, 500);

      setTimer(newTimer);
    });
  };

  /**
   * @param {array} options
   */
  const handleOnChange = (options) => {
    if (!options) {
      return;
    }

    setValues(options);
    helpers.setValue(options.value);
  };

  useEffect(() => {
    if (!field.value) {
      return;
    }

    setValues({ value: field.value, label: field.value });
  }, []);

  return (
    <FormGroup>
      {name && (
        <Label name={name} size="sm">
          {name}
        </Label>
      )}
      <AsyncCreatableSelect
        isClearable
        loadOptions={loadAsyncOptions}
        onChange={handleOnChange}
        value={values}
        cacheOptions
        defaultOptions
        styles={helper.buildStyles('sm', false)}
      />
      {name && <Error name={name} />}
    </FormGroup>
  );
};

Autocomplete.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string
};

Autocomplete.defaultProps = {
  value: null,
  name: null
};

export default Autocomplete;
