import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Row, Col } from 'reactstrap';

import { t } from '@/services/translator';

export default () => {
  const [item, , helpers] = useField({ name: 'prioritizedExpectedResponses' });
  const [responses, ,] = useField({ name: 'expectedResponses' });

  const [options, setOptions] = useState();
  const [selected, setSelected] = useState();

  /**
   *
   * @param {array} options
   */
  const handleSelection = (selections) => {
    if (!selections) {
      return;
    }

    const prioritized = [];

    selections.forEach((option) => {
      prioritized.push(option.value.alias);
    });

    helpers.setValue(prioritized);
  };

  useEffect(() => {
    if (!responses.value || !Array.isArray(responses.value)) {
      return;
    }

    const tmpOptions = [];
    const tmpSelected = [];
    const prioritized = item.value;

    responses.value.forEach((response) => {
      tmpOptions.push({ label: response.alias, value: response });

      if (prioritized && prioritized.includes(response.alias)) {
        tmpSelected.push({ label: response.alias, value: response });
      }
    });

    setOptions(tmpOptions);
    setSelected(tmpSelected);
  }, [responses.value, item.value]);

  return (
    <Row className="mb-2">
      <Col className="col-12">{t('adminapp_select_prioritized')}</Col>
      <Col>
        <Select isMulti options={options} value={selected} onChange={handleSelection} />
      </Col>
    </Row>
  );
};
