import PropTypes from 'prop-types';
import React from 'react';
import { Col, FormGroup, Label } from 'reactstrap';

import { TableLangValue, Input } from '@/components/form';
import { t } from '@/services/translator';

const SheetActionCost = ({ index }) => (
  <div key={`cost-${index}`}>
    <FormGroup row>
      <Label sm={5}>{t('adminapp_sheet_action_name')}</Label>
      <Col sm={6}>
        <TableLangValue name={`costs[${index}].name`} />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Label sm={5}>{t('adminapp_sheet_action_price')}</Label>
      <Col sm={6}>
        <Input name={`costs[${index}].price`} label={null} required />
      </Col>
    </FormGroup>
  </div>
);

SheetActionCost.propTypes = {
  index: PropTypes.number,
  costState: PropTypes.array,
  handleCostChange: PropTypes.func
};

export default SheetActionCost;
